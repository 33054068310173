import React, { useEffect } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useNavigate } from "react-router-dom";
import { Validate } from "../../utils/validation";
import { hasuraEvents } from "../../utils/hasura_events";

const PaypalButton = ({
  configsData,
  loading,
  check,
  handleSubmit,
  createOrder,
  setSelectedStepper,
  clearReport,
  details,
  schema,
  setErrors,
  scrollPosition,
}) => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, scrollPosition);
  }, [scrollPosition]);
  return (
    <div className={`grid gap-4 md:grid-cols-2 mt-8 w-full`}>
      {/* <div className={``}>  fot auth card on*/}
      {configsData?.config_by_pk?.pay_method?.paypal ||
      configsData?.config_by_pk?.pay_method?.paypal_card ||
      configsData?.config_by_pk?.pay_method?.venmo ? (
        <PayPalScriptProvider
          options={{
            intent: "capture",
            currency: "USD",

            clientId: configsData?.config_by_pk?.paypal_client_id,
            components: "funding-eligibility,buttons",
            "enable-funding": "venmo",
          }}
        >
          {configsData?.config_by_pk?.pay_method?.venmo ? (
            <PayPalButtons
              disabled={loading || !check}
              style={{
                layout: "vertical",
                color: "blue",
                label: "pay",
                height: 52,
              }}
              containerWidth={200}
              className="paypal-container disabled:cursor-not-allowed"
              onApprove={async (data) => {
                handleSubmit(data);
              }}
              fundingSource="venmo"
              onError={async (err) => {
                setSelectedStepper({
                  count: 1,
                  label: "Location & Date",
                });
                const id = localStorage.getItem("reportId");
                console.log(id, "id", configsData);

                await clearReport();
                navigate(`/${id}?success=false`);
              }}
              onClick={async () => {
                const validate = await Validate(details, schema);
                if (validate?.error) {
                  setErrors(validate?.error);
                  return false;
                } else {
                  setErrors({});
                  return true;
                }
              }}
              createOrder={createOrder}
              forceReRender={[check]}
            />
          ) : null}
          {configsData?.config_by_pk?.pay_method?.paypal_card ? (
            <PayPalButtons
              disabled={loading || !check}
              style={{
                layout: "vertical",
                color: "black",
                label: "pay",
                height: 52,
              }}
              containerWidth={200}
              className="paypal-container disabled:cursor-not-allowed"
              onApprove={async (data) => {
                handleSubmit(data);
              }}
              fundingSource="card"
              onError={async (err) => {
                setSelectedStepper({
                  count: 1,
                  label: "Location & Date",
                });
                const id = localStorage.getItem("reportId");
                console.log(id, "id", configsData);

                await clearReport();
                navigate(`/${id}?success=false`);
              }}
              onClick={async () => {
                const validate = await Validate(details, schema);
                if (validate?.error) {
                  setErrors(validate?.error);
                  return false;
                } else {
                  setErrors({});
                  return true;
                }
              }}
              createOrder={createOrder}
              forceReRender={[check]}
            />
          ) : null}
          {configsData?.config_by_pk?.pay_method?.paypal ? (
            <PayPalButtons
              disabled={loading || !check}
              style={{
                layout: "vertical",
                color: "blue",
                label: "pay",
                height: 52,
              }}
              // containerWidth={200}
              className="paypal-container w-full disabled:cursor-not-allowed"
              onApprove={async (data) => {
                handleSubmit(data);
              }}
              fundingSource="paypal"
              onError={async (err) => {
                setSelectedStepper({
                  count: 1,
                  label: "Location & Date",
                });
                const id = localStorage.getItem("reportId");
                await clearReport();
                navigate(`/${id}?success=false`);
              }}
              onClick={async () => {
                const validate = await Validate(details, schema);
                if (validate?.error) {
                  setErrors(validate?.error);
                  return false;
                } else {
                  setErrors({});
                  return true;
                }
              }}
              createOrder={createOrder}
              forceReRender={[check]}
            >
              <h3 style={{ color: "#dc3545", textTransform: "capitalize" }}>
                {"The component is ineligible to render"}
              </h3>
            </PayPalButtons>
          ) : null}
        </PayPalScriptProvider>
      ) : null}
    </div>
  );
};
export default PaypalButton;
