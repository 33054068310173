import CookieConsent from "react-cookie-consent";

const CookieBanner = () => {
  return (
    <CookieConsent
      location="bottom"
      enableDeclineButton
      declineButtonText="Decline"
      buttonText="Accept"
      cookieName="lost-found-report"
      style={{
        background: "rgba(255,255,255, 0.9)",
        color: "#334155",
        fontSize: "12px",
        fontWeight: "600",
        textAlign: "center",
      }}
      buttonWrapperClasses="inline mx-auto justify-center items-center md:pb-2 pb-4" // Center buttons in mobile
      buttonStyle={{
        background: "#1d4ed8",
        color: "#FFFFFF",
        fontSize: "14px",
        fontWeight: "bold",
        textTransform: "uppercase",
        borderRadius: "5px",
        padding: "4px 20px",
        width: "100px",
        margin: "0 10px"
      }}
      declineButtonStyle={{
        background: "#d32f2f",
        color: "#FFFFFF",
        fontSize: "14px",
        fontWeight: "bold",
        textTransform: "uppercase",
        borderRadius: "5px",
        padding: "4px 20px",
        width: "100px",
        margin: "0"
      }}
      expires={150}
      onAccept={() => {
        window.gtag('consent', 'update', {
          'ad_storage': 'granted',
          'analytics_storage': 'granted'
        });
      }}
      onDecline={() => {
        window.gtag('consent', 'update', {
          'ad_storage': 'denied',
          'analytics_storage': 'denied'
        });
      }}
    >
      <span className="hidden md:inline-block font-semibold text-xs">
      This website uses cookies for a better user experience. You can choose to accept or decline cookies.
      </span>
      <span className="md:hidden font-semibold text-xs">
      This website uses cookies for a better user experience.
      </span>
    </CookieConsent>
  );
};

export default CookieBanner;