import React, { useState } from "react";
import Select from "react-dropdown-select";
import InputBox from "./input";

const Items = ({ children }) => (
  <div className="react-select-items-custom w-full appearance-none">
    {children}
  </div>
);

const Item = ({ onClick, children }) => (
  <div
    className="react-select-item-custom w-full hover:bg-blue-700 hover:text-white transition-all duration-200 ease-in-out py-1 px-3 cursor-pointer text-sm"
    onClick={onClick}
  >
    {children}
  </div>
);

const ItemLabel = ({ children }) => (
  <div className="react-select-item-label-custom w-full">{children}</div>
);

const customDropdownRenderer = ({ props, state, methods }) => {
  const regexp = new RegExp(state?.search, "i");
  const filteredoption = props?.options.filter((item) =>
    regexp.test(item[props?.searchBy] || item?.label)
  );
  const otherItem = props?.options.filter((item) => "Other" == item?.name);
  return (
    <div className="bg-white">
      <Items>
        {filteredoption?.length < 1 ? (
          <Item
            key={"other"}
            onClick={() => {
              if (props?.noDataLabel === "Select 'Other'") {
                // if the select is item category then get other from option and add it
                if (props?.placeholder === "Select Item Category ") {
                  if (otherItem?.length > 0) {
                    methods.addItem({ ...otherItem[0] });
                  }
                } else {
                  methods.addItem({
                    id: "Other",
                    name: "Other",
                  });
                }
              }
            }}
          >
            <ItemLabel>
              {props?.noDataLabel === "Select 'Other'"
                ? "Select 'Other'"
                : props?.noDataLabel}
            </ItemLabel>
          </Item>
        ) : (
          filteredoption?.map((option, id) => (
            <Item key={id} onClick={() => methods.addItem(option)}>
              <ItemLabel>{option[props?.labelField]}</ItemLabel>
            </Item>
          ))
        )}
      </Items>
    </div>
  );
};
const SelectBox = ({
  labelValue,
  classnames,
  options,
  valueField,
  labelField,
  onChange,
  values,
  ...rest
}) => {
  const option = [];
  const [value, setValue] = useState();
  return (
    <div className={`${classnames}`}>
      <label
        htmlFor={labelValue}
        className="text-xs text-slate-500 font-semibold"
      >
        {labelValue}
      </label>
      <Select
        style={{
          outline: "none",
          border: "2px solid #e2e8f0",
          boxShadow: "none",
          borderRadius: "0.375rem",
          color: "#334155",
          padding: "0 0.5rem",
          fontSize: "14px !important",
        }}
        placeholder={`Select ` + labelValue}
        className={`${classnames} appearance-none font-semibold cursor-text bg-slate-50 rounded-lg my-1 border-2 border-slate-200 text-slate-700 text-sm h-12`}
        options={options || option}
        onChange={(values) => {
          setValue(values);
          if (onChange) {
            onChange(values);
          }
        }}
        // inputRenderer={({ props, state, methods, inputRef }) => {
        //   return <input ref={inputRef} {...props} />;
        // }}
        // contentRenderer={({ props, state, methods }) => <div {...props} />}
        dropdownRenderer={customDropdownRenderer}
        searchable
        searchBy={labelField || "name"}
        multi={false}
        values={values || value}
        labelField={labelField || "name"}
        valueField={valueField || "id"}
        {...rest}
      />
    </div>
  );
};
export default SelectBox;
