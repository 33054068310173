import React, { useState, useEffect } from 'react';
import { getRibbonData } from '../utility';
import { BsCheck2Circle } from 'react-icons/bs';

const Ribbon = () => {
  const [ribbonContent, setRibbonContent] = useState({ ribbonitems: [] });

  useEffect(() => {
    const data = getRibbonData();
    setRibbonContent(data);
  }, []);

  return (
    <div className="bg-blue-700 text-white md:py-8 py-8">
      <div className="flex flex-col md:flex-row md:justify-between">
        {ribbonContent.ribbonitems.map((item, index) => (
          <div key={index} className="w-full pb-4 md:pb-0 md:w-1/3 flex items-center justify-center flex-row">
            <span className="mr-2 md:block hidden">
              {React.createElement(BsCheck2Circle, { size: 32 })}
            </span>
            <span className="text-lg font-bold">{item.text}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Ribbon;