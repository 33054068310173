import React, { useEffect, useState } from "react";
import CheckBox from "../checkbox";
import InputBox from "../input";
import SelectAirport from "../select_inputs/select_airport";
import SelectAirline from "../select_inputs/select_airline";
import SelectCarRental from "../select_inputs/select_car_rental";
import ErrorMessage from "../error_message";
import TagsInput from "react-tagsinput";

const fieldsAirport = {
  ["at airport"]: [
    "departure airport",
    "arrival airport",
    "lost airport",
    "airline",
    "flight number",
    "where you lost it",
  ],
  ["on flight"]: [
    "departure airport",
    "arrival airport",
    "lost airport",
    "airline",
    "flight number",
    "seat number",
  ],
  ["tsa"]: [
    "departure airport",
    "arrival airport",
    "lost airport",
    "airline",
    "flight number",
    "where you lost it",
  ],
  // ["rental car"]: [
  //   "car rental agency",
  //   "where you lost it",
  //   "departure airport",
  //   "additional comment",
  // ],
};

const LocationAirport = ({ locationDetails, handleSetReport, errors }) => {
  const [location, setLocation] = useState([...locationDetails]);
  const handleLocation = async (key, data) => {
    try {
      let dataCopy = [...location];
      if (dataCopy?.length > 0) {
        const findIndex = dataCopy.findIndex((d) => d?.title === key);
        if (findIndex >= 0) {
          dataCopy[findIndex] = { title: key, value: data };
        } else {
          dataCopy.push({ title: key, value: data });
          // console.log(dataCopy);
        }
      } else {
        dataCopy = [...locationDetails];
        dataCopy.push({ title: key, value: data });
      }
      setLocation(dataCopy);
    } catch (e) {}
  };
  const handleRemoveExcess = async (dataCopy, findIndex) => {
    try {
      const ar = ["lost place"];
      dataCopy[findIndex].value?.forEach((d) => {
        ar.push(...fieldsAirport[d]);
      });
      const uniqueArray = new Set(ar);
      const filtered = dataCopy?.filter((item) => uniqueArray.has(item?.title));
      return { dataCopy: filtered };
    } catch (e) {}
  };

  const handleCheckbox = async (value, checked) => {
    try {
      let dataCopy = [...location];
      const findIndex = dataCopy.findIndex((d) => d?.title === "lost place");
      if (findIndex >= 0) {
        if (!checked) {
          const indexOfPlace = dataCopy[findIndex]?.value?.findIndex(
            (d) => d === value
          );
          dataCopy[findIndex]?.value?.splice(indexOfPlace, 1);
          //  handle removing the excess data
          const handleExcess = await handleRemoveExcess(dataCopy, findIndex);
          if (handleExcess?.dataCopy?.length > 0) {
            dataCopy = handleExcess?.dataCopy;
          }
        } else {
          dataCopy[findIndex]?.value?.push(value);
        }
      } else {
        dataCopy.push({ title: "lost place", value: [value] });
      }
      setLocation(dataCopy);
    } catch (e) {}
  };
  const handleRemoveItem = async (key) => {
    try {
      const updatedArray = location.filter((item) => item.title !== key);
      setLocation(updatedArray);
    } catch (e) {}
  };

  useEffect(() => {
    if (location?.length > 0) {
      handleSetReport({ location_details: location });
    }
  }, [location]);

  return (
    <div className="">
      <div className="text-xs text-slate-500 font-semibold">
        Choose multiple places where you think you might have lost the item
      </div>
      <ErrorMessage condition={errors?.lostPlace} message={errors?.lostPlace} />
      <div className="mt-4">
        <div className="flex gap-x-16 flex-wrap">
          <div>
            <CheckBox
              labelValue={"Airport"}
              checked={locationDetails
                ?.find((l) => l?.title === "lost place")
                ?.value?.includes("at airport")}
              onChange={(e) => {
                handleCheckbox("at airport", e.target.checked);
              }}
            />
          </div>
          <div>
            <CheckBox
              labelValue={"On Flight"}
              checked={locationDetails
                ?.find((l) => l?.title === "lost place")
                ?.value?.includes("on flight")}
              onChange={(e) => {
                handleCheckbox("on flight", e.target.checked);
              }}
            />
          </div>
          <div>
            <CheckBox
              labelValue={"TSA / Security Check Point"}
              checked={locationDetails
                ?.find((l) => l?.title === "lost place")
                ?.value?.includes("tsa")}
              onChange={(e) => {
                handleCheckbox("tsa", e.target.checked);
              }}
            />
          </div>
          {/* <div>
            <CheckBox
              labelValue={"Rental Car"}
              checked={locationDetails
                ?.find((l) => l?.title === "lost place")
                ?.value?.includes("rental car")}
              onChange={(e) => {
                handleCheckbox("rental car", e.target.checked);
              }}
            />
          </div> */}
        </div>

        {/* at airport */}
        <div className="grid mb-4 my-1 gap-x-4 grid-cols-1 md:grid-cols-2">
          {/* departure airport */}
          {locationDetails
            ?.find((l) => l?.title === "lost place")
            ?.value?.some((ele) =>
              ["at airport", "on flight", "tsa", "rental car"].includes(ele)
            ) ? (
            <div className="w-full">
              <SelectAirport
                values={
                  locationDetails?.find(
                    (l) => l?.title === "departure airport"
                  ) || {}
                }
                onChange={(e) => {
                  if (!e[0]?.name || e?.length < 1) {
                    handleRemoveItem("departure airport");
                  } else if (e[0]?.name !== "Other") {
                    handleLocation("departure airport", {
                      name: e[0]?.name,
                      id: e[0]?.id,
                      inventory: "airport",
                    });
                  } else {
                    handleLocation("departure airport", "");
                  }
                }}
                classnames={""}
                labelValue={"Departure Airport *"}
                handleInput={(e) => {
                  handleLocation("departure airport", e.target.value);
                }}
              />
              <ErrorMessage
                condition={errors && errors["departure airport"]}
                message={errors && errors["departure airport"]}
              />
            </div>
          ) : null}
          {/* arrival airport */}
          {locationDetails
            ?.find((l) => l?.title === "lost place")
            ?.value?.some((ele) =>
              ["at airport", "on flight", "tsa", "rental car"].includes(ele)
            ) ? (
            <div className="w-full">
              <SelectAirport
                values={
                  locationDetails?.find(
                    (l) => l?.title === "arrival airport"
                  ) || {}
                }
                onChange={(e) => {
                  if (!e[0]?.name || e?.length < 1) {
                    handleRemoveItem("arrival airport");
                  } else if (e[0]?.name !== "Other") {
                    handleLocation("arrival airport", {
                      name: e[0]?.name,
                      id: e[0]?.id,
                      inventory: "airport",
                    });
                  } else {
                    handleLocation("arrival airport", "");
                  }
                }}
                classnames={""}
                labelValue={"Arrival Airport *"}
                handleInput={(e) => {
                  handleLocation("arrival airport", e.target.value);
                }}
              />
              <ErrorMessage
                condition={errors && errors["arrival airport"]}
                message={errors && errors["arrival airport"]}
              />
              <ErrorMessage
                condition={errors && errors["similar_airport"]}
                message={
                  (errors && errors["similar_airport"]) ||
                  (locationDetails?.find(
                    (l) => l?.title === "departure airport"
                  )?.value?.name &&
                    locationDetails?.find((l) => l?.title === "arrival airport")
                      ?.value?.name &&
                    locationDetails?.find(
                      (l) => l?.title === "departure airport"
                    )?.value?.name ===
                      locationDetails?.find(
                        (l) => l?.title === "arrival airport"
                      )?.value?.name)
                    ? "Arrival Airport can't be similar to Departure Airport"
                    : null
                }
              />
            </div>
          ) : null}
          {/* airline */}
          {locationDetails
            ?.find((l) => l?.title === "lost place")
            ?.value?.some((ele) =>
              ["at airport", "on flight", "tsa"].includes(ele)
            ) ? (
            <div className="w-full">
              <SelectAirline
                values={
                  locationDetails?.find((l) => l?.title === "airline") || {}
                }
                classnames={""}
                labelValue={"Airline *"}
                onChange={(e) => {
                  if (!e[0]?.name || e?.length < 1) {
                    handleRemoveItem("airline");
                  } else if (e[0].name !== "Other") {
                    handleLocation("airline", {
                      name: e[0]?.name,
                      id: e[0]?.id,
                      inventory: "airline",
                    });
                  } else {
                    handleLocation("airline", "");
                  }
                }}
                handleInput={(e) => {
                  handleLocation("airline", e.target.value);
                }}
              />
              <ErrorMessage
                condition={errors?.airline}
                message={errors?.airline}
              />
            </div>
          ) : null}
          {/* lost airport */}
          {locationDetails
            ?.find((l) => l?.title === "lost place")
            ?.value?.some((ele) =>
              ["at airport", "on flight", "tsa", "rental car"].includes(ele)
            ) ? (
            <div className="w-full">
              <SelectAirport
                values={
                  locationDetails?.find((l) => l?.title === "lost airport") ||
                  {}
                }
                onChange={(e) => {
                  if (!e[0]?.name || e?.length < 1) {
                    handleRemoveItem("lost airport");
                  } else if (e[0]?.name !== "Other") {
                    handleLocation("lost airport", {
                      name: e[0]?.name,
                      id: e[0]?.id,
                      inventory: "airport",
                    });
                  } else {
                    handleLocation("lost airport", "");
                  }
                }}
                classnames={""}
                labelValue={"Airport you lose the item? *"}
                handleInput={(e) => {
                  handleLocation("lost airport", e.target.value);
                }}
              />
              <ErrorMessage
                condition={errors && errors["lost airport"]}
                message={errors && errors["lost airport"]}
              />
            </div>
          ) : null}
          {locationDetails
            ?.find((l) => l?.title === "lost place")
            ?.value?.some((ele) =>
              ["at airport", "on flight", "tsa"].includes(ele)
            ) ? (
            <div className="w-full">
              <InputBox
                className={"w-full"}
                value={
                  locationDetails?.find((l) => l?.title === "flight number")
                    ?.value ||
                  "" ||
                  ""
                }
                placeholder={"Flight number *"}
                onChange={(e) => {
                  handleLocation("flight number", e.target.value);
                }}
              />
              {/* <label className="text-xs text-slate-500 font-semibold">
                Flight number *{" "}
              </label>
              <TagsInput
                onlyUnique
                inputProps={{
                  className: "react-tagsinput-input",
                  placeholder: "Add Another",
                }}
                value={
                  locationDetails?.find((l) => l?.title === "flight number")
                    ?.value ?? []
                }
                onChange={(e) => {
                  handleLocation("flight number", e);
                }}
              />
              <div className="text-xs text-gray-400 font-semibold  mb-2">
                Add flight number and press enter
              </div> */}
              <ErrorMessage
                condition={errors?.["flight number"]}
                message={errors && errors["flight number"]}
              />
            </div>
          ) : null}
          {locationDetails
            ?.find((l) => l?.title === "lost place")
            ?.value?.some((ele) =>
              ["at airport", "tsa", "rental car"].includes(ele)
            ) ? (
            <div className="w-full">
              {" "}
              <InputBox
                className={"w-full"}
                value={
                  locationDetails?.find((l) => l?.title === "where you lost it")
                    ?.value ||
                  "" ||
                  ""
                }
                placeholder={"Where you lost it"}
                onChange={(e) => {
                  handleLocation("where you lost it", e.target.value);
                }}
              />
              <ErrorMessage
                condition={errors && errors["where you lost it"]}
                message={errors && errors["where you lost it"]}
              />
            </div>
          ) : null}
          {locationDetails
            ?.find((l) => l?.title === "lost place")
            ?.value?.some((ele) => ["on flight"].includes(ele)) ? (
            <InputBox
              value={
                locationDetails?.find((l) => l?.title === "seat number")
                  ?.value ||
                "" ||
                ""
              }
              className={"w-full"}
              placeholder={"Seat number"}
              onChange={(e) => {
                handleLocation("seat number", e.target.value);
              }}
            />
          ) : null}
          {locationDetails
            ?.find((l) => l?.title === "lost place")
            ?.value?.some((ele) => ["rental car"].includes(ele)) ? (
            <div>
              {" "}
              <SelectCarRental
                values={
                  locationDetails?.find(
                    (l) => l?.title === "car rental agency"
                  ) || {}
                }
                classnames={""}
                labelValue={"Car Rental Agency *"}
                onChange={(e) => {
                  if (!e[0]?.name || e?.length < 1) {
                    handleRemoveItem("car rental agency");
                  } else if (e[0].name !== "Other") {
                    handleLocation("car rental agency", {
                      name: e[0]?.name,
                      id: e[0]?.id,
                      inventory: "car_rental",
                    });
                  } else {
                    handleLocation("car rental agency", "");
                  }
                }}
                handleInput={(e) => {
                  handleLocation("car rental agency", e?.target?.value);
                }}
              />
              <ErrorMessage
                condition={errors && errors["car rental agency"]}
                message={errors && errors["car rental agency"]}
              />
            </div>
          ) : null}
          {locationDetails
            ?.find((l) => l?.title === "lost place")
            ?.value?.some((ele) => ["rental car"].includes(ele)) ? (
            <InputBox
              className={"w-full"}
              value={
                locationDetails?.find((l) => l?.title === "additional comment")
                  ?.value || ""
              }
              placeholder={"Additional Info"}
              onChange={(e) => {
                handleLocation("additional comment", e.target.value);
              }}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};
export default LocationAirport;
