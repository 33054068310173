import "./paypal.css";
import React, { useEffect, useState, useRef } from "react";
import Typography from "./typography";
import InputBox from "./input";
import Button from "./button";
import TextArea from "./textarea";
import "react-phone-number-input/style.css";
import PhoneInput, {
  getCountryCallingCode,
  parsePhoneNumber,
} from "react-phone-number-input";
import SelectCountry from "./select_inputs/select_country";
import CheckBox from "../components/checkbox";
import SelectState from "./select_inputs/select_state";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { lambda_end_point } from "../config";
import { useFetch } from "use-http";
import { Validate } from "../utils/validation";
import { useToasts } from "react-toast-notifications";
import { useNavigate } from "react-router-dom";
import { gql, useQuery } from "urql";
import { GetConfigQuery } from "../queries";
import { LoadingIcon } from "../icons";
import Payment from "./auth/payment";
import ErrorMessage from "./error_message";
import PaypalButton from "./paypal/buttons";
import Benefits from "../components/benefits";
import { Link } from "react-router-dom";

// import { HostedForm ,AcceptHosted} from "react-acceptjs";
// const authData = {
//   apiLoginID: "24kpLwQXDK6q",
//   clientKey: "SIMON",
// };
{
  /* Transparent overlay to capture events */
}

const OverLay = ({ overlayRef, setTryingToEdit, check }) => {
  if (check) {
    return (
      <div
        ref={overlayRef}
        onClick={(event) => {
          console.log("hello");
          setTryingToEdit(true);
        }}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          pointerEvents: "auto",
        }}
      ></div>
    );
  }
};
const PersonalDetails = ({
  setSelectedStepper,
  stepperItems,
  details,
  handleSetReport,
  fileReport,
  loading,
  clearReport,
  errors,
  setErrors,
  activeStep,
  setActiveStep,
  schema,
  // check,
  // setCheck,
}) => {
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const [scrollPosition, setScrollPosition] = useState(0);
  const [paymentLoading, setLoading] = useState(false);
  const [check, setCheck] = useState(false);
  const [tyingToEdit, setTryingToEdit] = useState(false);
  const [configResults, reexecuteConfigQuery] = useQuery({
    query: GetConfigQuery,
    pause: true,
  });

  const {
    data: configsData,
    fetching: configsFetching,
    error: configsError,
  } = configResults;

  const { post, response, error } = useFetch(lambda_end_point, {
    cachePolicy: "no-cache",
    "Content-Type": "application/json",
  });
  const [countryCode, setCountryCode] = useState();
  const [tempTimer, setTempTimer] = useState(false);

  useEffect(() => {
    if (tempTimer) {
      setTimeout(() => {
        setTempTimer(false);
      }, 50);
    }
  }, [tempTimer]);

  // useEffect(() => {

  //   delete details?.state;
  // }, [countryCode]);

  const createOrder = async (data) => {
    try {
      let reportId;
      const reportIDLS = localStorage.getItem("reportId");
      console.log(reportIDLS, "ls report id");
      if (reportIDLS && reportIDLS?.trim() !== "") {
        reportId = reportIDLS;
      } else {
        const report = await fileReport(configsData?.config_by_pk);

        if (report?.id && report?.id?.trim() !== "") {
          reportId = report?.id;
          localStorage.setItem("reportId", report?.id);
        } else {
          addToast(report?.error || "report couldn't be filed", {
            appearance: "error",
            autoDismiss: true,
          });
          throw report?.error;
        }
      }
      if (reportId && reportId?.trim() !== "") {
        console.log("have a report id", details?.phone_number);
        const parsedPhoneNumber = parsePhoneNumber(details?.phone_number);
        const countryCallingCode = parsedPhoneNumber?.country;
        const callingCode = getCountryCallingCode(countryCallingCode);
        console.log(callingCode, "code");
        const send = await post(`paypal/create-order`, {
          reference_id: reportId,
          paypal_type: data.paymentSource,
          email: details?.email,
          first_name: details?.first_name,
          last_name: details?.last_name,
          phone_number: details?.phone_number?.split(callingCode)[1],
          country_code: countryCallingCode,
          postal_code: details?.zip,
        });
        return send?.data?.order?.id;
      } else {
        throw "Some error Occured. Please refresh the page and try again";
      }
    } catch (e) {
      console.log(e);
      return false;
    }
  };
  const handleSubmit = async (data) => {
    try {
      setLoading(true);
      const send = await post(`paypal/capture-payment`, {
        order_id: data?.orderID,
      });
      try {
        // window.gtag("event", "conversion", {});

        window.gtag("event", "conversion", {
          send_to: "AW-758624954/lbfwCN7Ez4MYELrl3ukC",
          transaction_id: send?.data?.order?.id,
        });
      } catch (e) {
        console.log(e, "<<< === error for window gtag");
      }
      const reportIDLS = localStorage.getItem("reportId");

      navigate(`/${reportIDLS}?success=true`);
      await clearReport();

      return send?.data?.order?.id;
    } catch (e) {
      return false;
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    reexecuteConfigQuery({ requestPolicy: "network-only" });
  }, []);
  const handleReport = async () => {
    const validate = await Validate(details, schema);

    if (validate?.error) {
      if (Object?.keys(errors)?.length > 0) {
        setErrors(validate?.error);
      }
      setActiveStep({ ...activeStep, 2: { valid: false, navigate: true } });
    } else {
      setErrors({});
      setActiveStep({ ...activeStep, 2: { valid: true, navigate: true } });
    }
  };
  useEffect(() => {
    handleReport();
  }, [details]);
  useEffect(() => {
    if (details?.country === "United States") {
      setCountryCode("US");
    } else if (details?.country === "Canada") {
      setCountryCode("CA");
    }
  });
  // const handleScroll = () => {
  //   const position = window.scrollY;
  //   setScrollPosition(position);
  // };

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll, { passive: true });
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, [check]);
  const overlayRef = useRef(null);

  return (
    <>
      {paymentLoading || configsFetching ? (
        <div className="text-start  flex items-center h-64 my-1 max-h-screen justify-center ">
          <LoadingIcon className="h-32 rounded-full text-blue-200 w-32" />
        </div>
      ) : configsData ? (
        <>
          {check && tyingToEdit ? (
            <div className="text-xs text-red-500 font-semibold text-center mt-16">
              You have to uncheck the consent checkbox to edit information
            </div>
          ) : null}
          <div className="text-start mt-16">
            <Typography title={"Personal Details"} />
            <div className={`grid  grid-cols-1 gap-x-4  md:grid-cols-2 mb-10`}>
              <div>
                <InputBox
                  disabled={check}
                  required
                  className={"capitalize w-full"}
                  placeholder={"First Name *"}
                  onChange={(e) => {
                    handleSetReport({ first_name: e.target.value });
                  }}
                  value={details?.first_name}
                />

                <ErrorMessage
                  id={"first_name"}
                  message={errors && errors["first_name"]}
                />
                <OverLay
                  overlayRef={overlayRef}
                  setTryingToEdit={setTryingToEdit}
                  check={check}
                />
              </div>
              <div>
                <InputBox
                  disabled={check}
                  required
                  className={"capitalize w-full"}
                  placeholder={"Last Name *"}
                  onChange={(e) => {
                    handleSetReport({ last_name: e.target.value });
                  }}
                  value={details?.last_name}
                />
                <ErrorMessage
                  id={"last_name"}
                  message={errors && errors["last_name"]}
                />
                <OverLay
                  overlayRef={overlayRef}
                  setTryingToEdit={setTryingToEdit}
                  check={check}
                />
              </div>
              <div>
                {/* <InputBox
                  // pattern={"/^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,}$/i"}
                  disabled={check}
                  required
                  className={"w-full"}
                  type={"email"}
                  placeholder={"Email *"}
                  onChange={(e) => {
                    handleSetReport({ email: e.target.value });
                  }}
                  value={details?.email}
                /> */}
                <InputBox
                  disabled={check}
                  required
                  className={"w-full"}
                  type={"email"} 
                  placeholder={"Email *"}
                  onChange={(e) => {
                    // Convert the email input to lowercase
                    const lowercasedEmail = e.target.value.toLowerCase();
                    handleSetReport({ email: lowercasedEmail });
                  }}
                  value={details?.email}
                />

                <ErrorMessage
                  id={"email_validation"}
                  message={errors && errors["email_validation"]}
                />
                <ErrorMessage
                  id={"email"}
                  message={errors && errors["email"]}
                />
                <OverLay
                  overlayRef={overlayRef}
                  setTryingToEdit={setTryingToEdit}
                  check={check}
                />
              </div>
              <div>
                {
                  <label className="text-xs text-slate-500 font-semibold">
                    Phone Number *
                  </label>
                }
                <PhoneInput
                  disabled={check}
                  className={`${
                    check && "cursor-default opacity-30"
                  }  appearance-none font-semibold cursor-text bg-slate-50 rounded-lg px-2 my-1 border-2 border-slate-200 text-slate-700 text-sm h-12
                  `}
                  countryOptionsOrder={["US", "CA", "GB"]}
                  international={true}
                  countryCallingCodeEditable={false}
                  placeholder="Phone Number *"
                  value={details?.phone_number}
                  onChange={(value) => {
                    handleSetReport({ phone_number: value });
                  }}
                  defaultCountry={"US"}
                />{" "}
                {/* <CheckBox
                  inputClassName="h-4 w-4"
                  labelClassName="text-xs"
                  checked={details?.send_sms}
                  labelValue={"Send me Updates via SMS"}
                  onChange={async (e) => {
                    const checked = e.target.checked;
                    handleSetReport({ send_sms: checked });
                  }}
                /> */}
                <ErrorMessage
                  id={"phone_validation"}
                  message={errors && errors["phone_validation"]}
                />
                <ErrorMessage
                  id={"phone_number"}
                  message={errors && errors["phone_number"]}
                />
                <OverLay
                  overlayRef={overlayRef}
                  setTryingToEdit={setTryingToEdit}
                  check={check}
                />
              </div>
              <div>
                <SelectCountry
                  disabled={check}
                  values={
                    details?.country?.trim() === ""
                      ? {}
                      : { value: details?.country }
                  }
                  required
                  classnames={""}
                  labelValue={"Country * "}
                  valueField={"name"}
                  onChange={(values) => {
                    setTempTimer(true);
                    if (values?.length > 0) {
                      setCountryCode(values[0]?.isoCode);
                      handleSetReport({ country: values[0]?.name, state: "" });
                      // handleSetReport({ state: "" });
                    } else {
                      setCountryCode("US");
                      handleSetReport({ country: "" });
                    }
                  }}
                />
                <ErrorMessage
                  id={"country"}
                  message={errors && errors["country"]}
                />
                <OverLay
                  overlayRef={overlayRef}
                  setTryingToEdit={setTryingToEdit}
                  check={check}
                />
              </div>
              {details?.country &&
              details?.country?.trim !== "" &&
              !tempTimer &&
              (countryCode === "US" || countryCode === "CA") ? (
                <div>
                  <SelectState
                    disabled={check}
                    classnames={""}
                    countryCode={countryCode}
                    labelValue={
                      countryCode === "US"
                        ? "State"
                        : countryCode === "CA"
                        ? "Province"
                        : ""
                    }
                    values={
                      !details?.state || details?.state?.trim() === ""
                        ? {}
                        : { value: details?.state }
                    }
                    onChange={(values) => {
                      handleSetReport({ state: values[0]?.name });
                    }}
                  />
                  <ErrorMessage
                    id={"state"}
                    message={errors && errors["state"]}
                  />
                  <OverLay
                    overlayRef={overlayRef}
                    setTryingToEdit={setTryingToEdit}
                    check={check}
                  />
                </div>
              ) : null}

              <div className="grid md:col-span-2 w-full">
                <TextArea
                  disabled={check}
                  className={"h-24 p-2"}
                  placeholder={"Address * (include apt/suite number)"}
                  onChange={(e) => {
                    handleSetReport({ address: e.target.value });
                  }}
                  value={details?.address}
                />
                <ErrorMessage message={errors && errors["address"]} />
                <OverLay
                  overlayRef={overlayRef}
                  setTryingToEdit={setTryingToEdit}
                  check={check}
                />
              </div>
              <div>
                <InputBox
                  disabled={check}
                  required
                  className={"w-full"}
                  placeholder={"City *"}
                  onChange={(e) => {
                    handleSetReport({ city: e.target.value });
                  }}
                  value={details?.city}
                />
                <ErrorMessage id={"city"} message={errors && errors["city"]} />
                <OverLay
                  overlayRef={overlayRef}
                  setTryingToEdit={setTryingToEdit}
                  check={check}
                />
              </div>
              <div>
                <InputBox
                  disabled={check}
                  required
                  className={"w-full"}
                  placeholder={"Zip / Postal code *"}
                  onChange={(e) => {
                    handleSetReport({ zip: e.target.value });
                  }}
                  value={details?.zip}
                />
                <ErrorMessage id={"zip"} message={errors && errors["zip"]} />
                <OverLay
                  overlayRef={overlayRef}
                  setTryingToEdit={setTryingToEdit}
                  check={check}
                />
              </div>
            </div>
            <div className="pb-10">
              <Benefits />
            </div>
            <Typography title={"Payment Details"} />
            <div className="mt-4">
              {/* <textarea
                className="w-full appearance-none font-medium	 cursor-text bg-slate-50 rounded-lg p-3 my-1 border-2 border-slate-200 text-slate-700 text-xs"
                rows="8"
                disabled
                value={`By using this service, you agree to pay the NON-REFUNDABLE FEE of $${
                  configsData?.config_by_pk?.price || "49"
                } to file your lost item claim. You hereby provide Lost and Found Concierge with the authority to contact lost and found departments and custodians on your behalf, including the filing of reports. Furthermore, THERE ARE NO GUARANTEES OF RECLAIMING LOST ITEMS. You understand that we provide services on your behalf filing a lost item claim and interacting with such lost and found departments and custodians to assist you to retrieve your lost item. This is a Non-Refundable purchase. Under no circumstance will you be entitled to a refund. Lost and Found Concierge does not and will not issue refunds. Our fees do not include shipping and shipping fees must be paid separately.

You also certify that the information provided is true to the best of your knowledge. Any false or fraudulent statement may constitute as being a fraud and may be subject to any applicable civil and criminal penalties.You also understand and acknowledge that Lost and Found Concierge is not a lost and found department and does not serve as a lost and found custodian but a lost and found document filing service and charges a convenience fee for our service. Lost and Found Concierge has no partnership with, and is not associated or affiliated with any config, airport, the TSA, theme park, resort, timeshare, vacation rental, ride share company, taxi, rental car company, transit system, cruise line or other venue or entity where you may have lost an item. You acknowledge that you may file this report directly on your own without paying a convenience fee but elect to have Lost and Found Concierge file your report and pay the required fee solely as a convenience to you.
          
Electronic Signature Consent & Agreement: By submitting your electronic signature, you are signing this Agreement electronically. You agree your electronic signature is the legal equivalent of your manual signature on this Agreement. By selecting "I Accept" you consent to be legally bound by this Agreement's terms and conditions. You further agree that your use of a keypad, mouse or other device to select an item, button, icon or similar act/action, or to otherwise provide Lost and Found Concierge instructions via this website, or in accessing or making any transaction regarding any agreement, acknowledgement, consent terms, disclosures or conditions constitutes your signature (hereafter referred to as "E-Signature"), acceptance and agreement as if actually signed by you in writing. You also agree that no certification authority or other third-party verification is necessary to validate your E-Signature and that the lack of such certification or third-party verification will not in any way affect the enforceability of your E-Signature or any resulting contract between you and Lost and Found Concierge. You also represent that you are authorized to enter into this Agreement for all persons who own or are authorized to access any of your accounts and that such persons will be bound by the terms of this Agreement. You further agree that each use of your E-Signature in obtaining Lost and Found Concierge services constitutes your agreement to be bound by the terms and conditions of the Lost and Found Concierge Disclosures and Agreements as they exist on the date of your E-Signature.
          
Valid and current email address, notification, and updates: Your current valid email address is required in order for you to obtain Lost and Found Concierge services. You agree to keep Lost and Found Concierge informed of any changes in your email address. Lost and Found Concierge may notify you through email when an Electronic Communication or update on your lost item is made available. It is recommended that you print a copy of this Agreement for future reference.
          
By clicking the "Submit" button, you agree to be bound by the terms of this agreement.You also acknowledge that Lost and Found Concierge in no way warrants the information you submit to us for filing your lost item claims. We file your claims based on the information you provide and do not guarantee any results.
          `}
              ></textarea> */}
              <div className="mt-4">
                <CheckBox
                  checked={check}
                  labelValue={
                    <p>
                      I have read and agreed to the{" "}
                      <Link
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-700 font-extrabold"
                        to="/payment-terms"
                      >
                        Payment Terms and Conditions
                      </Link>
                      .{" "}
                      {/* <span className="font-bold text-sm">
                        ${configsData?.config_by_pk?.price || "35"}
                      </span> */}
                    </p>
                  }
                  onChange={async (e) => {
                    const checked = e.target.checked;

                    const validate = await Validate(details, schema);
                    if (validate?.error) {
                      setErrors(validate?.error);
                      const element =
                        Object?.keys(validate?.error)?.length > 0
                          ? Object?.keys(validate?.error)[0]
                          : null;
                      if (element) {
                        const section = document.querySelector(`#${element}`);
                        section?.scrollIntoView({
                          behavior: "smooth",
                          block: "center",
                        });
                      }
                    } else {
                      console.log("no error");
                      console.log(checked, "checked");
                      setErrors({});
                      setTryingToEdit(false);
                      setCheck(checked);
                    }
                  }}
                />
              </div>
            </div>
            {/* <div className="grid gap-4 md:grid-cols-2 mt-8"> for Auth button ON */}
            <div className="flex justify-between w-full">
              {" "}
              {/*For paypal on*/}
              {configsData?.config_by_pk?.pay_method?.auth_net ? (
                <Payment
                  address={details?.address}
                  city={details?.city}
                  clearReport={clearReport}
                  country={details?.country}
                  fileReport={fileReport}
                  first_name={details?.first_name}
                  last_name={details?.last_name}
                  zip={details?.zip}
                  checked={check}
                  setLoading={setLoading}
                  apiLoginID={configsData?.config_by_pk?.auth_login_id}
                  clientKey={configsData?.config_by_pk?.auth_client_id}
                  scrollPosition={scrollPosition}
                  loading={loading}
                  config={configsData?.config_by_pk}
                />
              ) : null}
              <PaypalButton
                check={check}
                clearReport={clearReport}
                configsData={configsData}
                createOrder={createOrder}
                details={details}
                handleSubmit={handleSubmit}
                loading={loading}
                schema={schema}
                setErrors={setErrors}
                setSelectedStepper={setSelectedStepper}
                key={"personal-details-paypal-button"}
                scrollPosition={scrollPosition}
              />
            </div>
          </div>
        </>
      ) : (
        <div className="text-start font-semibold flex items-center h-64 my-1 max-h-screen justify-center ">
          Connectivity issue. Please check your connection and try refreshing
          later. If the problem persists, feel free to contact us.{" "}
        </div>
      )}

      <div className="flex justify-start mt-8 ">
        <span
          onClick={(e) => {
            e.preventDefault();
            setSelectedStepper(stepperItems[1]);
          }}
          className={`${
            loading ? "cursor-not-allowed" : "cursor-pointer"
          } disabled:bg-slate-300 rounded-full py-3  text-slate-500 font-bold text-lg flex justify-center hover:text-slate-700`}
        >
          Back
        </span>
      </div>
    </>
  );
};
export default PersonalDetails;
