import React, { useState, useEffect, useRef } from "react";
import { uploadAssets } from "../../utils/upload";
import ImageUploading from "react-images-uploading";
import { useToasts } from "react-toast-notifications";
import {
  IoCloseCircleOutline,
  IoCheckmarkCircleOutline,
} from "react-icons/io5";
import { MdOutlineFileUpload } from "react-icons/md";
import { AiOutlineStop } from "react-icons/ai";
import { progress } from "@material-tailwind/react";

const UploadImages = ({
  progress,
  setProgress,
  handleUpdateImage,
  reportImages,
  id,
  images,
  setImages,
  removeImageFromMainArray,
}) => {
  const maxNumber = 3;
  const { addToast } = useToasts();
  // to help ease display
  // progress
  const [tempProgress, setTempProgress] = useState();
  // image list with name and mb
  const [tempImages, setTempImages] = useState();
  // add progress imgs
  const setProgressFunction = (pr) => {
    if (id) {
      const temp = [...progress];
      temp.splice(id, 1, [...pr]);
      setProgress(temp);
    } else {
      setProgress(pr);
    }
  };
  const updateProgress = async (pr, index) => {
    console.log(pr, id, index, "progress", progress);
    const mainProgress = [...progress];
    mainProgress.splice(id, 1, pr);
    setProgress(mainProgress);
  };
  // store file name,mb
  const setImagesFunction = (img) => {
    if (id) {
      const temp = [...images];
      temp.splice(id, 1, [...img]);
      setImages(temp);
    } else {
      setImages([...img]);
    }
  };
  async function uploadAsset(item, i, pr) {
    return uploadAssets(
      item,
      `lost-item/${item?.name}`,
      id === undefined ? setProgressFunction : updateProgress,
      pr,
      i
    );
  }

  const updateMainArray = async (imageList, addUpdateIndex) => {
    try {
      const imgs = reportImages?.length > 0 ? [...reportImages] : [];
      const error = [];
      if (addUpdateIndex && addUpdateIndex?.length > 0) {
        const pr = progress[id]?.length > 0 ? [...progress[id]] : [];
        for (const index of addUpdateIndex) {
          pr[index] = { progress: 0 };
          if (imageList[index]?.file?.name) {
            updateProgress([...pr], index);
            const imageTemp = [...images];
            imageTemp?.splice(id, 1, imageList);
            setImages(imageTemp);
            const uploadFile = await uploadAsset(
              imageList[index]?.file,
              index,
              pr
            );
            if (uploadFile?.url) {
              imgs[index] = uploadFile?.url;
              handleUpdateImage([...imgs]);
            } else {
              pr[index] = { progress: -1 };
              updateProgress([...pr], index);
              error.push(
                `error uploading ${
                  index === 0 ? "1st" : index === 1 ? "2nd" : "3rd"
                } image.  please select the file and try again`
              );
              imageList?.splice(index, 1);
            }
          } else {
            const pr = progress;
            pr[index] = { progress: -1 };
            updateProgress([...pr], index);
            error.push(
              `error uploading ${
                index === 0 ? "1st" : index === 1 ? "2nd" : "3rd"
              } image.  please select the file and try again`
            );
          }
        }
      }
    } catch (e) {
      if (e?.length > 0) {
        e?.map((er) => {
          addToast(er, { appearance: "error", autoDismiss: true });
        });
      }
    }
  };
  const onChange = async (imageList, addUpdateIndex) => {
    // data for submit
    // if theres id for onchange and on remove it makes the issue
    // we'll handle it seperately
    if (id !== undefined) {
      updateMainArray(imageList, addUpdateIndex);
    } else {
      const imgs = reportImages?.length > 0 ? [...reportImages] : [];
      const error = [];
      try {
        if (addUpdateIndex && addUpdateIndex?.length > 0) {
          let pr = progress?.length > 0 ? [...progress] : [];
          for (const index of addUpdateIndex) {
            pr[index] = { progress: 0 };
            if (imageList[index]?.file?.name) {
              setProgressFunction([...pr]);

              const uploadFile = await uploadAsset(
                imageList[index]?.file,
                index,
                id === undefined ? pr : pr[id]
              );
              if (uploadFile?.url) {
                imgs[index] = uploadFile?.url;
                handleUpdateImage([...imgs]);
              } else {
                pr[index] = { progress: -1 };
                setProgressFunction([...pr]);
                error.push(
                  `error uploading ${
                    index === 0 ? "1st" : index === 1 ? "2nd" : "3rd"
                  } image.  please select the file and try again`
                );
                imageList?.splice(index, 1);
              }
            } else {
              const pr = progress;
              pr[index] = { progress: -1 };
              setProgressFunction([...pr]);
              error.push(
                `error uploading ${
                  index === 0 ? "1st" : index === 1 ? "2nd" : "3rd"
                } image.  please select the file and try again`
              );
            }
          }
          setImagesFunction(imageList);
        }

        if (error?.length > 0) {
          throw error;
        }
      } catch (e) {
        console.log(e, "error");
        if (e?.length > 0) {
          e?.map((er) => {
            addToast(er, { appearance: "error", autoDismiss: true });
          });
        }
      }
    }
  };

  const imageRemove = async (index) => {
    if (id === undefined) {
      if (progress?.length > 0) {
        if (index >= 0) {
          const pr = [...progress];
          const imageList = [...images];
          pr.splice(index, 1);
          imageList.splice(index, 1);
          setProgressFunction([...pr]);
          setImagesFunction([...imageList]);
        } else {
          setProgressFunction([]);
          setImagesFunction([]);
        }
      }
      if (reportImages > 0) {
        let img = [...reportImages];
        if (index) {
          img?.splice(index, 1);
        } else {
        }
        handleUpdateImage([...img]);
      } else {
        console.log("no images uploaded ");
      }
    } else {
      removeImageFromMainArray(index);
    }
  };
  useEffect(() => {
    setTempProgress(id !== undefined ? progress[id] : progress);
  }, [progress]);

  useEffect(() => {
    setTempImages(id !== undefined ? images[id] : images);
  }, [images]);

  return (
    <>
      <ImageUploading
        multiple
        value={tempImages}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
        acceptType={["jpg", "png", "jpeg"]}
        maxFileSize={1 * 1024 * 1024}
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
          errors,
        }) => {
          return (
            // write your building UI
            <div className="upload__image-wrapper">
              <div
                onClick={onImageUpload}
                {...dragProps}
                style={isDragging ? { color: "red" } : undefined}
                className="mt-4 text-base space-y-1 p-6 border-dotted border-2 border-[#256ef8] rounded-md font-medium  hover:bg-blue-100"
              >
                <div className="flex justify-between items-center">
                  <div className="cursor-pointer">
                    <div className="flex items-center space-x-2">
                      <svg
                        width="30"
                        height="30"
                        viewBox="0 0 48 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <mask
                          id="mask0_295_239"
                          style={{ maskType: "alpha" }}
                          maskUnits="userSpaceOnUse"
                          x="0"
                          y="0"
                          width="48"
                          height="48"
                        >
                          <rect width="48" height="48" fill="#D9D9D9" />
                        </mask>
                        <g mask="url(#mask0_295_239)">
                          <path
                            d="M13 40C9.96667 40 7.375 38.95 5.225 36.85C3.075 34.75 2 32.1833 2 29.15C2 26.55 2.78333 24.2333 4.35 22.2C5.91667 20.1667 7.96667 18.8667 10.5 18.3C11.3333 15.2333 13 12.75 15.5 10.85C18 8.95 20.8333 8 24 8C27.9 8 31.2083 9.35833 33.925 12.075C36.6417 14.7917 38 18.1 38 22C40.3 22.2667 42.2083 23.2583 43.725 24.975C45.2417 26.6917 46 28.7 46 31C46 33.5 45.125 35.625 43.375 37.375C41.625 39.125 39.5 40 37 40H26C24.9 40 23.9583 39.6083 23.175 38.825C22.3917 38.0417 22 37.1 22 36V25.7L18.8 28.8L16 26L24 18L32 26L29.2 28.8L26 25.7V36H37C38.4 36 39.5833 35.5167 40.55 34.55C41.5167 33.5833 42 32.4 42 31C42 29.6 41.5167 28.4167 40.55 27.45C39.5833 26.4833 38.4 26 37 26H34V22C34 19.2333 33.025 16.875 31.075 14.925C29.125 12.975 26.7667 12 24 12C21.2333 12 18.875 12.975 16.925 14.925C14.975 16.875 14 19.2333 14 22H13C11.0667 22 9.41667 22.6833 8.05 24.05C6.68333 25.4167 6 27.0667 6 29C6 30.9333 6.68333 32.5833 8.05 33.95C9.41667 35.3167 11.0667 36 13 36H18V40H13Z"
                            fill="#256ef8"
                          />
                        </g>
                      </svg>
                      <div className="text-[#256ef8]">
                        Drag and drop your files here to upload
                      </div>
                    </div>
                    <div className="text-gray-500">
                      You can upload up-to 3 files, each file should be less
                      than 1MB
                    </div>
                    <div className="text-gray-500">
                      Accepted file types (JPEG, PNG){" "}
                    </div>
                  </div>
                  <div className="bg-[#5167FF] cursor-pointer px-3 py-2 flex gap-x-2 items-center rounded-full text-white">
                    {" "}
                    Upload{" "}
                    <MdOutlineFileUpload
                      size={20}
                      className="rounded-full text-[#5167FF]  bg-white"
                    />
                  </div>
                </div>
              </div>
              <div className="text-red-500 text-sm font-semibold">
                {errors?.maxNumber && (
                  <span>You can upload only upto maximum 3 images</span>
                )}
                {errors?.acceptType && (
                  <span>Selected file type cannot be allowed</span>
                )}
                {errors?.maxFileSize && (
                  <span>Selected file size exceeds 1MB</span>
                )}
              </div>{" "}
              {/* progress bar */}
              <div className="my-2 space-y-2">
                {tempProgress?.map((pr, i) => {
                  if (pr?.progress)
                    return (
                      <div>
                        <div className="flex justify-between my-1 items-center">
                          <div className="flex gap-x-2 items-center text-sm ">
                            {imageList[i] && imageList[i]["data_url"] && (
                              <img
                                onClick={(e) => {
                                  e.preventDefault();
                                  onImageUpdate(i);
                                }}
                                src={imageList[i]["data_url"]}
                                alt=""
                                width="40"
                                height={"40"}
                                className="cursor-pointer"
                              />
                            )}
                            <span className="text-[#5167FF]">
                              {imageList[i]?.file?.name}
                            </span>
                            {imageList[i]?.file?.size && (
                              <span className="text-[#ACB0C5]">
                                {Math.round(imageList[i]?.file?.size / 1024)} kb
                              </span>
                            )}
                          </div>
                          <div className="flex gap-x-2 items-center text-sm">
                            {pr?.progress ? (
                              pr?.progress >= 0 ? (
                                <span className="text-[#5C5E6C]">
                                  {pr?.progress}%
                                </span>
                              ) : (
                                <span className="text-[#5C5E6C]">
                                  Error uploading. please select the file and
                                  try again.
                                </span>
                              )
                            ) : null}
                            {pr?.progress === 100 ? (
                              <IoCheckmarkCircleOutline
                                size={20}
                                color="#2BC043"
                              />
                            ) : pr?.progress < 0 ? (
                              <AiOutlineStop
                                color="#FF5050"
                                className="cursor-pointer"
                                size={20}
                                onClick={(e) => {
                                  e.preventDefault();
                                  onImageUpdate(i);
                                }}
                              />
                            ) : null}
                            <IoCloseCircleOutline
                              className={` ${
                                pr?.progress < 100 && pr?.progress > 0
                                  ? "cursor-not-allowed"
                                  : "cursor-pointer"
                              }`}
                              size={20}
                              onClick={(e) => {
                                e.preventDefault();
                                if (pr?.progress < 100 && pr?.progress > 0) {
                                } else {
                                  onImageRemove(i);
                                  imageRemove(i);
                                }
                              }}
                            />
                          </div>
                        </div>

                        <div class="flex items-center justify-start">
                          <div
                            class={`w-full ${
                              pr?.progress >= -1
                                ? "bg-[#F7F6FB]"
                                : "bg-[#FF5050]"
                            } `}
                          >
                            <div
                              style={{
                                width: `${
                                  pr?.progress > 0 ? pr?.progress : 0
                                }%`,
                              }}
                              class={`text-center h-2 text-xs font-semibold text-white ${
                                pr?.progress > 75
                                  ? "bg-[#2BC043]"
                                  : "bg-[#5167FF]"
                              } `}
                            ></div>
                          </div>
                        </div>
                      </div>
                    );
                })}
              </div>
            </div>
          );
        }}
      </ImageUploading>
    </>
  );
};

export default UploadImages;
