// // import React from "react";
// // import { useNavigate, useLocation } from "react-router-dom";

// // const CallToActionHeader = ({ link, text }) => {
// //   const navigate = useNavigate();
// //   const location = useLocation();

// //   const handleClick = () => {
// //     if (location.pathname === '/file-a-claim') {
// //       navigate('/lost-item-report');
// //     } else {
// //       navigate(link);
// //     }
// //     window.scrollTo({ top: 0, behavior: 'smooth' });
// //   };

// //   return (
// //     <button
// //       onClick={handleClick}
// //       className="bg-blue-700 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded uppercase text-lg transition ease-in-out delay-150 hover:scale-105 duration-300 hover:shadow-2xl"
// //     >
// //       {text}
// //     </button>
// //   );
// // };

// // export default CallToActionHeader;

// import React from "react";
// import { useNavigate, useLocation } from "react-router-dom";

// const CallToActionHeader = ({ text }) => {
//   const navigate = useNavigate();
//   const location = useLocation();

//   const handleClick = () => {
//     let link = "/file-a-claim"; // Default link
  
//     if (location.pathname === "/file-a-claim") {
//       link = "/lost-item-report";
//     } else if (location.pathname.includes("/airport/")) {
//       link = "/lost-item-report?tab=airport";
//     } else if (location.pathname.includes("/airline/")) {
//       link = "/lost-item-report?tab=airline";
//     } else if (location.pathname.includes("/auto/")) {
//       link = "/lost-item-report?tab=rentalcar";
//     } else if (location.pathname.includes("/hotel/")) {
//       link = "/lost-item-report?tab=hotel";
//     } else if (location.pathname.includes("/park/")) {
//       link = "/lost-item-report?tab=themepark";
//     } else if (location.pathname.includes("/vegas/")) {
//       link = "/lost-item-report?tab=vegas";
//     } else if (location.pathname.includes("/cruise/")) {
//       link = "/lost-item-report?tab=cruiseship";
//     }
  
//     navigate(link);
//     window.scrollTo({ top: 0, behavior: 'smooth' });
//   };

//   return (
//     <button
//       onClick={handleClick}
//       className="bg-blue-700 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded uppercase text-lg transition ease-in-out delay-150 hover:scale-105 duration-300 hover:shadow-2xl"
//     >
//       {text}
//     </button>
//   );
// };

// export default CallToActionHeader;

import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

const CallToActionHeader = ({ text }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = () => {
    let link = "/file-a-claim"; // Default link

    if (location.pathname.includes("/airport/")) {
      link = "/lost-item-report?tab=airport";
    } else if (location.pathname.includes("/airline/")) {
      link = "/lost-item-report?tab=airline";
    } else if (location.pathname.includes("/auto/")) {
      link = "/lost-item-report?tab=rentalcar";
    } else if (location.pathname.includes("/hotel/")) {
      link = "/lost-item-report?tab=hotel";
    } else if (location.pathname.includes("/park/")) {
      link = "/lost-item-report?tab=themepark";
    } else if (location.pathname.includes("/vegas/")) {
      link = "/lost-item-report?tab=vegas";
    } else if (location.pathname === "/file-a-claim") {
      link = "/lost-item-report";
    }

    navigate(link);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <button
      onClick={handleClick}
      className="bg-blue-700 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded uppercase text-lg transition ease-in-out delay-150 hover:scale-105 duration-300 hover:shadow-2xl"
    >
      {text}
    </button>
  );
};

export default CallToActionHeader;