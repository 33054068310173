export const getHeroData = (category, subCategory) => {
    const data = {
        // all airports
        airport: {
            mia: {
                heroImage: '/locations/airport.webp',
                title: 'Welcome to MIA - Miami International Airport',
                subtitle: 'Lost something while Travelling? We\'re ready to help!',
                buttons: [
                    { text: 'File a Claim', link: '/lost-item-report?tab=airport' },
                ],
            },
            cdg: {
                heroImage: '/locations/airport.webp',
                title: 'Welcome to CDG - Charles-de-Gaulle Airport',
                subtitle: 'Lost Property? Biens perdus?',
                buttons: [
                    { text: 'File a Claim', link: '/lost-item-report?tab=airport' },
                ],
            },
            mad: {
                heroImage: '/locations/airport.webp',
                title: 'Welcome to MAD - Madrid Airport',
                subtitle: 'Lost items? Artículos perdidos?',
                buttons: [
                    { text: 'File a Claim', link: '/lost-item-report?tab=airport' },
                ],
            },
            ath: {
                heroImage: '/locations/airport.webp',
                title: 'Welcome to ATH - Athens Int\'l Airport',
                subtitle: 'Lost Property? Χαμένη περιουσία?',
                buttons: [
                    { text: 'File a Claim', link: '/lost-item-report?tab=airport' },
                ],
            },
            bwi: {
                heroImage: '/locations/airport.webp',
                title: 'Welcome to BWI - Baltimore Washington Airport',
                subtitle: 'Lost something while Travelling? We\'re ready to help!',
                buttons: [
                    { text: 'File a Claim', link: '/lost-item-report?tab=airport' },
                ],
            },
            bcn: {
                heroImage: '/locations/airport.webp',
                title: 'Welcome to BCN - Barcelona Int\'l Airport',
                subtitle: 'Lost Property? Propiedad perdida?',
                buttons: [
                    { text: 'File a Claim', link: '/lost-item-report?tab=airport' },
                ],
            },
            bos: {
                heroImage: '/locations/airport.webp',
                title: 'Welcome to BOS - Boston Logan Int\'l Airport',
                subtitle: 'Lost something during during your visit? We’re ready to help!',
                buttons: [
                    { text: 'File a Claim', link: '/lost-item-report?tab=airport' },
                ],
            },
            clt: {
                heroImage: '/locations/airport.webp',
                title: 'Welcome to CLT - Charlotte Douglas Int\'l Airport',
                subtitle: 'Lost something while Travelling? We\'re ready to help!',
                buttons: [
                    { text: 'File a Claim', link: '/lost-item-report?tab=airport' },
                ],
            },
            mdw: {
                heroImage: '/locations/airport.webp',
                title: 'Welcome to MDW - Chicago Midway Int\'l Airport',
                subtitle: 'Lost something while Travelling? We\'re ready to help!',
                buttons: [
                    { text: 'File a Claim', link: '/lost-item-report?tab=airport' },
                ],
            },
            ord: {
                heroImage: '/locations/airport.webp',
                title: 'Welcome to ORD - Chicago O\'Hare Int\'l Airport',
                subtitle: 'Lost something while Travelling? We\'re ready to help!',
                buttons: [
                    { text: 'File a Claim', link: '/lost-item-report?tab=airport' },
                ],
            },
            dfw: {
                heroImage: '/locations/airport.webp',
                title: 'Welcome to DFW - Dalls-Ft Worth Int\'l Airport',
                subtitle: 'Lost something while Travelling? We\'re ready to help!',
                buttons: [
                    { text: 'File a Claim', link: '/lost-item-report?tab=airport' },
                ],
            },
            dal: {
                heroImage: '/locations/airport.webp',
                title: 'Welcome to DAL - Dallas Love Field Airport',
                subtitle: 'Lost something while Travelling? We\'re ready to help!',
                buttons: [
                    { text: 'File a Claim', link: '/lost-item-report?tab=airport' },
                ],
            },
            den: {
                heroImage: '/locations/airport.webp',
                title: 'Welcome to DEN - Denver Int\'l Airport',
                subtitle: 'Lost something while Travelling? We\'re ready to help!',
                buttons: [
                    { text: 'File a Claim', link: '/lost-item-report?tab=airport' },
                ],
            },
            dtw: {
                heroImage: '/locations/airport.webp',
                title: 'Welcome to DTW - Detroit Mertropolitan Airport',
                subtitle: 'Lost something while Travelling? We\'re ready to help!',
                buttons: [
                    { text: 'File a Claim', link: '/lost-item-report?tab=airport' },
                ],
            },
            dxb: {
                heroImage: '/locations/airport.webp',
                title: 'Welcome to DXB - Dubai Int\'l Airport',
                subtitle: 'Lost something while Travelling? We\'re ready to help!',
                buttons: [
                    { text: 'File a Claim', link: '/lost-item-report?tab=airport' },
                ],
            },
            edi: {
                heroImage: '/locations/airport.webp',
                title: 'Welcome to EDI - Edinburgh Scotland Airport',
                subtitle: 'Lost something while Travelling? We\'re ready to help!',
                buttons: [
                    { text: 'File a Claim', link: '/lost-item-report?tab=airport' },
                ],
            },
            fll: {
                heroImage: '/locations/airport.webp',
                title: 'Welcome to FLL - Ft. Lauderdale Hollywood Int\'l Airport',
                subtitle: 'Lost something while Travelling? We\'re ready to help!',
                buttons: [
                    { text: 'File a Claim', link: '/lost-item-report?tab=airport' },
                ],
            },
            lgw: {
                heroImage: '/locations/airport.webp',
                title: 'Welcome to LGW - London Gatwick Airport',
                subtitle: 'Lost something while Travelling? We\'re ready to help!',
                buttons: [
                    { text: 'File a Claim', link: '/lost-item-report?tab=airport' },
                ],
            },
            iah: {
                heroImage: '/locations/airport.webp',
                title: 'Welcome to IAH - Geroge Bush Intercontinental Airport',
                subtitle: 'Lost something while Travelling? We\'re ready to help!',
                buttons: [
                    { text: 'File a Claim', link: '/lost-item-report?tab=airport' },
                ],
            },
            las: {
                heroImage: '/locations/airport.webp',
                title: 'Welcome to LAS - Las Vegas McCarran Airport',
                subtitle: 'Lost something while Travelling? We\'re ready to help!',
                buttons: [
                    { text: 'File a Claim', link: '/lost-item-report?tab=airport' },
                ],
            },
            atl: {
                heroImage: '/locations/airport.webp',
                title: 'Welcome to ATL - Hartsfield-Jackson Atlanta Airport',
                subtitle: 'Lost something while Travelling? We\'re ready to help!',
                buttons: [
                    { text: 'File a Claim', link: '/lost-item-report?tab=airport' },
                ],
            },
            lhr: {
                heroImage: '/locations/airport.webp',
                title: 'Welcome to LHR - London Heathrow Airport',
                subtitle: 'Lost something while Travelling? We\'re ready to help!',
                buttons: [
                    { text: 'File a Claim', link: '/lost-item-report?tab=airport' },
                ],
            },
            hnl: {
                heroImage: '/locations/airport.webp',
                title: 'Welcome to HNL - Honolulu Daniel Inouye Int\'l Airport',
                subtitle: 'Lost something while Travelling? We\'re ready to help!',
                buttons: [
                    { text: 'File a Claim', link: '/lost-item-report?tab=airport' },
                ],
            },
            lis: {
                heroImage: '/locations/airport.webp',
                title: 'Welcome to LIS - Humberto Delgado Airport Lisbon',
                subtitle: 'Lost Property? Propriedade Perdida?',
                buttons: [
                    { text: 'File a Claim', link: '/lost-item-report?tab=airport' },
                ],
            },
            ist: {
                heroImage: '/locations/airport.webp',
                title: 'Welcome to IST - Istanbul Airport',
                subtitle: 'Lost Property? Kayıp Eşya?',
                buttons: [
                    { text: 'File a Claim', link: '/lost-item-report?tab=airport' },
                ],
            },
            jax: {
                heroImage: '/locations/airport.webp',
                title: 'Welcome to JAX - Jacksonville Int\'l Airport',
                subtitle: 'Lost something while Travelling? We\'re ready to help!',
                buttons: [
                    { text: 'File a Claim', link: '/lost-item-report?tab=airport' },
                ],
            },
            jfk: {
                heroImage: '/locations/airport.webp',
                title: 'Welcome to JFK - New York JFK Int\'l Airport',
                subtitle: 'Lost something while Travelling? We\'re ready to help!',
                buttons: [
                    { text: 'File a Claim', link: '/lost-item-report?tab=airport' },
                ],
            },
            sna: {
                heroImage: '/locations/airport.webp',
                title: 'Welcome to SNA - John Wayne Airport',
                subtitle: 'Lost something while Travelling? We\'re ready to help!',
                buttons: [
                    { text: 'File a Claim', link: '/lost-item-report?tab=airport' },
                ],
            },
            mci: {
                heroImage: '/locations/airport.webp',
                title: 'Welcome to MCI - Kansas City Int\'l Airport',
                subtitle: 'Lost something while Travelling? We\'re ready to help!',
                buttons: [
                    { text: 'File a Claim', link: '/lost-item-report?tab=airport' },
                ],
            },
            krk: {
                heroImage: '/locations/airport.webp',
                title: 'Welcome to KRK - Krakow John Paul II Int\'l Airport',
                subtitle: 'Lost Property? Utracona własność?',
                buttons: [
                    { text: 'File a Claim', link: '/lost-item-report?tab=airport' },
                ],
            },
            fco: {
                heroImage: '/locations/airport.webp',
                title: 'Welcome to FCO - Leonardo da vinci-Fumicino Airport',
                subtitle: 'Lost Property? Oggetti smarriti?',
                buttons: [
                    { text: 'File a Claim', link: '/lost-item-report?tab=airport' },
                ],
            },
            lax: {
                heroImage: '/locations/airport.webp',
                title: 'Welcome to LAX - Los Angeles Int\'l Airport',
                subtitle: 'Lost something while Travelling? We\'re ready to help!',
                buttons: [
                    { text: 'File a Claim', link: '/lost-item-report?tab=airport' },
                ],
            },
            sju: {
                heroImage: '/locations/airport.webp',
                title: 'Welcome to SJU - Luis Munoz Marin Airport',
                subtitle: 'Lost items? Artículos perdidos?',
                buttons: [
                    { text: 'File a Claim', link: '/lost-item-report?tab=airport' },
                ],
            },
            mex: {
                heroImage: '/locations/airport.webp',
                title: 'Welcome to MEX - Mexico City Int\'l Airport',
                subtitle: 'Lost items? Artículos perdidos?',
                buttons: [
                    { text: 'File a Claim', link: '/lost-item-report?tab=airport' },
                ],
            },
            msp: {
                heroImage: '/locations/airport.webp',
                title: 'Welcome to MSP - Minneapolis-Saint Paul Int\'l Airport',
                subtitle: 'Lost something while Travelling? We\'re ready to help!',
                buttons: [
                    { text: 'File a Claim', link: '/lost-item-report?tab=airport' },
                ],
            },
            yul: {
                heroImage: '/locations/airport.webp',
                title: 'Welcome to YUL - Montréal–Trudeau Int\'l Airport',
                subtitle: 'Lost something while Travelling? We\'re ready to help!',
                buttons: [
                    { text: 'File a Claim', link: '/lost-item-report?tab=airport' },
                ],
            },
            nrt: {
                heroImage: '/locations/airport.webp',
                title: 'Welcome to NRT - Narita Int\'l Airport',
                subtitle: '旅行中に何かを紛失しましたか？ 私たちがお手伝いいたします！',
                buttons: [
                    { text: 'File a Claim', link: '/lost-item-report?tab=airport' },
                ],
            },
            bna: {
                heroImage: '/locations/airport.webp',
                title: 'Welcome to BNA - Nashville Int\'l Airport',
                subtitle: 'Lost something while Travelling? We\'re ready to help!',
                buttons: [
                    { text: 'File a Claim', link: '/lost-item-report?tab=airport' },
                ],
            },
            ewr: {
                heroImage: '/locations/airport.webp',
                title: 'Welcome to EWR - Newark Int\'l Airport',
                subtitle: 'Lost something while Travelling? We\'re ready to help!',
                buttons: [
                    { text: 'File a Claim', link: '/lost-item-report?tab=airport' },
                ],
            },
            ams: {
                heroImage: '/locations/airport.webp',
                title: 'Welcome to AMS - Amsterdam Schiphol Airport',
                subtitle: 'Lost and Found? Tapt og funnet?',
                buttons: [
                    { text: 'File a Claim', link: '/lost-item-report?tab=airport' },
                ],
            },
            lys: {
                heroImage: '/locations/airport.webp',
                title: 'Welcome to LYS - Lyon Airport France',
                subtitle: 'Lost Property? Biens perdus?',
                buttons: [
                    { text: 'File a Claim', link: '/lost-item-report?tab=airport' },
                ],
            },
            ory: {
                heroImage: '/locations/airport.webp',
                title: 'Welcome to ORY - Paris Orly Airport',
                subtitle: 'Lost Property? Biens perdus?',
                buttons: [
                    { text: 'File a Claim', link: '/lost-item-report?tab=airport' },
                ],
            },
            nce: {
                heroImage: '/locations/airport.webp',
                title: 'Welcome to NCE - Nice Côte d\'Azur',
                subtitle: 'Lost Property? Biens perdus?',
                buttons: [
                    { text: 'File a Claim', link: '/lost-item-report?tab=airport' },
                ],
            },
            // More airports...
        },
        air: {
            // All airlines
            ua: {
                heroImage: '/locations/airline.webp',
                title: 'United Airlines - Lost and Found Service',
                subtitle: 'Lost something while at the Gate, Lounge or on-board?!',
                buttons: [{ text: 'File a Claim', link: '/lost-item-report?tab=airline' }],
            },
            aa: {
                heroImage: '/locations/airline.webp',
                title: 'American Airlines - Lost and Found Service',
                subtitle: 'Lost something while at the Gate, Lounge or on-board?!',
                buttons: [{ text: 'File a Claim', link: '/lost-item-report?tab=airline' }],
            },
            as: {
                heroImage: '/locations/airline.webp',
                title: 'Alaska Airlines - Lost and Found Service',
                subtitle: 'Lost something while at the Gate, Lounge or on-board?!',
                buttons: [{ text: 'File a Claim', link: '/lost-item-report?tab=airline' }],
            },
            dl: {
                heroImage: '/locations/airline.webp',
                title: 'Delta Airlines - Lost and Found Service',
                subtitle: 'Lost something while at the Gate, Lounge or on-board?!',
                buttons: [{ text: 'File a Claim', link: '/lost-item-report?tab=airline' }],
            },
            ac: {
                heroImage: '/locations/airline.webp',
                title: 'Air Canada - Lost and Found Service',
                subtitle: 'Lost something while at the Gate, Lounge or on-board?!',
                buttons: [{ text: 'File a Claim', link: '/lost-item-report?tab=airline' }],
            },
            nk: {
                heroImage: '/locations/airline.webp',
                title: 'Spirit Airlines - Lost and Found Service',
                subtitle: 'Lost something while at the Gate, Lounge or on-board?!',
                buttons: [{ text: 'File a Claim', link: '/lost-item-report?tab=airline' }],
            },
            b6: {
                heroImage: '/locations/airline.webp',
                title: 'jetBlue - Lost and Found Service',
                subtitle: 'Lost something while at the Gate, Lounge or on-board?!',
                buttons: [{ text: 'File a Claim', link: '/lost-item-report?tab=airline' }],
            },
            af: {
                heroImage: '/locations/airline.webp',
                title: 'Air France - Lost and Found Service',
                subtitle: 'Lost something while at the Gate, Lounge or on-board?!',
                buttons: [{ text: 'File a Claim', link: '/lost-item-report?tab=airline' }],
            },
            kl: {
                heroImage: '/locations/airline.webp',
                title: 'KLM - Lost and Found Service',
                subtitle: 'Lost something while at the Gate, Lounge or on-board?!',
                buttons: [{ text: 'File a Claim', link: '/lost-item-report?tab=airline' }],
            },
            lh: {
                heroImage: '/locations/airline.webp',
                title: 'Lufthansa - Lost and Found Service',
                subtitle: 'Lost something while at the Gate, Lounge or on-board?!',
                buttons: [{ text: 'File a Claim', link: '/lost-item-report?tab=airline' }],
            },
            az: {
                heroImage: '/locations/airline.webp',
                title: 'ITA Airways - Lost and Found Service',
                subtitle: 'Lost something while at the Gate, Lounge or on-board?!',
                buttons: [{ text: 'File a Claim', link: '/lost-item-report?tab=airline' }],
            },
            // More airports...
        },
        // All vegas
        vegas: {
            venetian: {
                heroImage: '/locations/vegas.webp',
                title: 'Welcome to The Palazzo at The Venetian Resort',
                subtitle: 'Lost and Found at the Palazzo at The Venetian Resort',
                buttons: [{ text: 'File a Claim', link: '/lost-item-report?tab=vegas' }],
            },
            bellagio: {
                heroImage: '/locations/vegas.webp',
                title: 'Welcome to The Bellgio Las Vegas',
                subtitle: 'Lost and Found at the Bellgio Las Vegas',
                buttons: [{ text: 'File a Claim', link: '/lost-item-report?tab=vegas' }],
            },
            aria: {
                heroImage: '/locations/vegas.webp',
                title: 'Welcome to The Aria Resort & Casino',
                subtitle: 'Lost and Found at the Aria Resort & Casino',
                buttons: [{ text: 'File a Claim', link: '/lost-item-report?tab=vegas' }],
            },
            vdara: {
                heroImage: '/locations/vegas.webp',
                title: 'Welcome to The Vdara Hotel & Spa',
                subtitle: 'Lost and Found at the Vdara Hotel & Spa',
                buttons: [{ text: 'File a Claim', link: '/lost-item-report?tab=vegas' }],
            },
            cosmo: {
                heroImage: '/locations/vegas.webp',
                title: 'Welcome to The Cosmopolitamn of Las Vegas',
                subtitle: 'Lost and Found at the The Cosmopolitamn of Las Vegas',
                buttons: [{ text: 'File a Claim', link: '/lost-item-report?tab=vegas' }],
            },
            mgm: {
                heroImage: '/locations/vegas.webp',
                title: 'Welcome to The MGM Grand Las Vegas',
                subtitle: 'Lost and Found at the MGM Grand Las Vegas',
                buttons: [{ text: 'File a Claim', link: '/lost-item-report?tab=vegas' }],
            },
            bay: {
                heroImage: '/locations/vegas.webp',
                title: 'Welcome to The Mandalay Bay',
                subtitle: 'Lost and Found at the Mandalay Bay',
                buttons: [{ text: 'File a Claim', link: '/lost-item-report?tab=vegas' }],
            },
            delano: {
                heroImage: '/locations/vegas.webp',
                title: 'Welcome to The Delano Las Vegas',
                subtitle: 'Lost and Found at the Delano Las Vegfas',
                buttons: [{ text: 'File a Claim', link: '/lost-item-report?tab=vegas' }],
            },
            park: {
                heroImage: '/locations/vegas.webp',
                title: 'Welcome to Park MGM Las Vegas',
                subtitle: 'Lost and Found at the Park MGM Las Vegas',
                buttons: [{ text: 'File a Claim', link: '/lost-item-report?tab=vegas' }],
            },
            nyny: {
                heroImage: '/locations/vegas.webp',
                title: 'Welcome to The New York-New York Hotel & Casino',
                subtitle: 'Lost and Found at the New York-New York Hotel & Casino',
                buttons: [{ text: 'File a Claim', link: '/lost-item-report?tab=vegas' }],
            },
            luxor: {
                heroImage: '/locations/vegas.webp',
                title: 'Welcome to The Luxor Hotel & Casino',
                subtitle: 'Lost and Found at the Luxor Hotel & Casino',
                buttons: [{ text: 'File a Claim', link: '/lost-item-report?tab=vegas' }],
            },
            nomad: {
                heroImage: '/locations/vegas.webp',
                title: 'Welcome The NoMad Hotel Las Vegas',
                subtitle: 'Lost and Found at the The NoMad Hotel Las Vegas',
                buttons: [{ text: 'File a Claim', link: '/lost-item-report?tab=vegas' }],
            },
            xcal: {
                heroImage: '/locations/vegas.webp',
                title: 'Welcome The Excalibur Hotel & Casino',
                subtitle: 'Lost and Found at the Excalibur Hotel & Casino',
                buttons: [{ text: 'File a Claim', link: '/lost-item-report?tab=vegas' }],
            },
            // More vegas...
        },
        // All ships
        ship: {
            carnival: {
                heroImage: '/locations/cruise.webp',
                title: 'Welcome to Carnival Cruise Line',
                subtitle: 'Lost something on your cruise? We can help',
                buttons: [{ text: 'File a Claim', link: '/lost-item-report?tab=cruiseship' }],
            },
            royal: {
                heroImage: '/locations/cruise.webp',
                title: 'Welcome to Royal Carribbean',
                subtitle: 'Lost something on your cruise? We can help',
                buttons: [{ text: 'File a Claim', link: '/lost-item-report?tab=cruiseship' }],
            },
            // More ships...
        },
        // All autos
        auto: {
            alamo: {
                heroImage: '/locations/rentacar.webp',
                title: 'Welcome to Alamo Car Rental',
                subtitle: 'Left something behind during your rental? We’re ready to help!',
                buttons: [{ text: 'File a Claim', link: '/lost-item-report?tab=rentalcar' }],
            },
            avis: {
                heroImage: '/locations/rentacar.webp',
                title: 'Welcome to Avis Car Rental',
                subtitle: 'Left something behind during your rental? We’re ready to help!',
                buttons: [{ text: 'File a Claim', link: '/lost-item-report?tab=rentalcar' }],
            },
            budget: {
                heroImage: '/locations/rentacar.webp',
                title: 'Welcome to Budget Car Rental',
                subtitle: 'Left something behind during your rental? We’re ready to help!',
                buttons: [{ text: 'File a Claim', link: '/lost-item-report?tab=rentalcar' }],
            },
            dollar: {
                heroImage: '/locations/rentacar.webp',
                title: 'Welcome to Dollar Car Rental',
                subtitle: 'Left something behind during your rental? We’re ready to help!',
                buttons: [{ text: 'File a Claim', link: '/lost-item-report?tab=rentalcar' }],
            },
            enterprise: {
                heroImage: '/locations/rentacar.webp',
                title: 'Welcome to Enterprise Car Rental',
                subtitle: 'Left something behind during your rental? We’re ready to help!',
                buttons: [{ text: 'File a Claim', link: '/lost-item-report?tab=rentalcar' }],
            },
            hertz: {
                heroImage: '/locations/rentacar.webp',
                title: 'Welcome to Hertz Car Rental',
                subtitle: 'Left something behind during your rental? We’re ready to help!',
                buttons: [{ text: 'File a Claim', link: '/lost-item-report?tab=rentalcar' }],
            },
            national: {
                heroImage: '/locations/rentacar.webp',
                title: 'Welcome to National Car Rental',
                subtitle: 'Left something behind during your rental? We’re ready to help!',
                buttons: [{ text: 'File a Claim', link: '/lost-item-report?tab=rentalcar' }],
            },
            payless: {
                heroImage: '/locations/rentacar.webp',
                title: 'Welcome to Payless Car Rental',
                subtitle: 'Left something behind during your rental? We’re ready to help!',
                buttons: [{ text: 'File a Claim', link: '/lost-item-report?tab=rentalcar' }],
            },
            sixt: {
                heroImage: '/locations/rentacar.webp',
                title: 'Welcome to Sixt Car Rental',
                subtitle: 'Left something behind during your rental? We’re ready to help!',
                buttons: [{ text: 'File a Claim', link: '/lost-item-report?tab=rentalcar' }],
            },
            thrifty: {
                heroImage: '/locations/rentacar.webp',
                title: 'Welcome to Thrifty Car Rental',
                subtitle: 'Left something behind during your rental? We’re ready to help!',
                buttons: [{ text: 'File a Claim', link: '/lost-item-report?tab=rentalcar' }],
            },
            // More autos...
        },
        // All parks
        park: {
            disneyworld: {
                heroImage: '/locations/park.webp',
                title: 'Welcome to Disney World Parks & Resorts Orlando, FL',
                subtitle: 'Left something behind your visit? We’re ready to help!',
                buttons: [{ text: 'File a Claim', link: '/lost-item-report?tab=themepark' }],
            },
            disneyland: {
                heroImage: '/locations/park.webp',
                title: 'Welcome to Disnely Land Parks & Resorts Anaheim, CA',
                subtitle: 'Left something behind your visit? We’re ready to help!',
                buttons: [{ text: 'File a Claim', link: '/lost-item-report?tab=themepark' }],
            },
            universal_orlando: {
                heroImage: '/locations/park.webp',
                title: 'Welcome to Universal Orlando Parks & Resorts Orlando, FL',
                subtitle: 'Left something behind your visit? We’re ready to help!',
                buttons: [{ text: 'File a Claim', link: '/lost-item-report?tab=themepark' }],
            },
            universal_hollywood: {
                heroImage: '/locations/park.webp',
                title: 'Welcome to Universal Studios Hollywood, CA',
                subtitle: 'Left something behind your visit? We’re ready to help!',
                buttons: [{ text: 'File a Claim', link: '/lost-item-report?tab=themepark' }],
            },
            seaworld_orlando: {
                heroImage: '/locations/park.webp',
                title: 'Welcome to SeaWorld Orlando, FL',
                subtitle: 'Left something behind your visit? We’re ready to help!',
                buttons: [{ text: 'File a Claim', link: '/lost-item-report?tab=themepark' }],
            },
            seaworld_californa: {
                heroImage: '/locations/park.webp',
                title: 'Welcome to SeaWorld San Diego, CA',
                subtitle: 'Left something behind your visit? We’re ready to help!',
                buttons: [{ text: 'File a Claim', link: '/lost-item-report?tab=themepark' }],
            },
            seaworld_sanantonio: {
                heroImage: '/locations/park.webp',
                title: 'Welcome to SeaWorld San Antonio, TX',
                subtitle: 'Left something behind your visit? We’re ready to help!',
                buttons: [{ text: 'File a Claim', link: '/lost-item-report?tab=themepark' }],
            },
            // More parks...
        },
        default: {
            heroImage: '/hero-img.webp',
            title: 'Lost Something on your Trip?',
            subtitle: 'We will do everything we can to find your item and make the process as easy as possible.',
            buttons: [{ text: 'File a Claim', link: '/file-a-claim' }],
        },
    };

    return (data[category]?.[subCategory]) || data.default;
};