import React, { useState, useEffect } from 'react';
import { getHowItWorksData } from '../utility';

const HowItWorks = () => {
  const [howItWorksContent, setHowItWorksContent] = useState({ title: '', columns: [] });

  useEffect(() => {
    const data = getHowItWorksData();
    if (data) {
      setHowItWorksContent(data);
    }
  }, []);

  return (
    <div className="text-center pt-16 pb-8 w-11/12 mx-auto">
      <h2 className="text-3xl font-bold text-blue-700 mb-8 uppercase">{howItWorksContent.title}</h2>
      <div className="flex flex-wrap justify-between w-full mx-auto">
        {howItWorksContent.columns &&
          howItWorksContent.columns.map((column, index) => (
            <div key={index} className="w-full md:w-1/3 p-4 text-center">
              <h3 className="text-2xl font-bold mb-4 text-blue-700">{column.title}</h3>
              <p className='font-semibold text-slate-700'>{column.content}</p>
            </div>
          ))}
      </div>
    </div>
  );
};

export default HowItWorks;