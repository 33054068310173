import {
  Client,
  cacheExchange,
  fetchExchange,
  createClient,
  Provider,
} from "urql";
import React, { createContext } from "react";
import { hasura_endpoint } from "../config";

export const DBContext = createContext();
export const DBProvider = ({ children }) => {
  const client = new Client({
    url: `https://${hasura_endpoint}`,
    exchanges: [cacheExchange, fetchExchange],
  });
  return (
    <DBContext.Provider value={client}>
      {" "}
      <Provider value={client}>{children}</Provider>
    </DBContext.Provider>
  );
};
