import { gql } from "urql";

export const ListAirportsQuery = gql`
  query ListAirportsQuery {
    airports(order_by: { name: asc }) {
      id
      name
      airport_code
    }
  }
`;
export const ListAirlinesQuery = gql`
  query ListAirlinesQuery {
    airlines(order_by: { name: asc }) {
      id
      name
      iata_code
    }
  }
`;
export const FileReportMutation = gql`
  mutation FileReportMutation($objects: reports_insert_input = {}) {
    insert_reports_one(object: $objects) {
      id
    }
  }
`;
export const ListCategoriesQuery = gql`
  query ListCategoriesQuery {
    categories(order_by: { name: asc, order: asc }) {
      id
      name
      schema
    }
  }
`;
export const GetCategoryByPKQuery = gql`
  query CategoryByPKQuery($id: uuid = "") {
    categories_by_pk(id: $id) {
      id
      name
      schema
    }
  }
`;
export const ListCarRentalQuery = gql`
  query ListCarRentalsQuery {
    car_rental(order_by: { name: asc }) {
      id
      name
    }
  }
`;
export const ListThemeParkQuery = gql`
  query ListThemeParksQuery {
    theme_park(order_by: { name: asc }) {
      id
      name
    }
  }
`;
export const ListThemeParkStateQuery = gql`
  query ListThemeParkStateQuery {
    theme_park(distinct_on: state) {
      id
      state
    }
  }
`;
export const GetConfigQuery = gql`
  query ConfigQuery {
    config_by_pk(id: "9216a6d0-9fad-4184-8e09-f948538fad98") {
      price
      paypal_client_id
      auth_client_id
      auth_login_id
      pay_method
      payment_reminder
    }
  }
`;

// export const CheckStatusQuery = gql`
//   query CheckStatus($email: String = "", $claim_id: String = "") {
//     reports(where: { email: { _eq: $email }, claim_id: { _eq: $claim_id } }) {
//       status
//       close_reason
//       first_name
//     }
//   }
// `;

export const CheckStatusQuery = gql`
  query CheckStatus($email: String!, $claim_id: String!) {
    reports(where: { email: { _eq: $email }, claim_id: { _eq: $claim_id } }) {
      status
      claim_id
      email
      email_logs {
        body
        subject
        created_at
      }
    }
  }
`;

export const GetReportDataQuery = gql`
  query ConfigQuery($id: uuid = "36d6d871-00e3-47d4-a257-1e9ce40c683f") {
    config_by_pk(id: "9216a6d0-9fad-4184-8e09-f948538fad98") {
      price
      paypal_client_id
      auth_client_id
      auth_login_id
      pay_method
      payment_reminder
    }
    reports_by_pk(id: $id) {
      id
      claim_id
      phone_number
      zip
      first_name
      last_name
      email
      status
      address
      city
      country
    }
  }
`;
export const AddReviewMutation = gql`
  mutation AddReview($review: String = "", $id: uuid = "") {
    update_reports_by_pk(pk_columns: { id: $id }, _set: { review: $review }) {
      id
    }
  }
`;

export const CheckReport = gql`
  query CheckId($id: uuid = "") {
    reports_by_pk(id: $id) {
      id
    }
  }
`;

export const GetReviewsQuery = gql`
  query GetReviews {
    reviews(order_by: { date: desc }) {
      date
      id
      rate
      review
      show
      title
      user_name
    }
    reviews_aggregate {
      aggregate {
        count
      }
    }
  }
`;
