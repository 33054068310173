import React from 'react';
import { Link } from 'react-router-dom';

const AppStore = () => {
  const appStoreLink = 'https://apps.apple.com/app/lf-concierge/id6736951457'; // Replace with actual link to the app store

  return (
    <table border="0" cellPadding="10" cellSpacing="0" align="center">
      <tbody>
        <tr>
          <td align="center"></td>
        </tr>
        <tr>
          <td align="center">
            <Link target='_blank' to={appStoreLink} title="Download on the App Store">
              <img 
                src="../download-on-the-app-store.svg" // Replace with actual App Store image
                border="0" 
                alt="Download on the App Store" 
                width={160}
              />
            </Link>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default AppStore;