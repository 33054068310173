import React, { useEffect, useState } from "react";
import Typography from "../typography";
import LocationAirport from "./location_airport";
import SelectBox from "../select";
import InputBox from "../input";
import Button from "../button";
import LocationRentalCar from "./location_rental_car";
import LocationHotel from "./location_hotel";
import LocationLasVegas from "./location_las_vegas";
import LocationPark from "./location_park";
import LocationShip from "./location_ship";
import { ValidateLocation } from "../../utils/validation";
import { useToasts } from "react-toast-notifications";
import InputDate from "../input_date";
import ErrorMessage from "../error_message";
const Location = ({
  selectedTab,
  setSelectedStepper,
  stepperItems,
  locationDetails,
  handleSetReport,
  lostDate,
  loading,
  lostTime,
  errors,
  setErrors,
  activeStep,
  setActiveStep,
}) => {
  const { addToast } = useToasts();
  const [vaild, setValid] = useState(false);
  const handleReport = async () => {
    const validate = await ValidateLocation(
      selectedTab?.title,
      locationDetails,
      lostTime,
      lostDate,
      true
    );

    if (validate?.error) {
      if (Object?.keys(errors)?.length > 0) {
        setErrors(validate?.error);
      }
      if (vaild) {
        setValid(false);
        setActiveStep({
          0: { valid: false, navigate: true },
          1: { navigate: false },
        });
      }
    } else {
      setErrors({});
      if (!vaild) {
        setValid(true);
        setActiveStep({
          ...activeStep,
          0: { valid: true, navigate: true },
          1: { ...activeStep[1], navigate: true },
        });
      }
    }
  };
  useEffect(() => {
    handleReport();
  }, [locationDetails, lostDate, lostTime]);

  return (
    <div className="text-start mt-16">
      <Typography title={"Where did you lose your item?"} />
      {selectedTab?.title === "Airport / Airline" && (
        <LocationAirport
          handleSetReport={handleSetReport}
          locationDetails={locationDetails}
          errors={errors}
        />
      )}
      {selectedTab?.title === "Rental Car" && (
        <LocationRentalCar
          handleSetReport={handleSetReport}
          locationDetails={locationDetails}
          errors={errors}
        />
      )}
      {selectedTab?.title === "Hotel / AirBnB" && (
        <LocationHotel
          handleSetReport={handleSetReport}
          locationDetails={locationDetails}
          errors={errors}
        />
      )}
      {selectedTab?.title === "Las Vegas" && (
        <LocationLasVegas
          handleSetReport={handleSetReport}
          locationDetails={locationDetails}
          errors={errors}
        />
      )}
      {selectedTab?.title === "Theme Park" && (
        <LocationPark
          handleSetReport={handleSetReport}
          locationDetails={locationDetails}
          errors={errors}
        />
      )}
      {selectedTab?.title === "Cruise Ship" && (
        <LocationShip
          handleSetReport={handleSetReport}
          locationDetails={locationDetails}
          errors={errors}
        />
      )}
      <div className={`mt-6 grid grid-cols-1 md:grid-cols-2`}>
        <div className="md:mr-4">
          {" "}
          <Typography title={"Item lost date?"} />
          <InputDate
            value={lostDate ?? new Date().toUTCString()}
            onChange={(e) => {
              handleSetReport({ lost_date: e });
            }}
            className={"w-full"}
            placeholder={"Pick the lost date *"}
            maxDate={new Date()}
          />
          <ErrorMessage
            condition={errors?.lostDate}
            message={errors?.lostDate}
          />
        </div>
        <div className="md:mt-8 mt-0">
          <SelectBox
            values={
              lostTime?.trim() === "" || !lostTime ? [] : [{ name: lostTime }]
            }
            valueField={"id"}
            onChange={(e) => {
              // if (e[0]?.name !== "other") {
              //   handleSetReport({ lost_time_description: "" });
              // }
              handleSetReport({ lost_time_of_the_day: e[0]?.name });
            }}
            options={
              // [
              // { id: "other", name: "other" },
              // { id: "morning", name: "Morning" },
              // { id: "evening", name: "Evening" },
              // { id: "night", name: "Night" },
              //  ]
              [
                { id: "01:00 AM", name: "01:00 AM" },
                { id: "02:00 AM", name: "02:00 AM" },
                { id: "03:00 AM", name: "03:00 AM" },
                { id: "04:00 AM", name: "04:00 AM" },
                { id: "05:00 AM", name: "05:00 AM" },
                { id: "06:00 AM", name: "06:00 AM" },
                { id: "07:00 AM", name: "07:00 AM" },
                { id: "08:00 AM", name: "08:00 AM" },
                { id: "09:00 AM", name: "09:00 AM" },
                { id: "10:00 AM", name: "10:00 AM" },
                { id: "11:00 AM", name: "11:00 AM" },
                { id: "12:00 PM", name: "12:00 PM" },
                { id: "01:00 PM", name: "01:00 PM" },
                { id: "02:00 PM", name: "02:00 PM" },
                { id: "03:00 PM", name: "03:00 PM" },
                { id: "04:00 PM", name: "04:00 PM" },
                { id: "05:00 PM", name: "05:00 PM" },
                { id: "06:00 PM", name: "06:00 PM" },
                { id: "07:00 PM", name: "07:00 PM" },
                { id: "08:00 PM", name: "08:00 PM" },
                { id: "09:00 PM", name: "09:00 PM" },
                { id: "10:00 PM", name: "10:00 PM" },
                { id: "11:00 PM", name: "11:00 PM" },
                { id: "12:00 AM", name: "12:00 AM" },
              ]
            }
            classnames={" w-full"}
            labelValue={"Time (approximately) *"}
            labelField={"name"}
          />{" "}
          <ErrorMessage
            condition={errors?.lostTime}
            message={errors?.lostTime}
          />
        </div>
        {/* {lostTime === "other" ? (
          <div className="grid md:col-span-2">
            {" "}
            <TextArea
              cols={6}
              value={lostTimeDescription}
              onChange={(e) => {
                handleSetReport({ lost_time_description: e.target.value });
              }}
              className={"w-full p-2"}
              placeholder={"Mention exact time"}
            />
          </div>
        ) : null} */}
      </div>
      <div className="flex justify-end my-4 py-4">
        {" "}
        <Button
          disabled={loading || !vaild}
          value={"Next"}
          onClick={async (e) => {
            e.preventDefault();
            const validate = await ValidateLocation(
              selectedTab?.title,
              locationDetails,
              lostTime,
              lostDate
            );
            if (validate?.error) {
              console.log(validate?.error);
              setErrors(validate?.error);
              // addToast(validate?.error, {
              //   appearance: "warning",
              //   autoDismiss: true,
              // });
            } else {
              setSelectedStepper(stepperItems[1]);
              setErrors({});
            }
          }}
        />
      </div>
    </div>
  );
};
export default Location;
