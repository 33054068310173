import React, { useState, createRef } from "react";

const InputBox = ({
  value,
  placeholder,
  onChange,
  className,
  type,
  label,
  ...rest
}) => {
  const ref = createRef();
  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    // Define a regular expression pattern that allows letters and specific special characters
    const pattern = /^[a-zA-Z0-9 ,.+@-_]*$/;
    // Check if the input value matches the pattern
    if (pattern.test(inputValue)) {
      onChange(e);
    }
  };
  return (
    <div>
      {placeholder && (
        <label
          className="text-xs text-slate-500 font-semibold"
          htmlFor={placeholder}
        >
          {placeholder}
        </label>
      )}
      <input
        ref={ref}
        type={type || "text"}
        className={`${className} disabled:cursor-default disabled:opacity-30  appearance-none font-semibold cursor-text bg-slate-50 rounded-lg px-2 my-1 border-2 border-slate-200 text-slate-700 text-sm h-12`}
        value={value || ""}
        onChange={handleInputChange}
        placeholder={placeholder}
        {...rest}
      />
    </div>
  );
};
export default InputBox;
