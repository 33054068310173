import { lambda_end_point } from "../config";
import fetchProgress from "fetch-progress";

export const uploadAssets = async (
  file,
  type,
  setProgress,
  progressState,
  i
) => {
  try {
    // Step 1: Get the signed URL
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    const body = JSON.stringify({
      type: `${type}`,
      name: `${file?.name}`,
      mimeType: `${file.type}`,
    });

    const getSignedUrl = await fetch(
      `${lambda_end_point}/file/get-signed-url`,
      {
        method: "POST",
        headers,
        body,
        redirect: "follow",
      }
    )
      .then(
        fetchProgress({
          onProgress(progress) {
            // Progress tracking for the first fetch call (get signed URL)
            console.log(
              "Get Signed URL Progress:",
              progress,
              progress?.transferred
            );
            if (setProgress) {
              const pr = progressState;
              pr[i] = {
                ...progressState[i],
                progress: Math.round(progress?.percentage / 2),
              };
              setProgress([...pr], i);
            }
          },
        })
      )
      .then((response) => response.text())
      .then((result) => {
        const res = JSON.parse(result);
        return { data: res?.data };
      })
      .catch((error) => {
        console.log("error", error);
        throw "GET_SIGNED_URL_ERROR";
      });

    console.log(
      "get signed url",
      getSignedUrl?.data?.upload_url,
      getSignedUrl?.data?.access_link
    );

    // Step 2: Upload the file using the signed URL
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", file.type);

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: file,
      redirect: "follow",
    };

    // Use XMLHttpRequest to track progress for the file upload
    const xhr = new XMLHttpRequest();
    xhr.upload.addEventListener("progress", (event) => {
      if (event.lengthComputable) {
        const progress = (event.loaded / event.total) * 100;
        console.log("File Upload Progress:", progress, progressState);

        if (setProgress) {
          const pr = progressState || [];
          pr[i] = {
            ...progressState[i],
            progress: Math.round(progress / 2) + 50,
          };
          setProgress([...pr], i);
        }
      }
    });

    // Send the PUT request
    const upload = await fetch(getSignedUrl?.data?.upload_url, requestOptions);
    xhr.open("PUT", getSignedUrl?.data?.upload_url, true);
    xhr.send(file);

    if (upload.ok) {
      const icon = getSignedUrl?.data?.access_link;
      console.log(icon);
      return { url: icon };
    } else {
      console.log("error", upload.statusText);
      throw "UPLOAD_ERROR";
    }
  } catch (e) {
    console.log(e);
    return {
      error: e,
    };
  }
};
