import React, { useEffect } from "react";
import { useQuery } from "urql";
import { useSearchParams } from "react-router-dom";
import { GetReportDataQuery } from "../queries";
import { FaCheck, FaXmark } from "react-icons/fa6";
import { getPricingData } from './utility';
import Typography from "./typography";
import CallToAction from "./cta";



const PricingTables = () => {
  const [param] = useSearchParams();
  const pricingData = getPricingData();
  const [configResults, reexecuteConfigQuery] = useQuery({
    query: GetReportDataQuery,
    pause: true,
  });
  
  const {
    data: configsData,
    fetching: configsFetching,
    error: configsError,
  } = configResults;

  useEffect(() => {
    reexecuteConfigQuery({ requestPolicy: "network-only" });
  }, []);
  return (
    <div id='pricing' className="text-center bg-blue-900 pt-16 pb-16 md:pb-32 w-full  md:w-100">
      <h2 className="text-3xl font-bold text-white mb-16 uppercase">{pricingData.title}</h2>
    <div className="wrapper block md:grid w-screen place-content-center font-semibold px-8">
  {/* Pricing table */}
  <div className="pricing-table w-full rounded-md text-slate-900 justify-between hidden md:flex px-4">
     {/* Pricing table key */}
     <div className="pricing-column pricing-column-1 flex flex-col gap-y-5 rounded-md w-2/4 text-left mt-28">
        <span className="text-white font-bold">{pricingData.features}</span>
      {/* Account features */}
      <div className="account-features text-slate-900 bg-white p-6 rounded-xl -mr-4 -mt-2">
        <div className="feature border-b-2 border-slate-100">
          <p className="text-sm">
          {pricingData.featuresItems[0].name}
          </p>
        </div>
        <div className="feature border-b-2 border-slate-100">
          <p className="text-sm ">{pricingData.featuresItems[1].name}</p>
        </div>
        <div className="feature border-b-2 border-slate-100">
          <p className="text-sm ">
          {pricingData.featuresItems[2].name}
          </p>
        </div>
        <div className="feature border-b-2 border-slate-100">
          <p className="text-sm ">
          {pricingData.featuresItems[3].name}
          </p>
        </div>
        <div className="feature border-b-2 border-slate-100">
          <p className="text-sm ">
          {pricingData.featuresItems[4].name}
          </p>
        </div>
        <div className="feature border-b-2 border-slate-100">
          <p className="text-sm ">
          {pricingData.featuresItems[5].name}
          </p>
        </div>
        <div className="feature">
          <p className="text-sm ">{pricingData.featuresItems[6].name}</p>
        </div>
      </div>
    </div>
    {/* Pricing column 1 */}
    {/* <div className="pricing-column pricing-column-1 flex flex-col gap-y-5 p-4 rounded-xl -mr-4 bg-white border-4 border-slate-500 w-1/5 hover:shadow-2xl shadow-slate-500/100">
      <div className="upper-part flex flex-col gap-y-4">
        <div className="flex items-center justify-between mx-auto">
         
          <p className="rounded-md bg-slate-500 px-2 py-1 text-xs text-white">
          {pricingData.plans[0].plan}
          </p>
        </div>
        <div className="tier-and-description">
          <h2 className="text-2xl font-extrabold">{pricingData.plans[0].price}</h2>
          <p className="text-sm text-slate-700">{pricingData.plans[0].desc}<br/>&nbsp;</p>
        </div>
      </div>
      <div className="account-features text-slate-900 mx-auto -mt-2">
        <div className="feature">
        <FaCheck className="text-green-600"/>
        </div>
        <div className="feature">
        <FaXmark className="text-red-600" />
        </div>
        <div className="feature">
        <FaXmark className="text-red-600" />
        </div>
        <div className="feature">
        <FaXmark className="text-red-600" />
        </div>
        <div className="feature">
        <FaXmark className="text-red-600" />
        </div>
        <div className="feature">
        <FaXmark className="text-red-600" />
        </div>
        <div className="feature">
        <FaXmark className="text-red-600" />
        </div>
      </div>
      <CallToAction link="/file-a-claim" text={pricingData.plans[0].cta} />
    </div> */}
    {/* Pricing column 2 */}
    <div className="pricing-column pricing-column-2 rounded-xl flex flex-col gap-y-5 p-4 z-10 -mt-2 -mb-2 bg-white border-4 border-amber-500  w-1/4 hover:shadow-2xl shadow-amber-500/100">
      <div className="upper-part flex flex-col gap-y-4">
        <div className="flex items-center justify-between">
          
          <p className="rounded-md bg-amber-500 px-2 py-1 text-xs text-white mx-auto">
          {pricingData.plans[1].plan}
          </p>
        </div>
        <div className="tier-and-description">
          <h2 className="text-2xl font-extrabold">${configsData?.config_by_pk?.price || '35'}</h2>
          <p className="text-sm">{pricingData.plans[1].desc}</p>
        </div>
      </div>
      {/* Account features */}
      <div className="account-features mx-auto">
        <div className="feature">
        <FaCheck className="text-green-600"/>
        </div>
        <div className="feature">
        <FaCheck className="text-green-600"/>
        </div>
        <div className="feature">
        <FaCheck className="text-green-600"/>
        </div>
        <div className="feature">
        <FaCheck className="text-green-600"/>
        </div>
        <div className="feature">
        <FaCheck className="text-green-600"/>
        </div>
        <div className="feature">
        <FaCheck className="text-green-600"/>
        </div>
        <div className="feature">
        <FaCheck className="text-green-600"/>
        </div>
      </div>
      {/* call to action button */}
     {/* call to action button */}
     <CallToAction link="/lost-item-report" text={pricingData.plans[1].cta} />
    </div>
    {/* Pricing column 3 */}
    <div className="pricing-column pricing-column-3 rounded-xl flex flex-col gap-y-5 p-4 opacity-50 prose z-0 bg-white border-4 border-violet-500 -ml-4  w-1/4">
      <div className="upper-part flex flex-col gap-y-4">
        <div className="flex items-center justify-between mx-auto">
          
          <p className="rounded-md bg-violet-500 px-2 py-1 text-xs text-white">
          {pricingData.plans[2].plan}
          </p>
        </div>
        <div className="tier-and-description">
          <h2 className="text-2xl font-extrabold">
          ${(configsData?.config_by_pk?.price*6) - pricingData.plans[2].discount}&nbsp;
          <small className="text-xs">({pricingData.plans[2].savings} ${pricingData.plans[2].discount})</small>
          </h2>
          <p className="text-sm">{pricingData.plans[2].desc}</p>
        </div>
      </div>
      {/* Account features */}
      <div className="account-features mx-auto -mt-2">
        <div className="feature">
        <FaCheck className="text-green-600"/>
        </div>
        <div className="feature">
        <FaCheck className="text-green-600"/>
        </div>
        <div className="feature">
        <FaCheck className="text-green-600"/>
        </div>
        <div className="feature">
        <FaCheck className="text-green-600"/>
        </div>
        <div className="feature">
        <FaCheck className="text-green-600"/>
        </div>
        <div className="feature">
        <FaCheck className="text-green-600"/>
        </div>
        <div className="feature">
        <FaCheck className="text-green-600"/>
        </div>
      </div>
      {/* call to action button */}
      <CallToAction link="" text={pricingData.plans[2].cta} disabled />
    </div>
  </div>
  {/* Mobile */}
  <div className="pricing-table w-full rounded-md text-slate-900 justify-between block md:hidden">
     {/* Pricing 1 */}
     {/* <div className="pricing-column pricing-column-1 gap-y-5 rounded-md w-full text-left bg-white p-6 rounded-xl mb-8"> */}
      {/* Account features */}
      {/* <div className="upper-part flex flex-col mb-8">
        <div className="flex items-top justify-between">
          <div className="tier-and-description w-1/2x">
          <h2 className="text-2xl font-extrabold">
          {pricingData.plans[0].price}
          </h2>
          <p className="text-sm">{pricingData.plans[0].desc}</p>
        </div>
        <div>
          <p className="rounded-md bg-slate-500 px-2 py-1 text-xs text-white">
          {pricingData.plans[0].plan}
          </p>
          </div>
        </div>
      </div>
      <div className="account-features mobile text-slate-900 gap-y-8">
        <div className="feature border-b-2 border-slate-100">
        <FaCheck className="text-green-600"/><p className="text-sm">
          {pricingData.featuresItems[0].name}
          </p>
        </div>
        <div className="feature border-b-2 border-slate-100">
        <FaXmark className="text-red-600"/><p className="text-sm ">{pricingData.featuresItems[1].name}</p>
        </div>
        <div className="feature border-b-2 border-slate-100">
        <FaXmark className="text-red-600"/><p className="text-sm ">
          {pricingData.featuresItems[2].name}
          </p>
        </div>
        <div className="feature border-b-2 border-slate-100">
        <FaXmark className="text-red-600"/><p className="text-sm ">
          {pricingData.featuresItems[3].name}
          </p>
        </div>
        <div className="feature border-b-2 border-slate-100">
        <FaXmark className="text-red-600"/><p className="text-sm ">
          {pricingData.featuresItems[4].name}
          </p>
        </div>
        <div className="feature border-b-2 border-slate-100">
        <FaXmark className="text-red-600"/><p className="text-sm ">
          {pricingData.featuresItems[5].name}
          </p>
        </div>
        <div className="feature">
        <FaXmark className="text-red-600"/><p className="text-sm ">{pricingData.featuresItems[6].name}</p>
        </div>
      </div>
      <div className="text-center w-full mt-8 mb-4"><CallToAction link="/file-a-claim" text={pricingData.plans[0].cta} /></div>
    </div> */}
    {/* Pricing 2 */}
    <div className="pricing-column pricing-column-1 gap-y-5 rounded-md w-full text-left bg-white p-6 rounded-xl mb-8">
      {/* Account features */}
      <div className="upper-part flex flex-col mb-8">
        <div className="flex items-top justify-between">
          <div className="tier-and-description w-1/2">
          <h2 className="text-2xl font-extrabold">
          {pricingData.plans[1].price}
          </h2>
          <p className="text-sm">{pricingData.plans[1].desc}</p>
        </div>
        <div>
          <p className="rounded-md bg-amber-500 px-2 py-1 text-xs text-white">
          {pricingData.plans[1].plan}
          </p>
          </div>
        </div>
      </div>
      <div className="account-features text-slate-900 gap-y-8">
        <div className="feature border-b-2 border-slate-100">
        <FaCheck className="text-green-600"/><p className="text-sm">
          {pricingData.featuresItems[0].name}
          </p>
        </div>
        <div className="feature border-b-2 border-slate-100">
        <FaCheck className="text-green-600"/><p className="text-sm ">{pricingData.featuresItems[1].name}</p>
        </div>
        <div className="feature border-b-2 border-slate-100">
        <FaCheck className="text-green-600"/><p className="text-sm ">
          {pricingData.featuresItems[2].name}
          </p>
        </div>
        <div className="feature border-b-2 border-slate-100">
        <FaCheck className="text-green-600"/><p className="text-sm ">
          {pricingData.featuresItems[3].name}
          </p>
        </div>
        <div className="feature border-b-2 border-slate-100">
        <FaCheck className="text-green-600"/><p className="text-sm ">
          {pricingData.featuresItems[4].name}
          </p>
        </div>
        <div className="feature border-b-2 border-slate-100">
        <FaCheck className="text-green-600"/><p className="text-sm ">
          {pricingData.featuresItems[5].name}
          </p>
        </div>
        <div className="feature">
        <FaCheck className="text-green-600"/><p className="text-sm ">{pricingData.featuresItems[6].name}</p>
        </div>
      </div>
      <div className="text-center w-full mt-8 mb-4"><CallToAction link="/lost-item-report" text={pricingData.plans[1].cta} /></div>
    </div>
    {/* Pricing 3 */}
    <div className="pricing-column pricing-column-1 gap-y-5 rounded-md w-full text-left bg-white p-6 rounded-xl  opacity-50 prose">
      {/* Account features */}
      <div className="upper-part flex flex-col mb-8">
        <div className="flex items-top justify-between">
          <div className="tier-and-description w-1/2">
          <h2 className="text-2xl font-extrabold">
          ${(configsData?.config_by_pk?.price*6) - pricingData.plans[2].discount}&nbsp;
          <small className="text-xs">({pricingData.plans[2].savings} ${pricingData.plans[2].discount})</small>
          </h2>
          <p className="text-sm w-30">{pricingData.plans[2].desc}</p>
        </div>
        <div>
          <p className="rounded-md bg-violet-500 px-2 py-1 text-xs text-white">
          {pricingData.plans[2].plan}
          </p>
          </div>
        </div>
      </div>
      <div className="account-features text-slate-900 gap-y-8">
        <div className="feature border-b-2 border-slate-100">
        <FaCheck className="text-green-600"/><p className="text-sm">
          {pricingData.featuresItems[0].name}
          </p>
        </div>
        <div className="feature border-b-2 border-slate-100">
        <FaCheck className="text-green-600"/><p className="text-sm ">{pricingData.featuresItems[1].name}</p>
        </div>
        <div className="feature border-b-2 border-slate-100">
        <FaCheck className="text-green-600"/><p className="text-sm ">
          {pricingData.featuresItems[2].name}
          </p>
        </div>
        <div className="feature border-b-2 border-slate-100">
        <FaCheck className="text-green-600"/><p className="text-sm ">
          {pricingData.featuresItems[3].name}
          </p>
        </div>
        <div className="feature border-b-2 border-slate-100">
        <FaCheck className="text-green-600"/><p className="text-sm ">
          {pricingData.featuresItems[4].name}
          </p>
        </div>
        <div className="feature border-b-2 border-slate-100">
        <FaCheck className="text-green-600"/><p className="text-sm ">
          {pricingData.featuresItems[5].name}
          </p>
        </div>
        <div className="feature">
        <FaCheck className="text-green-600"/><p className="text-sm ">{pricingData.featuresItems[6].name}</p>
        </div>
      </div>
      <div className="text-center w-full mt-8 mb-4"><CallToAction link="" text={"Coming " + pricingData.plans[2].cta} /></div>
    </div>
    </div>
</div>

</div>
  );
};
export default PricingTables;
