import { HostedForm } from "react-acceptjs";
import React, { useState, useEffect } from "react";
import { useToasts } from "react-toast-notifications";
import { useNavigate } from "react-router-dom";
import { lambda_end_point } from "../../config";
import { useFetch } from "use-http";
import { PiWalletBold } from "react-icons/pi";
import { hasuraEvents } from "../../utils/hasura_events";

const Payment = ({
  fileReport,
  clearReport,
  first_name,
  last_name,
  address,
  city,
  zip,
  country,
  checked,
  setLoading,
  reportId,
  apiLoginID,
  clientKey,
  scrollPosition,
  config,
}) => {
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const { post, response, error } = useFetch(lambda_end_point, {
    cachePolicy: "no-cache",
    "Content-Type": "application/json",
  });

  function sleep(ms) {
    return new Promise((resolve) => setInterval(resolve, ms));
  }

  const handleSubmit = async (response) => {
    try {
      setLoading(true);
      if (response?.messages?.resultCode === "Error") {
        console.log("error");
        var i = 0;
        while (i < response?.messages?.message?.length) {
          console.log(
            response?.messages?.message[i]?.code +
              ": " +
              response?.messages?.message[i]?.text
          );
          i = i + 1;
        }
      } else {
        let id;
        let report;
        console.log("reportId >>", reportId);
        const reportIDLS = localStorage.getItem("reportId");
        console.log(reportIDLS, "ls report id");
        if (reportId) {
          id = reportId;
          await sleep(3000);
        } else if (reportIDLS && reportIDLS?.trim() !== "") {
          id = reportIDLS;
        } else {
          report = await fileReport(config);
          id = report?.id;
        }
        if (id) {
          console.log("just before making the API call", id);
          const send = await post(`transaction/create`, {
            opaqueData: response?.opaqueData,
            report_id: id,
            first_name: first_name,
            last_name: last_name,
            address: address,
            city: city,
            zip: zip,
            country: country,
          });
          console.log(send, "send");
          try {
            // window.gtag("event", "conversion", {});

            window.gtag("event", "conversion", {
              send_to: "AW-758624954/lbfwCN7Ez4MYELrl3ukC",
              transaction_id: send?.data?.order?.id,
            });
          } catch (e) {
            console.log(e, "<<< === error for window gtag");
          }
          await clearReport();
          if (!send || send?.status === "error") {
            if (config?.payment_reminder?.on) {
              console.log(
                parseInt(config?.payment_reminder?.time),
                config?.payment_reminder?.time,
                new Date(
                  new Date().setMinutes(
                    new Date().getMinutes() +
                      parseInt(config?.payment_reminder?.time) || 60
                  )
                ).toISOString()
              );
              await hasuraEvents(
                "payment_failure",
                { report_id: id },
                new Date(
                  new Date().setMinutes(
                    new Date().getMinutes() +
                      parseInt(config?.payment_reminder?.time) || 60
                  )
                ).toISOString()
              );
            }
            navigate(`/${id}?success=false`);

            throw send?.message || send || "transaction failed";
          } else navigate(`/${id}?success=true`);
          return send?.data;
        } else {
          throw report?.error;
        }
        // } else {
        //   id = reportId;
        // }
        // if (id) {

        // }
      }
    } catch (e) {
      addToast("Payment failed. Try again or contact support", {
        appearance: "error",
        autoDismiss: true,
      });
      console.log(e);
      await clearReport();
      navigate("/lost-item-report?success=false");
      return false;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (checked) {
        window.scrollTo(0, scrollPosition);
      }
    }, 500);
  }, [checked]);

  return (
    <div className={`flex justify-center w-full`}>
      {checked ? (
        <HostedForm
          key={"auth-button"}
          disabled={!checked}
          authData={{ apiLoginID: apiLoginID, clientKey: clientKey }}
          environment="PRODUCTION"
          onSubmit={handleSubmit}
          buttonText={
            <div className="bg-slate-700 hover:bg-slate-800  cursor-pointer h-[52px] justify-center items-center  text-center flex space-x-2   text-lg text-white px-4 rounded-md font-semibold w-full">
              <PiWalletBold className="" height={8} />{" "}
              <span>Debit or Credit Card</span>
            </div>
          }
          containerClassName="custom-style"
          buttonClassName="bg-slate-700  text-center text-white text-lg rounded-md font-semibold w-full"
        />
      ) : (
        <div className="custom-style">
          <div className="bg-slate-700 hover:bg-slate-800 h-[52px] cursor-not-allowed opacity-50 justify-center items-center  text-center flex space-x-2   text-lg text-white px-4 rounded-md font-semibold w-full">
            <PiWalletBold className="" height={8} />{" "}
            <span>Debit or Credit Card</span>
          </div>
        </div>
      )}
    </div>
  );
};
export default Payment;
