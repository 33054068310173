import React, { useState, useEffect } from 'react';
import { getServiceData } from '../utility';
import { Airport, RentCar, Hotel, Vegas, ThemePark, Ship } from '../../icons'; 

const ServiceAreas = () => {
  const [serviceContent, setServiceContent] = useState({});

  useEffect(() => {
    const data = getServiceData();
    setServiceContent(data);
  }, []);

  return (
    <div id='services' className="text-center bg-blue-50 py-16">
      <h2 className="text-3xl font-bold text-blue-700 mb-8 uppercase">{serviceContent.title}</h2>
      <div className="flex flex-wrap justify-between md:w-8/12 w-full mx-auto">
        {Object.keys(serviceContent).length > 0 && serviceContent.serviceAreas.map((service, index) => (
          <div key={index} className="w-1/3 md:w-1/6 p-2 flex flex-col items-center justify-center">
            <a href={service.link} className="transition ease-in-out delay-150 hover:scale-110 duration-300 hover:invert">
              {service.icon === 'Airport' && <Airport className="w-12 text-blue-700" />}
              {service.icon === 'RentCar' && <RentCar className="w-12 text-blue-700" />}
              {service.icon === 'Hotel' && <Hotel className="w-12 text-blue-700" />}
              {service.icon === 'Vegas' && <Vegas className="w-12 text-blue-700" />}
              {service.icon === 'ThemePark' && <ThemePark className="w-12 text-blue-700" />}
              {service.icon === 'Ship' && <Ship className="w-12 text-blue-700" />}
            </a>
            <small className='font-semibold text-slate-700'>{service.name}</small>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServiceAreas;