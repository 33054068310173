import React, { useEffect } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useNavigate } from "react-router-dom";
import { hasuraEvents } from "../../utils/hasura_events";
const Repay = ({
  configsData,
  check,
  handleSubmit,
  createOrder,
  clearReport,
  scrollPosition,
  paymentLoading,
  validate,
  id,
}) => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, scrollPosition);
  }, [scrollPosition]);
  return (
    <div className={`grid gap-4 md:grid-cols-2 mt-8 w-full`}>
      {/* <div className={``}>  fot auth card on*/}
      {configsData?.config_by_pk?.pay_method?.paypal ||
      configsData?.config_by_pk?.pay_method?.paypal_card ||
      configsData?.config_by_pk?.pay_method?.venmo ? (
        <PayPalScriptProvider
          options={{
            intent: "capture",
            currency: "USD",
            clientId: configsData?.config_by_pk?.paypal_client_id,
            components: "funding-eligibility,buttons",
            "enable-funding": "venmo",
          }}
        >
          {configsData?.config_by_pk?.pay_method?.venmo ? (
            <PayPalButtons
              disabled={paymentLoading || !check}
              style={{
                layout: "horizontal",
                color: "blue",
                label: "pay",
                height: 52,
              }}
              containerWidth={200}
              className="paypal-container disabled:cursor-not-allowed"
              onApprove={async (data) => {
                handleSubmit(data);
              }}
              fundingSource="venmo"
              onError={async (err) => {
                await clearReport();
                console.log(id);
                if (configsData?.config_by_pk?.payment_reminder?.on) {
                  await hasuraEvents(
                    "payment_failure",
                    { report_id: id },
                    new Date(
                      new Date().setMinutes(
                        new Date().getMinutes() +
                          parseInt(
                            configsData?.config_by_pk?.payment_reminder?.time
                          ) || 60
                      )
                    ).toISOString()
                  );
                }
                navigate(`/${id}?success=false`);
              }}
              onClick={async () => {
                const validated = await validate();
                if (validated?.status === "success") {
                  return true;
                } else {
                  return false;
                }
              }}
              createOrder={createOrder}
              forceReRender={[check]}
            />
          ) : null}
          {configsData?.config_by_pk?.pay_method?.paypal_card ? (
            <PayPalButtons
              disabled={paymentLoading || !check}
              style={{
                layout: "horizontal",
                color: "black",
                label: "pay",
                height: 52,
              }}
              containerWidth={200}
              className="paypal-container disabled:cursor-not-allowed"
              onApprove={async (data) => {
                handleSubmit(data);
              }}
              fundingSource="card"
              onError={async (err) => {
                await clearReport();
                if (configsData?.config_by_pk?.payment_reminder?.on) {
                  await hasuraEvents(
                    "payment_failure",
                    { report_id: id },
                    new Date(
                      new Date().setMinutes(
                        new Date().getMinutes() +
                          parseInt(
                            configsData?.config_by_pk?.payment_reminder?.time
                          ) || 60
                      )
                    ).toISOString()
                  );
                }
                navigate(`/${id}?success=false`);
              }}
              onClick={async () => {
                const validated = await validate();
                if (validated?.status === "success") {
                  return true;
                } else {
                  return false;
                }
              }}
              createOrder={createOrder}
              forceReRender={[check]}
            />
          ) : null}
          {configsData?.config_by_pk?.pay_method?.paypal ? (
            <PayPalButtons
              disabled={paymentLoading || !check}
              style={{
                layout: "horizontal",
                color: "blue",
                label: "pay",
                height: 52,
              }}
              containerWidth={200}
              className="paypal-container disabled:cursor-not-allowed"
              onApprove={async (data) => {
                handleSubmit(data);
              }}
              fundingSource="paypal"
              onError={async (err) => {
                await clearReport();
                console.log(id);
                if (configsData?.config_by_pk?.payment_reminder?.on) {
                  await hasuraEvents(
                    "payment_failure",
                    { report_id: id },
                    new Date(
                      new Date().setMinutes(
                        new Date().getMinutes() +
                          parseInt(
                            configsData?.config_by_pk?.payment_reminder?.time
                          ) || 60
                      )
                    ).toISOString()
                  );
                }
                navigate(`/${id}?success=false`);
              }}
              onClick={async () => {
                const validated = await validate();
                if (validated?.status === "success") {
                  return true;
                } else {
                  return false;
                }
              }}
              createOrder={createOrder}
              forceReRender={[check]}
            />
          ) : null}
        </PayPalScriptProvider>
      ) : null}
    </div>
  );
};
export default Repay;
