import React, { useEffect, useState } from "react";
import Typography from "./typography";
import Button from "./button";
import { MultipleItems } from "./item_details/multiple_items";
import { validateItemDetails } from "../utils/validation";
import { useToasts } from "react-toast-notifications";
import { Loader, LoadingIcon } from "../icons";
import { Accordion } from "flowbite-react";

// store the new item in  a temp array -- done
// on click of add new add to main array -- done
// edit  main array --done
// remove from main array --done
// on change of main array add it to report details --done
// make category_id null if it is other --done
// on change of  temp array get validated in the same page it self --done

const ItemDetails = ({
  setSelectedStepper,
  stepperItems,
  handleSetReport,
  errors,
  setErrors,
  activeStep,
  setActiveStep,
  schema,
  setSchema,
  report,
  progress,
  setProgress,
  images,
  setImages,
  loading,
}) => {
  const { addToast } = useToasts();
  const [valid, setValid] = useState(false);
  // 0:  no images
  // 1:  image there without uploading
  // 2:  image all uploaded
  const [tempItemData, setTempItemData] = useState({ details: [], images: [] });
  const [tempError, setTempError] = useState(true);
  const [tempErrorObject, setTempErrorObject] = useState({});
  const [tempSchema, setTempSchema] = useState([]);
  const [tempProgress, setTempProgress] = useState([]);
  const [tempImages, setTempImages] = useState([]);
  const [uploading, setUploading] = useState(false);
  const handleReport = async () => {
    const validate = await validateItemDetails(
      schema,
      report?.report_categories?.data
    );
    if (!validate?.error) {
      setErrors({});
    }
    if (validate?.error) {
      setErrors(validate?.error);
      console.log(validate?.error, schema, report?.report_categories?.data);
      if (valid) {
        setValid(false);
        setActiveStep({
          ...activeStep,
          1: { valid: false, navigate: true },
          2: { navigate: false },
        });
      }
    } else {
      if (!valid) {
        setValid(true);
        setActiveStep({
          ...activeStep,
          1: { valid: true, navigate: true },
          2: { ...activeStep[2], navigate: true },
        });
      }
    }
  };
  useEffect(() => {
    handleReport();
  }, [schema, report?.report_categories?.data]);

  const saveImageAlert = () => {
    addToast(
      "Please wait till uploading completes the images before navigating",
      {
        autoDismiss: true,

        appearance: "warning",
      }
    );
  };
  const removeFromMainArray = async (id) => {
    const tempMainArray = [...report?.report_categories?.data];
    console.log(tempMainArray, "temp main array");
    tempMainArray?.splice(id, 1);
    console.log(tempMainArray, "after updating");
    const tempMainSchema = [...schema];
    tempMainSchema.splice(id, 1);
    //remove progress
    const tempMainProgress = [...progress] ?? [];
    tempMainProgress?.splice(id, 1);
    //remove images
    const tempMainImages = [...images] ?? [];

    tempMainImages?.splice(id, 1);
    setImages(tempMainImages);
    setProgress(tempMainProgress);
    setSchema(tempMainSchema);
    handleSetReport({
      report_categories: { data: [...tempMainArray] },
    });
  };
  const addToMainArray = async () => {
    // const mainImageList = [...images];
    // const subImagelist = mainImageList[id];
    // subImagelist?.splice(index, 1);
    // mainImageList?.splice(id, 1, subImagelist);
    // setImages(subImagelist);
    const validateTempItem = await validate();
    if (!validateTempItem?.error) {
      const tempMainSchema = [...schema];
      tempMainSchema.unshift(tempSchema);
      const tempMainArray = [...report?.report_categories?.data];
      tempMainArray.unshift(tempItemData);
      const tempMainProgress = progress?.length > 0 ? [...progress] : [];
      tempMainProgress.unshift(tempProgress);
      const tempMainImages = images?.length > 0 ? [...images] : [];
      tempMainImages.unshift([...tempImages]);
      setImages([...tempMainImages]);
      setProgress([...tempMainProgress]);
      setTempImages([]);
      setTempProgress([]);
      handleSetReport({
        report_categories: { data: [...tempMainArray] },
      });
      setSchema([...tempMainSchema]);
      setTempItemData({ details: [] });
      setTempSchema([]);
      return { report: tempMainArray, schema: tempMainSchema };
    } else {
      setTempErrorObject(validateTempItem?.error);
      return false;
    }
  };
  const validate = async (id) => {
    const errorsTemp = {};
    if (tempItemData?.category_id === undefined) {
      errorsTemp["itemCategory"] = "Please select an item category";
      // throw "item category cannot be empty";
    }

    if (tempItemData?.category_id === null) {
      if (
        !tempItemData?.description ||
        tempItemData?.description?.trim() === ""
      ) {
        errorsTemp["itemDescription"] =
          "Please enter a description for the lost item ";
      }
    }
    tempSchema?.forEach((s) => {
      if (s?.required) {
        if (
          !tempItemData?.details?.find((l) => l?.title === s?.name) ||
          (typeof tempItemData?.details?.find((l) => l?.title === s?.name)
            ?.value === "string" &&
            tempItemData?.details
              ?.find((l) => l?.title === s?.name)
              ?.value?.trim() === "")
        ) {
          errorsTemp[s?.name] = `Please enter ${s?.name}`;
        }
      }
    });
    // if theres id add to main error with id
    if (id === undefined) {
      if (
        tempItemData?.category_id === undefined ||
        Object.keys(errorsTemp)?.length > 0
      ) {
        setTempError(true);
        if (Object.keys(tempErrorObject)?.length > 0) {
          setTempErrorObject(errorsTemp);
        }
        return { error: errorsTemp };
      } else {
        setTempError(false);
        setTempErrorObject({});
        return true;
      }
    }
  };
  const removeFromTemp = async () => {
    if (!uploading) {
      setTempImages([]);
      setTempProgress([]);
      setTempItemData({ details: [] });
      setTempSchema([]);
      setTempError(false);
    }
  };
  // console.log(
  //   tempError,
  //   "temp error",
  //   tempItemData?.details?.length > 0 ||
  //     tempItemData?.description?.trim() !== ""
  // );
  const validateTempData = async () => {
    try {
      const validateTempItem = await validate();

      if (!validateTempItem?.error) {
        setErrors({});
        setValid(true);
      } else {
        setValid(false);
      }
    } catch (e) {}
  };

  useEffect(() => {
    if (
      progress
        ?.flatMap((innerArray) => innerArray)
        .some((obj) => obj.progress < 100) ||
      tempProgress?.some((obj) => obj.progress < 100)
    ) {
      setUploading(true);
    } else {
      setUploading(false);
    }
  }, [progress, tempProgress]);
  useEffect(() => {
    validateTempData();
  }, [tempItemData, tempSchema]);
  const BackButton = () => {
    return (
      <span
        onClick={(e) => {
          e.preventDefault();
          if (uploading) {
            saveImageAlert();
          } else setSelectedStepper(stepperItems[0]);
        }}
        className={`${
          uploading ? "cursor-not-allowed " : "cursor-pointer"
        } text-sm text-[#256ef8]`}
      >
        Back
      </span>
    );
  };
  return (
    <div className="text-start mt-16" id={"item-details"}>
      <Typography title={"What was lost?"} />
      <div className="">
        {[...report?.report_categories?.data]?.map((ma, i) => {
          return (
            <div className="my-6 border-b border-dashed">
              <MultipleItems
                id={i}
                tempError={tempError}
                setTempError={setTempError}
                errors={errors[i]}
                handleSetReport={handleSetReport}
                report={report}
                schema={schema}
                setSchema={setSchema}
                tempItemData={ma}
                validate={validate}
                progress={progress}
                setProgress={setProgress}
                images={images}
                setImages={setImages}
                removeFromMainArray={removeFromMainArray}
                data={report?.report_categories?.data}
                uploading={uploading}
                deleteButton={report?.report_categories?.data?.length > 1}
              />
            </div>
          );
        })}
      </div>
      <MultipleItems
        deleteButton={
          tempItemData?.category_id !== undefined &&
          (tempItemData?.category_id === null || schema?.length > 0)
        }
        errors={tempErrorObject}
        schema={schema}
        setSchema={setSchema}
        setTempItemData={setTempItemData}
        tempItemData={tempItemData}
        setTempError={setTempError}
        tempError={tempError}
        setTempSchema={setTempSchema}
        tempSchema={tempSchema}
        validate={validate}
        progress={tempProgress}
        setProgress={setTempProgress}
        images={tempImages}
        setImages={setTempImages}
        removeFromMainArray={removeFromTemp}
        uploading={uploading}
      />
      <div className="flex w-full justify-between items-center my-8  md:pb-8">
        {/* <span
          onClick={(e) => {
            e.preventDefault();
            if (uploading) {
              saveImageAlert();
            } else setSelectedStepper(stepperItems[0]);
          }}
          className={`${
            uploading ? "cursor-not-allowed " : "cursor-pointer"
          } text-sm text-[#256ef8]`}
        >
          Back
        </span> */}
        <div className="hidden md:block w-full">
          <BackButton />
        </div>
        <div className="flex flex-col sm:flex-row justify-between md:justify-end w-full  gap-4">
          {tempError ? (
            <></>
          ) : (
            <>
              {tempItemData?.details?.length > 0 ||
              (tempItemData?.description &&
                tempItemData?.description?.trim() !== "") ? (
                <button
                  disabled={uploading}
                  className="rounded-md md:w-44 w-full disabled:opacity-30 py-3 px-8 bg-white border-2 border-blue-700 hover:text-white hover:bg-blue-700 disabled:cursor-not-allowed text-blue-700 font-bold text-base flex justify-center  hover:shadow-md"
                  onClick={(e) => {
                    e.preventDefault();
                    if (!uploading) {
                      addToMainArray();
                    }
                  }}
                >
                  {uploading ? (
                    <LoadingIcon className="font-bold" height={25} width={25} />
                  ) : (
                    "Add Another"
                  )}
                </button>
              ) : (
                <></>
              )}
            </>
          )}
          <Button
            className={`${
              uploading
                ? "cursor-not-allowed opacity-30 hover:bg-blue-700  "
                : ""
            } w-full md:w-44`}
            disabled={!valid}
            value={"Next"}
            onClick={async (e) => {
              e.preventDefault();
              try {
                if (uploading) {
                  // not allow when image is getting uploaded
                } else {
                  let tempReport = [...report?.report_categories?.data];
                  let tempSchema = [...schema];
                  let add;
                  if (tempItemData?.category_id !== undefined) {
                    add = await addToMainArray();
                    if (add?.report) {
                      tempReport = [...add?.report];
                      tempSchema = [...add?.schema];
                    } else {
                      return false;
                    }
                  }

                  const reportLength = add?.report
                    ? add?.report?.length
                    : report?.report_categories?.data?.length;

                  if (reportLength < 1) {
                    addToast("add atleast one lost item", {
                      appearance: "error",
                    });
                    return false;
                  }

                  const validate = await validateItemDetails(
                    [...tempSchema],
                    [...tempReport]
                  );
                  if (validate?.error) {
                    setErrors(validate?.error);
                    return false;
                  }
                  setErrors({});

                  setSelectedStepper(stepperItems[2]);

                  //  else
                  // if (
                  //   progress
                  //     .flatMap((innerArray) => innerArray)
                  //     .some((obj) => obj.progress < 100) ||
                  //   tempProgress?.some((obj) => obj.progress < 100)
                  // ) {
                  //   saveImageAlert();
                  // }
                }
              } catch (e) {
              } finally {
              }
            }}
          />
        </div>
      </div>
      <div className=" md:hidden w-full">
        <BackButton />
      </div>
    </div>
  );
};
export default ItemDetails;
