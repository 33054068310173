import React, { useEffect, useState } from "react";
import HeaderPayment from "../components/header_payment";
import FooterPayment from "../components/footer_payment";
import Benefits from "../components/benefits";
import { useToasts } from "react-toast-notifications";
import { useQuery } from "urql";
import { GetReportDataQuery } from "../queries";
import { LoadingIcon } from "../icons";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import CheckBox from "../components/checkbox";
import Typography from "../components/typography";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useFetch } from "use-http";
import { lambda_end_point } from "../config";
import PhoneInput, {
  getCountryCallingCode,
  parsePhoneNumber,
} from "react-phone-number-input";
import { Link } from "react-router-dom";
import Payment from "../components/auth/payment";
import Repay from "../components/paypal/repay";
function PaymentTwo() {
  const [param] = useSearchParams();
  const id = param.get("claim_id");
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const [scrollPosition, setScrollPosition] = useState(0);
  const [paymentLoading, setLoading] = useState(false);
  const [payment, setPayment] = useState({});
  const [configResults, reexecuteConfigQuery] = useQuery({
    query: GetReportDataQuery,
    pause: true,
    variables: { id },
  });
  const {
    data: configsData,
    fetching: configsFetching,
    error: configsError,
  } = configResults;

  const { post, response, error } = useFetch(lambda_end_point, {
    cachePolicy: "no-cache",
    "Content-Type": "application/json",
  });
  const [check, setCheck] = useState(false);

  const validate = () => {
    try {
      if (!payment?.first_name || payment?.first_name?.trim() === "") {
        throw "First Name cannot be empty";
      } else if (!payment?.last_name || payment?.last_name?.trim() === "") {
        throw "Last Name cannot be empty";
      } else if (
        !payment?.phone_number ||
        payment?.phone_number?.trim() === ""
      ) {
        throw "Phone Number cannot be empty";
      } else if (!payment?.email || payment?.email?.trim() === "") {
        throw "Name cannot be empty";
      } else if (!payment?.zip || payment?.zip?.trim() === "") {
        throw "Postal Code /Zip cannot be empty";
      }
      return {
        status: "success",
      };
    } catch (e) {
      console.log(e);
      addToast(e, {
        appearance: "warning",
        autoDismiss: true,
      });
      return { status: "error", error: e };
    }
  };
  const clearReport = () => {
    setPayment({});
  };
  const createOrder = async (data) => {
    try {
      if (id) {
        const parsedPhoneNumber = parsePhoneNumber(payment?.phone_number);
        const countryCallingCode = parsedPhoneNumber?.country;
        const callingCode = getCountryCallingCode(countryCallingCode);
        const restOfPhoneNumber = payment?.phone_number?.split(callingCode)[1];
        const send = await post(`paypal/create-order`, {
          reference_id: id,
          paypal_type: data.paymentSource,
          email: payment.email,
          first_name: payment?.first_name,
          last_name: payment?.last_name,
          phone_number: restOfPhoneNumber,
          country_code: countryCallingCode,
          postal_code: payment?.zip,
        });
        return send?.data?.order?.id;
      }
    } catch (e) {
      addToast(e || "report couldn't be filed", {
        appearance: "error",
        autoDismiss: true,
      });
      return false;
    }
  };
  const handleSubmit = async (data) => {
    try {
      setLoading(true);
      const send = await post(`paypal/capture-payment`, {
        order_id: data?.orderID,
      });
      navigate(`/${id}?success=true`);
      return send?.data?.order?.id;
    } catch (e) {
      return false;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    reexecuteConfigQuery({ requestPolicy: "network-only" });
  }, []);

  useEffect(() => {
    setPayment({
      first_name: configsData?.reports_by_pk?.first_name,
      last_name: configsData?.reports_by_pk?.last_name,
      email: configsData?.reports_by_pk?.email,
      phone_number: configsData?.reports_by_pk?.phone_number,
      zip: configsData?.reports_by_pk?.zip,
    });
  }, [configsData]);
  // const handleScroll = () => {
  //   const position = window.scrollY;
  //   setScrollPosition(position);
  // };

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll, { passive: true });

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, [check]);
  return (
    <div className="h-screen">
      <HeaderPayment />
      {paymentLoading || configsFetching ? (
        <div className="text-start  flex items-center h-[600px] my-1 max-h-screen justify-center ">
          <LoadingIcon className="h-32 rounded-full text-blue-200 w-32" />
        </div>
      ) : configsData ? (
        configsData?.reports_by_pk?.status === "no_payment" ||
        configsData?.reports_by_pk?.status === "not_paid_closed" ? (
          <>
            <div className="bg-blue-950 text-4xl md:text-5xl text-white font-bold py-8 ">
              Claim ID - {configsData?.reports_by_pk?.claim_id}
            </div>
            <div className="max-w-2xl mx-auto text-lg pt-6">
              <div className="text-white my-4 font-semibold text-center mb-10">
                Make the payment to start the process
              </div>
            </div>

            <div className="text-left mb-14 bg-white max-w-5xl md:mx-auto mx-2 p-4 md:p-14 rounded-md">
              <div className="grid md:grid-cols-2 gap-x-2">
                {" "}
                <div className="mb-3">
                  <label
                    for="subject-input"
                    className="text-xs text-slate-500 font-semibold"
                  >
                    First Name *
                  </label>
                  <input
                    disabled
                    placeholder="First Name*"
                    type="text"
                    value={payment?.first_name}
                    onChange={(e) => {
                      setPayment({ ...payment, first_name: e.target?.value });
                    }}
                    id="subject-input"
                    className={`appearance-none font-semibold cursor-text bg-slate-50 rounded-lg px-2 my-1 border-2 border-slate-200 text-slate-700 text-sm h-10 w-full`}
                  />
                </div>
                <div className="mb-3">
                  <label
                    for="subject-input"
                    className="text-xs text-slate-500 font-semibold"
                  >
                    Last Name *
                  </label>
                  <input
                    disabled
                    placeholder="Last Name"
                    type="text"
                    value={payment?.last_name}
                    onChange={(e) => {
                      setPayment({ ...payment, last_name: e.target?.value });
                    }}
                    id="subject-input"
                    className={`appearance-none font-semibold cursor-text bg-slate-50 rounded-lg px-2 my-1 border-2 border-slate-200 text-slate-700 text-sm h-10 w-full`}
                  />
                </div>
                <div className="mb-3">
                  <label
                    for="subject-input"
                    className="text-xs text-slate-500 font-semibold"
                  >
                    Phone Number *
                  </label>
                  <PhoneInput
                    disabled={true}
                    className={`appearance-none font-semibold cursor-text bg-slate-50 rounded-lg px-2 my-1 border-2 border-slate-200 text-slate-700 text-sm h-10 w-full`}
                    countryOptionsOrder={["US", "CA", "GB"]}
                    international={true}
                    countryCallingCodeEditable={false}
                    placeholder="Phone Number *"
                    value={payment?.phone_number}
                    onChange={(value) => {
                      setPayment({ ...payment, phone_number: value });
                    }}
                  />{" "}
                </div>
                <div className="mb-3">
                  <label
                    for="subject-input"
                    className="text-xs text-slate-500 font-semibold"
                  >
                    Email *
                  </label>
                  <input
                    onChange={(e) => {
                      setPayment({ ...payment, email: e.target?.value });
                    }}
                    disabled
                    value={payment?.email}
                    placeholder="Email*"
                    type="email"
                    id="subject-input"
                    className={`appearance-none font-semibold cursor-text bg-slate-50 rounded-lg px-2 my-1 border-2 border-slate-200 text-slate-700 text-sm h-10 w-full`}
                  />
                </div>
                {/* <div className="mb-3">
                <label
                  for="subject-input"
                  className="block mb-2 text-sm font-medium text-blue-900"
                >
                  Postal Code / ZIP*{" "}
                </label>
                <input
                  placeholder="Postal Code / ZIP*"
                  type="text"
                  value={payment?.zip}
                  onChange={(e) => {
                    setPayment({ ...payment, zip: e.target?.value });
                  }}
                  id="subject-input"
                  className={`py-3.5 bg-white border border-slate-300 text-slate-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
                />
              </div> */}
              </div>
              <div>
                <Benefits />
              </div>
              <div className="h-4"></div>
              {/* <Typography title={"Payment Details"} /> */}
              <div className="mt-4">
                {/* <textarea
                  className="w-full appearance-none font-medium	 cursor-text bg-slate-50 rounded-lg p-3 my-1 border-2 border-slate-200 text-slate-700 text-xs"
                  rows="8"
                  disabled
                  value={`By using this service, you agree to pay the NON-REFUNDABLE FEE of $${
                    configsData?.config_by_pk?.price || "35"
                  } to file your lost item claim. You hereby provide Lost and Found Concierge with the authority to contact lost and found departments and custodians on your behalf, including the filing of reports. Furthermore, THERE ARE NO GUARANTEES OF RECLAIMING LOST ITEMS. You understand that we provide services on your behalf filing a lost item claim and interacting with such lost and found departments and custodians to assist you to retrieve your lost item. This is a Non-Refundable purchase. Under no circumstance will you be entitled to a refund. Lost and Found Concierge does not and will not issue refunds. Our fees do not include shipping and shipping fees must be paid separately.

You also certify that the information provided is true to the best of your knowledge. Any false or fraudulent statement may constitute as being a fraud and may be subject to any applicable civil and criminal penalties.You also understand and acknowledge that Lost and Found Concierge is not a lost and found department and does not serve as a lost and found custodian but a lost and found document filing service and charges a convenience fee for our service. Lost and Found Concierge has no partnership with, and is not associated or affiliated with any config, airport, the TSA, theme park, resort, timeshare, vacation rental, ride share company, taxi, rental car company, transit system, cruise line or other venue or entity where you may have lost an item. You acknowledge that you may file this report directly on your own without paying a convenience fee but elect to have Lost and Found Concierge file your report and pay the required fee solely as a convenience to you.
          
Electronic Signature Consent & Agreement: By submitting your electronic signature, you are signing this Agreement electronically. You agree your electronic signature is the legal equivalent of your manual signature on this Agreement. By selecting "I Accept" you consent to be legally bound by this Agreement's terms and conditions. You further agree that your use of a keypad, mouse or other device to select an item, button, icon or similar act/action, or to otherwise provide Lost and Found Concierge instructions via this website, or in accessing or making any transaction regarding any agreement, acknowledgement, consent terms, disclosures or conditions constitutes your signature (hereafter referred to as "E-Signature"), acceptance and agreement as if actually signed by you in writing. You also agree that no certification authority or other third-party verification is necessary to validate your E-Signature and that the lack of such certification or third-party verification will not in any way affect the enforceability of your E-Signature or any resulting contract between you and Lost and Found Concierge. You also represent that you are authorized to enter into this Agreement for all persons who own or are authorized to access any of your accounts and that such persons will be bound by the terms of this Agreement. You further agree that each use of your E-Signature in obtaining Lost and Found Concierge services constitutes your agreement to be bound by the terms and conditions of the Lost and Found Concierge Disclosures and Agreements as they exist on the date of your E-Signature.
          
Valid and current email address, notification, and updates: Your current valid email address is required in order for you to obtain Lost and Found Concierge services. You agree to keep Lost and Found Concierge informed of any changes in your email address. Lost and Found Concierge may notify you through email when an Electronic Communication or update on your lost item is made available. It is recommended that you print a copy of this Agreement for future reference.
          
By clicking the "Submit" button, you agree to be bound by the terms of this agreement.You also acknowledge that Lost and Found Concierge in no way warrants the information you submit to us for filing your lost item claims. We file your claims based on the information you provide and do not guarantee any results.
          `}
                ></textarea> */}
                <div className="mt-4">
                  <CheckBox
                    checked={check}
                    labelValue={
                      <p>
                        I have read and agreed to the{" "}
                        <Link
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-700 font-extrabold"
                          to="/payment-terms"
                        >
                          Payment Terms and Conditions
                        </Link>
                        .{" "}
                        {/* <span className="font-bold text-sm">
                        ${configsData?.config_by_pk?.price || "35"}
                      </span> */}
                      </p>
                    }
                    onChange={async (e) => {
                      const checked = e.target.checked;
                      const validated = await validate();
                      if (validated?.status === "success") {
                        setCheck(checked);
                      } else {
                      }
                    }}
                  />
                </div>
              </div>{" "}
              {/* <div className="grid gap-4 md:grid-cols-2 mt-8"> for Auth button ON */}
              <div className="flex justify-between w-full">
                {" "}
                {/*For paypal on*/}
                {configsData?.config_by_pk?.pay_method?.auth_net ? (
                  <Payment
                    address={configsData?.reports_by_pk?.address}
                    checked={check}
                    city={configsData?.reports_by_pk?.city}
                    clearReport={clearReport}
                    country={configsData?.reports_by_pk?.country}
                    fileReport={() => {
                      return true;
                    }}
                    first_name={payment?.first_name}
                    last_name={payment?.last_name}
                    setLoading={setLoading}
                    zip={payment?.zip}
                    reportId={configsData?.reports_by_pk?.id}
                    apiLoginID={configsData?.config_by_pk?.auth_login_id}
                    clientKey={configsData?.config_by_pk?.auth_client_id}
                    scrollPosition={scrollPosition}
                    loading={paymentLoading}
                    config={configsData?.config_by_pk}
                  />
                ) : null}
                <Repay
                  check={check}
                  clearReport={clearReport}
                  configsData={configsData}
                  createOrder={createOrder}
                  handleSubmit={handleSubmit}
                  scrollPosition={scrollPosition}
                  validate={validate}
                  paymentLoading={paymentLoading}
                  id={configsData?.reports_by_pk?.id}
                />
              </div>
            </div>
          </>
        ) : (
          <div className="my-32 h-96">
            <div className="relative p-4 w-full max-w-lg h-full md:h-auto m-auto">
              <div className="relative p-4 text-center bg-blue-50 rounded-md shadow sm:p-5">
                <div className=" mb-2 text-2xl font-bold text-blue-700 ">
                  Claim ID - {configsData?.reports_by_pk?.claim_id}
                </div>
                <p className="mb-4 text-sm font-semibold">
                  You have already completed your Payment successfully. <br />
                  You may track your lost item from here.
                </p>
                <Link to="/check-status" className="my-2">
                  <button
                    type="button"
                    className="disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-blue-700 rounded-md py-3 px-8 bg-blue-700 text-white font-bold text-lg flex justify-center hover:bg-blue-800 hover:shadow-md w-full"
                  >
                    Track your Lost Item{" "}
                  </button>
                </Link>
                <p className="mt-4 text-sm text-slate-600 font-semibold">
                  Sincerely,
                </p>
                <p className="mb-4 text-sm text-slate-600  font-semibold">
                  Customer Care Team
                </p>
              </div>
            </div>
          </div>
        )
      ) : !id ? (
        <div className="text-start  flex items-center h-[600px] my-1 max-h-screen justify-center ">
          Malformed URL. Report ID is missing in the URL
        </div>
      ) : (
        <div className="text-start  flex items-center h-[600px] my-1 max-h-screen justify-center ">
          Some Error Occured. Please Try Again Refreshing After Some Time.
        </div>
      )}
      <FooterPayment />
    </div>
  );
}

export default PaymentTwo;
