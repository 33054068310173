import data from './data/data.json'

export const getData = () => {
    return data;
  };

export const getHeroData = () => {
  return data.hero || {};
};

export const getLeaveBehindData = () => {
  return data.leavebehind || {};
};

export const getServiceData = () => {
  return data.service || {};
};

export const getHowItWorksData = () => {
  return data.howitworks || {};
};

export const getTimeNotData = () => {
  return data.timenot || {};
};

export const getHeaderHomeData = () => {
    return data.headerhome;
};

export const getHeaderData = () => {
    return data.header;
};

export const getRibbonData = () => {
  return data.ribbon;
};

export const getAboutData = () => {
  return data.about;
};

export const getMarqueeData = () => {
  return data.marquee || {};
};

export const getReviewData = () => {
  return data.reviews || {};
};

export const getFaqData = () => {
  return data.faq;
};

export const getFileAClaimData = () => {
  return data.fileaclaim;
};

export const getContactData = () => {
  return data.contact;
};

export const getTermsAndPrivacyData = () => {
  return data.termsandprivacy;
};

export const getPaymentTermsData = () => {
  return data.paymentterms;
};

export const getFooterData = () => {
    return data.footer || {};
};

export const getBenefitsData = () => {
    return data.benefits || {};
};

export const getPricingData = () => {
  return data.pricing || {};
};
// to replace header data in data.json when go live
  // "header": {
  //   "menuItems": [
  //     { "title": "Home", "link": "/" },
  //     { "title": "Services", "link": "/#services" },
  //     { "title": "FAQ", "link": "/#faq" },
  //     { "title": "Contact", "link": "/contact" }
  //   ]
  // },