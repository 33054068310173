import React from "react";
import { LoadingIcon } from "../icons";
const Button = ({ type, value, className, onClick, disabled, loading }) => {
  return (
    <button
      type={type || "button"}
      // disabled={disabled}
      onClick={(e) => {
        onClick(e);
      }}
      className={` ${
        disabled && " opacity-30 hover:bg-blue-700"
      } rounded-md py-3 px-8 bg-blue-700 text-white font-bold text-base flex justify-center hover:bg-blue-800 hover:shadow-md ${className}`}
    >
      {loading ? (
        <LoadingIcon className="font-bold" height={25} width={25} />
      ) : (
        value
      )}
    </button>
  );
};
export default Button;
