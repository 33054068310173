import React, { useEffect, useState } from "react";
import SelectBox from "../select";
import InputBox from "../input";
import InputDate from "../input_date";
import ErrorMessage from "../error_message";

const LocationShip = ({ locationDetails, handleSetReport, errors }) => {
  const [location, setLocation] = useState([...locationDetails]);

  const handleLocationDetails = async (key, data) => {
    const dataCopy = [...location];
    const findIndex = dataCopy.findIndex((d) => d?.title === key);
    try {
      if (findIndex >= 0) {
        dataCopy[findIndex] = { title: key, value: data };
      } else {
        dataCopy.push({ title: key, value: data });
      }
      setLocation(dataCopy);
    } catch (e) {}
  };

  useEffect(() => {
    handleSetReport({ location_details: location });
  }, [location]);
  useEffect(() => {
    if (
      !locationDetails?.find((l) => l?.title === "date of embarkation")?.value
    ) {
      setLocation([
        ...locationDetails,
        { title: "date of embarkation", value: new Date() },
      ]);
    }
  }, [locationDetails]);
  return (
    <div className="">
      <div className="mt-4">
        {/* at airport */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4">
          <div>
            {" "}
            <InputBox
              value={
                locationDetails?.find((l) => l?.title === "cruise line")
                  ?.value || ""
              }
              onChange={(e) => {
                handleLocationDetails("cruise line", e.target.value);
              }}
              className={"w-full"}
              placeholder={"Cruise Line *"}
            />
            <ErrorMessage
              condition={errors && errors["cruise line"]}
              message={errors && errors["cruise line"]}
            />
          </div>
          <div>
            <InputBox
              value={
                locationDetails?.find((l) => l?.title === "ship name")?.value ||
                ""
              }
              onChange={(e) => {
                handleLocationDetails("ship name", e.target.value);
              }}
              className={"w-full"}
              placeholder={"Cruise Ship Name *"}
            />
            <ErrorMessage
              condition={errors && errors["ship name"]}
              message={errors && errors["ship name"]}
            />
          </div>
          <InputBox
            className={"w-full"}
            value={
              locationDetails?.find((l) => l?.title === "where you lost it")
                ?.value || ""
            }
            onChange={(e) => {
              handleLocationDetails("where you lost it", e.target.value);
            }}
            placeholder={"Location"}
          />
          <div>
            <InputBox
              className={"w-full"}
              placeholder={"Port of embarkation *"}
              value={
                locationDetails?.find((l) => l?.title === "port of embarkation")
                  ?.value || ""
              }
              onChange={(e) => {
                handleLocationDetails("port of embarkation", e.target.value);
              }}
            />
            <ErrorMessage
              condition={errors && errors["port of embarkation"]}
              message={errors["port of embarkation"]}
            />
          </div>
          <div>
            {/* <InputBox
              className={"w-full"}
              type={"date"}
              value={
                locationDetails?.find((l) => l?.title === "date of embarkation")
                  ?.value || new Date().toUTCString()
              }
              maxDate={new Date()}
              onChange={(e) => {
                console.log(e, "hello");
                handleLocationDetails("date of embarkation", e.target.value);
              }}
              placeholder={"Date of embarkation *"}
            /> */}
            <InputDate
              className={"w-full"}
              value={
                locationDetails?.find((l) => l?.title === "date of embarkation")
                  ?.value || null
              }
              maxDate={new Date()}
              onChange={(e) => {
                handleLocationDetails("date of embarkation", e);
              }}
              placeholder={"Date of embarkation *"}
            />
            <ErrorMessage
              condition={errors && errors["date of embarkation"]}
              message={errors && errors["date of embarkation"]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default LocationShip;
