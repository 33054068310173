import { Link } from "react-router-dom";
import logoImg from "../logo_vw.svg";

const HeaderPayment = () => {
  return (
    <div className="w-full mx-auto pt-8 pb-4">
      <div className="py-4">
        <Link to={"/lost-item-report"}>
          <img
            src={logoImg}
            alt="Lost and Found Concierge"
            width={240}
            className="mx-auto mt-6"
          />
        </Link>
      </div>
    </div>
  );
};

export default HeaderPayment;
