// import React, { useState, useEffect } from 'react';
// import CallToAction from '../cta';
// import { getHeroData } from '../data/getHeroData';
// import StarsRow from './stars';
// import NumberUpdater from '../number_updater';

// const HeroSection = ({ airportCode, autoCode }) => {
//   const [heroContent, setHeroContent] = useState({});

//   useEffect(() => {
//     const data = getHeroData(airportCode, autoCode); // Use airportCode to fetch specific data
//     setHeroContent(data);
//   }, [airportCode, autoCode]);

//   return (
//     <div id='top' className='bg-gradient-to-b from-blue-950 to-blue-800 md:h-screen pt-20 items-center place-content-center'>
//       <div className="grid md:grid-cols-2 gap-2 items-center md:px-20 px-8">
//         <div className="container text-white text-left md:pt-0 pt-20">
//           <img
//             className="max-w-full md:hidden block pb-20 max-h-full rounded-xl skew-y-2"
//             src='.././hero-img.webp'
//             alt="Responsive Image"
//           />
//           <div className='flex'><StarsRow rating={5}/>&nbsp; <strong><NumberUpdater /></strong>&nbsp;reviews</div>
//           <h1 className="text-3xl md:py-4 md:w-11/12 md:text-4xl lg:text-5xl font-bold text-left">{heroContent.title}</h1>
//           <p className="text-md md:w-10/12 md:text-md lg:text-md font-semibold mb-8 text-left">{heroContent.subtitle}</p>
//           {heroContent.buttons && heroContent.buttons.map((button, index) => (
//             <CallToAction key={index} link={button.link} text={button.text} />
//           ))}
//         </div>
//         <div className="md:space-y-4 hidden md:block">
//           <img
//             className="max-w-full max-h-full rounded-xl skew-y-2 pb-10"
//             src='.././hero-img.webp'
//             alt="Responsive Image"
//           />
//         </div>
//       </div>
//       <div className='grid grid-cols-7 text-white text-sm md:text-md font-semibold py-8 px-8 text-center items-center mx-auto'>
//         <div><img className='w-12 mx-auto' src='../icons/fill.svg'></img><br/>File a claim</div>
//         <div className='border-t-2 border-white'></div>
//         <div><img className='w-12 mx-auto' src='../icons/notify.svg'></img><br/>Notify <span className='hidden md:block'>Lost & Found</span></div>
//         <div className='border-t-2 border-white'></div>
//         <div><img className='w-12 mx-auto' src='../icons/updates-2.svg'></img><br/>Receive Updates</div>
//         <div className='border-t-2 border-white'></div>
//         <div><img className='w-12 mx-auto' src='../icons/retrieve.svg'></img><br/>Retrieve</div>
//       </div>
//     </div>
//   );
// };

// export default HeroSection;
// import React, { useEffect, useState } from "react";
// import { useParams } from "react-router-dom";
// import { getHeroData } from "../utility";
// import CallToAction from "../cta";
// import StarsRow from "./stars";
// import NumberUpdater from "../number_updater";

// const HeroSection = () => {
//   const { category, code } = useParams(); // Extract category and code from the URL
//   const [heroContent, setHeroContent] = useState({});

//   useEffect(() => {
//     // Fetch hero content based on the category and code
//     const data = getHeroData(category, code);
//     setHeroContent(data);
//   }, [category, code]);

//   return (
//     <div id='top' className='bg-gradient-to-b from-blue-950 to-blue-800 h-screen pt-20 items-center place-content-center'>
//       <div className="grid md:grid-cols-2 gap-2 items-center md:px-20 px-8">
//         <div className="container text-white text-left md:pt-0 pt-20">
//           <img
//             className="max-w-full md:hidden block pb-20 max-h-full rounded-xl skew-y-2"
//             src={heroContent.heroImage}
//             alt="Responsive Image"
//           />
//           <div className='flex'><StarsRow rating={5}/>&nbsp; <strong><NumberUpdater /></strong>&nbsp;reviews</div>
//           <h1 className="text-3xl md:py-4 md:w-8/12 md:text-4xl lg:text-5xl font-bold text-left">{heroContent.title}</h1>
//           <p className="text-md md:pb-4 md:w-10/12 md:text-md lg:text-md font-semibold mb-8 text-left">{heroContent.subtitle}</p>
//           {heroContent.buttons && heroContent.buttons.map((button, index) => (
//             <CallToAction key={index} link={button.link} text={button.text} />
//           ))}
//         </div>
//         <div className="md:space-y-4 hidden md:block">
//           <img
//             className="max-w-full max-h-full rounded-xl skew-y-2"
//             src='.././hero-img.webp'
//             alt="Responsive Image"
//           />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default HeroSection;


import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getHeroData } from '../data/getHeroData';
import CallToAction from '../cta';
import StarsRow from './stars';
import NumberUpdater from '../number_updater';

const HeroSection = () => {
  const { category, subCategory } = useParams(); // Get category and subCategory from URL
  const [heroContent, setHeroContent] = useState({});

  console.log('Hero Content:', heroContent);

  useEffect(() => {
    const data = getHeroData(category, subCategory);
    setHeroContent(data);
  }, [category, subCategory]);

  return (
    <div id='top' className='bg-gradient-to-b from-blue-950 to-blue-800 md:pt-32 pt-16 items-center place-content-center'>
      <div className="grid md:grid-cols-2 gap-2 items-center md:px-20 px-8">
        <div className="container text-white text-left md:pt-0 pt-20">
          <img
            className="max-w-full md:hidden block pb-20 max-h-full rounded-xl skew-y-2"
            src={heroContent.heroImage}
            alt="Responsive Image"
          />
          <div className='flex'><StarsRow rating={5}/>&nbsp; <strong><NumberUpdater /></strong>&nbsp;reviews</div>
          <h1 className="text-3xl md:py-4 md:w-10/12 md:text-4xl lg:text-5xl font-bold text-left">{heroContent.title}</h1>
          <p className="text-md md:pb-4 md:w-10/12 md:text-md lg:text-md font-semibold mb-8 text-left">{heroContent.subtitle}</p>
          {heroContent.buttons && heroContent.buttons.map((button, index) => (
            <CallToAction key={index} link={button.link} text={button.text} />
          ))}
        </div>
        <div className="md:space-y-4 hidden md:block">
          <img
            className="max-w-full max-h-full rounded-xl skew-y-2"
            src={heroContent.heroImage}
            alt="Responsive Image"
          />
        </div>
      </div>
      <div className='grid grid-cols-7 text-white text-sm md:text-md font-semibold py-8 px-8 text-center items-center mx-auto'>
      <div><img className='w-12 mx-auto' src='../icons/fill.svg'></img><br/>File a claim</div>
        <div className='border-t-2 border-white'></div>
        <div><img className='w-12 mx-auto' src='../icons/notify.svg'></img><br/>Notify <span className='hidden md:block'>Lost & Found</span></div>
       <div className='border-t-2 border-white'></div>
     <div><img className='w-12 mx-auto' src='../icons/updates-2.svg'></img><br/>Receive Updates</div>
       <div className='border-t-2 border-white'></div>
       <div><img className='w-12 mx-auto' src='../icons/retrieve.svg'></img><br/>Retrieve</div>
     </div>
    </div>
  );
};

export default HeroSection;