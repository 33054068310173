import { lambda_end_point, localhost } from "../config";

export const hasuraEvents = async (event, payload, schedule_at) => {
  let webhook = "";
  if (event === "payment_failure") {
    webhook = `${lambda_end_point}/hasura-webhook/send-failure-email`;
  }
  try {
    const scheduled = await fetch(`${lambda_end_point}/hasura-webhook/create`, {
      method: "POST",
      body: JSON.stringify({
        type: "create_scheduled_event",
        args: {
          webhook,
          schedule_at,
          payload,
        },
      }),
      redirect: "follow",
    }).then((response) => response.json());
    console.log(scheduled, "scheduled");
    return scheduled;
  } catch (e) {
    console.log(e);
    return {
      error: e,
    };
  }
};

/*
var myHeaders = new Headers();
myHeaders.append("X-Hasura-Role", "admin");
myHeaders.append("x-hasura-admin-secret", "InGJsk3LcKlEURwDkJM0dmGuNtGCMEh6");
myHeaders.append("Content-Type", "application/json");

var raw = JSON.stringify({
  "type": "create_scheduled_event",
  "args": {
    "webhook": "https://webhook.site/07ff27d2-b16f-4373-ac13-dd483fd57c50",
    "schedule_at": "2023-11-24T05:28:00Z",
    "payload": {
      "message": "test message"
    }
  }
});

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

fetch("https://hasura.lostandfoundoffice.org/v1/metadata", requestOptions)
  .then(response => response.text())
  .then(result => console.log(result))
  .catch(error => console.log('error', error));
*/
