import React from "react";
import { Link, useNavigate } from "react-router-dom";

const CallToActionHeader = ({ link, text }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(link);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <button
      onClick={handleClick}
      className="bg-blue-700 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded uppercase text-lg transition ease-in-out delay-150 hover:scale-105 duration-300 hover:shadow-2xl"
    >
      {text}
    </button>
  );
};

export default CallToActionHeader;