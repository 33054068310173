import React from "react";

const ErrorMessage = ({ message, condition, id }) => {
  return (
    <div id={id || message} className="text-xs text-red-500 font-semibold  mb-2">
      {message ? message : null}
    </div>
  );
};
export default ErrorMessage;
