import React, { useEffect, useState } from "react";
import { gql, useQuery } from "urql";
import { ListCategoriesQuery } from "../../queries";
import SelectBox from "../select";

const SelectCategory = ({
  classnames,
  labelValue,
  onChange,
  values,
  ...rest
}) => {
  const [options, setOptions] = useState([]);

  const [categoryResults, reexecuteCategoryQuery] = useQuery({
    query: ListCategoriesQuery,
  });

  const {
    data: categoryData,
    fetching: categoryFetching,
    error: categoryError,
  } = categoryResults;
  useEffect(() => {
    if (categoryData?.categories) {
      setOptions([
        ...categoryData?.categories,
        // { name: "Other", id: "Other" }
      ]);
    }
  }, [categoryData]);

  const handleChange = async (e) => {
    onChange(e);
  };
  return (
    <SelectBox
      loading={categoryFetching}
      noDataLabel={
        categoryFetching
          ? "Loading.."
          : categoryData?.categories
          ? "Select 'Other'"
          : ""
      }
      values={
        // values === null
        //   ? [{ name: "Other", id: "Other" }]
        //   :
        values && options?.length > 0
          ? [options?.find((opt) => opt?.id === values)]
          : []
      }
      valueField={"id"}
      onChange={handleChange}
      options={options}
      classnames={classnames}
      labelValue={labelValue}
      labelField={"name"}
      {...rest}
    />
  );
};
export default SelectCategory;
