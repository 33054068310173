import React, { useState } from "react";

const CheckBox = ({
  labelValue,
  checked,
  onChange,
  inputClassName,
  labelClassName,
}) => {
  return (
    <div id={labelValue} key={labelValue} className="flex items-center my-1">
      <input
        id={`${labelValue}input`}
        key={`${labelValue}input`}
        onChange={(e) => {
          onChange(e);
        }}
        type="checkbox"
        checked={checked}
        className={`appearance-none w-6 h-6 cursor-pointer text-white-700 bg-slate-100 border-slate-300 rounded-md checked:bg-blue-700 ring-2 ring-slate-200 focus:ring-2 focus:ring-blue-700 my-2 ${inputClassName}`}
      />

      <label
        id={`${labelValue}label`}
        key={`${labelValue}label`}
        onClick={(e) => {}}
        htmlFor={`${labelValue}input`}
        className={`ml-2 text-sm text-slate-900 font-semibold ${labelClassName}`}
      >
        {labelValue}
      </label>
      {checked && (
        <div
        className="absolute w-4 h-4 currentColor cursor-pointer"
        onClick={(e) => {
          onChange(e); // You can call your onChange function here if needed
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="white"
          className="absolute mx-1 w-4 h-4 currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M5 13l4 4L19 7"
          />
        </svg>
        </div>
  )}
    </div>
  );
};
export default CheckBox;
