import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
const Logo = () => {
  const [logoSize, setLogoSize] = useState(200); // Initial logo size in pixels

  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    // Function to handle scroll event
    const handleScroll = () => {
      const scrolled = window.scrollY; // Get the scroll position
      const newSize = scrolled > 200 ? 180 : 200; // Adjust the size based on scroll
      setLogoSize(newSize); // Update the logo size state
    };

    // Add the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // useEffect(() => {
  //   const onScroll = () => setScrollY(window.scrollY);
  //   window.removeEventListener("scroll", onScroll);
  //   window.addEventListener("scroll", onScroll, { passive: true });
  //   return () => window.removeEventListener("scroll", onScroll);
  // }, []);

  return (
    <Link to="/" className={`${scrollY <= 20 ? "py-5" : "py-4"}`}>
      <span className="sr-only">Lost And Found</span>
      <img
        // className={`${scrollY <= 20 ? "h-11" : "h-8"} w-auto`}
        // src="/lostAndfound-logo.png"
        src="/logo_vw.svg"
        alt="Logo"
        style={{ width: `${logoSize}px`, height: "auto" }}
      />
    </Link>
  );
};
export default Logo;
