import React, { useEffect, useState } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { getContactData } from "../components/utility";
import { lambda_end_point } from "../config";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useFetch } from "use-http";
import { useToasts } from "react-toast-notifications";
import { Loader, LoadingIcon } from "../icons";
import { BsCheck2 } from "react-icons/bs";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import ErrorMessage from "../components/error_message";
import FileCta from "../components/file_cta";

function Contact() {
  const [searchParams] = useSearchParams();
  const { addToast } = useToasts();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [contactContent, setContactContent] = useState({});
  const [info, setInfo] = useState({});
  const [err, setErrors] = useState({});
  const { post, response, error } = useFetch(lambda_end_point, {
    cachePolicy: "no-cache",
    "Content-Type": "application/json",
  });
  useEffect(() => {
    const data = getContactData();
    setContactContent(data);
  }, []);
  const validate = (information) => {
    try {
      const reqFields = [
        "first_name",
        "last_name",
        "email",
        "message",
        "phone_number",
      ];
      const errors = {};
      reqFields.forEach((req) => {
        if (information[req]?.trim() === "" || !information[req]) {
          errors[req] = `Please enter ${req?.replace("_", " ")}`;
        }
      });
      //phone validataion
      if (
        information["phone_number"] &&
        !isPossiblePhoneNumber(information["phone_number"])
      ) {
        errors["phone_validation"] = "Please enter a valid phone number";
      }
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      // email validation
      if (information["email"]) {
        if (information["email"].match(emailRegex)) {
        } else {
          errors["email_validation"] = "Please enter a valid email address";
        }
      }
      if (Object.keys(errors).length > 0) {
        throw errors;
      } else {
        return true;
      }
    } catch (e) {
      return { status: "error", error: e };
    }
  };
  const handleInputChange = async (e, field) => {
    const information = { ...info };
    const inputValue = e.target.value;
    // Define a regular expression pattern that allows letters and specific special characters
    const pattern = /^[a-zA-Z0-9 ,.+@-]*$/;
    // Check if the input value matches the pattern
    if (pattern.test(inputValue)) {
      setInfo({
        ...info,
        [field.formid]: e.target.value,
      });
      information[field?.formid] = e.target.value;
    }
    const valid = await validate(information);
    if (
      valid?.error &&
      Object?.keys(valid?.error)?.length > 0 &&
      Object.keys(err).length > 0
    ) {
      setErrors({ ...valid?.error });
    } else {
      setErrors({});
    }
  };
  const handleContact = async () => {
    try {
      setLoading(true);
      const valid = await validate(info);
      setErrors({ ...valid?.error });
      if (valid?.error) {
        setErrors({ ...valid?.error });
      } else {
        const send = await post(`contact/support`, {
          ...info,
        });
        if (send?.status === "ok") {
          setInfo({});
          navigate("?success=true");
        } else {
          throw send?.error;
        }
      }
    } catch (e) {
      addToast(
        `An error occurred while making the inquiry. ${e?.errors[0]?.message}`,
        {
          appearance: "error",
        }
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="">
      <Header />
      {searchParams.get("success") === "true" ? (
        <div className="my-40 overflow-hidden ">
          <div className="relative  p-4 w-full max-w-lg h-full md:h-auto m-auto">
            <div className="relative p-4 text-center shadow-blue-100 rounded-lg shadow sm:p-5">
              <div className="w-12 h-12 rounded-full bg-green-100 flex items-center justify-center mx-auto mb-3.5">
                <BsCheck2 color="green" size={30} />
              </div>

              {/* <p className="mb-2 text-sm font-semibold text-slate-900">
                Successfully created a report
              </p> */}
              <p className="my-8 text-xs">
                Thank you for reaching out! Your message has been successfully
                submitted. Our support team will review your inquiry and will
                get back to you shortly. We appreciate your patience.
              </p>
              <p className="mt-4 text-sm text-slate-900"> Best regards,</p>
              <p className="mb-4 text-sm text-slate-900">Travel Concierge</p>
            </div>
          </div>
        </div>
      ) : (
        <div className="md:pt-0 pt-16">
          <div className="bg-gradient-to-b from-blue-950 to-blue-900 md:pt-32 text-4xl md:text-5xl text-white font-bold py-8">
            {contactContent.title}
            <p className="text-sm pt-8 font-semibold">
              Address: {contactContent.address}
            </p>
            <p className="text-sm font-semibold">
              Phone: {contactContent.phone}
            </p>
          </div>
          <FileCta/>
          <div className="bg-slate-200">
            <div className="text-center pt-16 pb-8 w-11/12 md:w-9/12 mx-auto">
              <p className="pb-8">{contactContent.subContent}</p>
              <p className="pb-8">
                <strong>{contactContent.note}</strong>
              </p>
              <div className="py-8 md:w-8/12 mx-auto text-left">
                {contactContent.formFields &&
                  contactContent.formFields.length > 0 && (
                    <div className="grid md:grid-cols-2 md:gap-6">
                      {contactContent.formFields.map((field, index) => (
                        <div
                          key={index}
                          className={`relative z-0 w-full mb-6 group ${
                            field.id === "floating_claimno" ||
                            field.id === "floating_message"
                              ? "md:col-span-2"
                              : ""
                          }`}
                        >
                          {field.type === "textarea" ? (
                            <textarea
                              onChange={(e) => handleInputChange(e, field)}
                              rows="3"
                              type={field.type}
                              name={field.formid}
                              id={field.formid}
                              className={`block py-2.5  px-0 w-full text-sm text-slate-900 bg-transparent border-0 border-b-2 border-slate-200 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer ${
                                field.id === "floating_claimno" ||
                                field.id === "floating_message"
                                  ? "w-full"
                                  : ""
                              }`}
                              placeholder={field.placeholder}
                              required={field?.required}
                            />
                          ) : (
                            <input
                              onChange={(e) => handleInputChange(e, field)}
                              type={field?.type}
                              name={field?.formid}
                              id={field?.formid}
                              className={`block py-2.5  px-0 w-full text-sm text-slate-900 bg-transparent border-0 border-b-2 border-slate-200 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer ${
                                field.id === "floating_claimno" ||
                                field.id === "floating_message"
                                  ? "w-full"
                                  : ""
                              }`}
                              placeholder={field?.placeholder}
                              required={field?.required}
                            />
                          )}
                          <label
                            htmlFor={field?.id}
                            className="peer-focus:font-medium absolute text-sm text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                          >
                            {field?.name}
                            {field?.required ? "*" : ""}
                          </label>
                          {field?.id === "floating_phone" ? (
                            <ErrorMessage
                              message={err && err["phone_validation"]}
                              id={`err_phone_validation`}
                            />
                          ) : null}
                          {field?.id === "floating_email" ? (
                            <ErrorMessage
                              message={err && err["email_validation"]}
                              id={`err_email_validation`}
                            />
                          ) : null}
                          <ErrorMessage
                            message={err && err[field?.formid]}
                            id={`err_${field?.formid}`}
                          />
                        </div>
                      ))}
                    </div>
                  )}
                <div className="flex space-y-4 px-8 justify-center mt-8">
                  {contactContent?.buttons &&
                    contactContent?.buttons.map((button, index) => (
                      <button
                        title={
                          !info?.first_name ||
                          !info?.last_name ||
                          !info?.email ||
                          !info?.message ||
                          !info?.phone_number
                            ? "Fill all required fields"
                            : "Submit"
                        }
                        onClick={async (e) => {
                          e.preventDefault();
                          handleContact();
                        }}
                        key={index}
                        type="submit"
                        className={` flex align-baseline space-x-1 ${
                          Object?.keys(err)?.length > 0 ||
                          !info?.first_name ||
                          !info?.last_name ||
                          !info?.email ||
                          !info?.message ||
                          !info?.phone_number
                            ? "bg-blue-500 "
                            : "bg-blue-600 hover:bg-blue-800 hover:-translate-y-1 hover:shadow-2xl  hover:scale-105 duration-300"
                        }  cursor-pointer  text-white font-bold py-4 px-8 rounded uppercase text-lg transition ease-in-out delay-150   `}
                      >
                        {" "}
                        {button.text}
                        {loading ? (
                          <LoadingIcon className="h-10 rounded-full text-blue-200 w-32" />
                        ) : null}
                      </button>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
}

export default Contact;
