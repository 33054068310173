import { useEffect, useState } from "react";
import TextArea from "../textarea";
import DisplaySchema from "./display_schema";
import ErrorMessage from "../error_message";
import UploadImages from "./image_uploader";
import { GetCategoryByPKQuery } from "../../queries";
import { useQuery } from "urql";
import SelectCategory from "../select_inputs/select_category";
import Typography from "../typography";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/20/solid";
import { LoadingIcon } from "../../icons";
import { RiDeleteBin6Fill } from "react-icons/ri";

// add images to report category table --done
// on change /remove add to report_category table
// no need validation

// progress tracking
// store progress tracking in the same way you store report category
// hence in an array
// [[{progress:},{progress:}],[],[],[]]
// [[{}],[{}],[{}]]
// display it accordingly

const PanelContents = ({
  tempItemData,
  handleUpdateCatAndDescription,
  errors,
  categoryError,
  categoryData,
  categoryFetching,
  handleUpdateData,
  report,
  progress,
  setProgress,
  handleUpdateImage,
  id,
  images,
  setImages,
  setTempSchema,
  setTempError,
}) => {
  const removeImageFromMainArray = async (index) => {
    try {
      const reportImages = tempItemData?.images ?? [];
      // remove from progress
      //  [[{progress:},{progress:}],[],[],[]]
      const mainProgress = [...progress];
      const subProgress = mainProgress[id];
      subProgress?.splice(index, 1);
      mainProgress.splice(id, 1, subProgress);
      setProgress(mainProgress);
      // remove from imagelist
      //[[{},{},{}],[{}],[]]
      const mainImageList = [...images];
      const subImagelist = mainImageList[id];
      subImagelist?.splice(index, 1);
      mainImageList?.splice(id, 1, subImagelist);
      console.log(mainImageList, "main image list");
      setImages(mainImageList);
      // remove from main array
      const reportImg = [...reportImages];
      reportImg?.splice(index, 1);
      handleUpdateImage([...reportImg]);
    } catch (e) {
      console.log(e, "remove images from main array");
    }
  };
  return (
    <>
      <div className="grid grid-cols-1 w-full">
        <SelectCategory
          defaultMenuIsOpen={
            id === undefined && !tempItemData?.category_id ? true : false
          }
          values={
            tempItemData?.category_id?.trim() === ""
              ? null
              : tempItemData?.category_id
          }
          onChange={(e) => {
            if (tempItemData?.category_id !== e[0]?.id) {
              if (id !== undefined) {
                removeImageFromMainArray(id);
              }

              if (id === undefined) {
                setImages([]);
                setProgress([]);
                setTempSchema([]);
              }
              handleUpdateCatAndDescription({
                category_id: e[0]?.id === "Other" ? null : e[0]?.id,
                details: [],
              });
            }
            if (setTempError) {
              setTempError(false);
            }
          }}
          classnames={"w-full"}
          labelValue={"Item Category "}
        />
        <ErrorMessage
          condition={errors && errors["itemCategory"]}
          message={errors && errors["itemCategory"]}
        />
        <div />
      </div>
      {tempItemData?.category_id?.trim() === "" ||
      tempItemData?.category_id === undefined ? (
        <div className="text-center text-xs font-semibold md:text-sm mt-4">
          Please select a category first
        </div>
      ) : tempItemData?.category_id === null ? (
        <div>
          {" "}
          <TextArea
            cols={6}
            value={tempItemData?.description}
            onChange={(e) => {
              handleUpdateCatAndDescription({
                ...tempItemData,
                description: e.target.value,
                details: [],
              });
            }}
            className={"w-full p-2"}
            placeholder={"Describe your item"}
          />
          <ErrorMessage
            condition={errors && errors["itemDescription"]}
            message={errors && errors["itemDescription"]}
          />
        </div>
      ) : categoryError ? (
        <div className="text-center text-xs md:text-sm font-semibold">
          Error Fetching Schema
        </div>
      ) : categoryFetching ? (
        <div className="text-center text-xs md:text-sm font-semibold">
          Loading...{" "}
        </div>
      ) : (
        <>
          <div className={`grid grid-cols-1 gap-x-4 md:grid-cols-2`}>
            {categoryData?.categories_by_pk?.schema?.map((s, i) => {
              return (
                <DisplaySchema
                  key={i}
                  lostItemDetails={tempItemData?.details}
                  schema={s}
                  i={i}
                  handleUpdateData={handleUpdateData}
                  errors={errors}
                />
              );
            })}
          </div>
        </>
      )}
      <>
        {tempItemData?.category_id !== undefined &&
        (tempItemData?.category_id === null ||
          categoryData?.categories_by_pk?.schema) ? (
          <>
            <Typography
              title={
                "Add images of your lost item or location tracking (Optional)"
              }
            />
            <UploadImages
              report={report}
              progress={progress}
              setProgress={setProgress}
              handleUpdateImage={handleUpdateImage}
              reportImages={tempItemData?.images ?? []}
              id={id}
              images={images}
              setImages={setImages}
              removeImageFromMainArray={removeImageFromMainArray}
            />
          </>
        ) : (
          <></>
        )}
      </>
      {/* {id !== undefined && data?.length > 1 ? (
        <button
          disabled={uploading}
          className="rounded-md w-48 disabled:opacity-30 disabled:cursor-not-allowed py-3 px-8 bg-red-600 text-white font-bold text-base flex justify-center hover:bg-red-800 hover:shadow-md"
          onClick={(e) => {
            e.preventDefault();
            if (!uploading) {
              removeFromMainArray(id);
            }
          }}
        >
          {uploading ? (
            <LoadingIcon className="font-bold" height={25} width={25} />
          ) : (
            "Remove Item"
          )}{" "}
        </button>
      ) : (
        <></>
      )} */}
    </>
  );
};
export const MultipleItems = ({
  errors,
  id,
  schema,
  setSchema,
  tempItemData,
  setTempItemData,
  report,
  handleSetReport,
  setTempSchema,
  validate,
  progress,
  setProgress,
  images,
  setImages,
  removeFromMainArray,
  setTempError,
  uploading,
  deleteButton,
}) => {
  const [categoryResults, reexecuteCategoryQuery] = useQuery({
    query: GetCategoryByPKQuery,
    variables: {
      id: tempItemData?.category_id,
    },
    pause: true,
    requestPolicy: "network-only",
  });

  const {
    data: categoryData,
    fetching: categoryFetching,
    error: categoryError,
  } = categoryResults;

  // update the data of displayschema
  const handleUpdateData = async (details) => {
    let tempMainArray = [];
    if (id !== undefined) {
      tempMainArray = [...report?.report_categories?.data];
    }
    const dataCopy =
      id !== undefined
        ? [...tempMainArray[id]?.details]
        : [...tempItemData?.details];
    const findIndex = dataCopy.findIndex((d) => d?.title === details?.title);
    if (findIndex >= 0) {
      dataCopy[findIndex] = details;
    } else {
      dataCopy?.push(details);
    }
    if (id !== undefined) {
      tempMainArray?.splice(id, 1, { ...tempMainArray[id], details: dataCopy });
      handleSetReport({
        report_categories: { data: [...tempMainArray] },
      });
    } else {
      setTempItemData({ ...tempItemData, details: dataCopy });
    }
  };
  // update images to report_categories
  const handleUpdateImage = async (images) => {
    let tempMainArray = [];
    if (id !== undefined) {
      console.log(images, "images");
      tempMainArray = [...report?.report_categories?.data];
      tempMainArray?.splice(id, 1, {
        ...tempMainArray[id],
        images: [...images],
      });

      handleSetReport({
        report_categories: { data: [...tempMainArray] },
      });
    } else {
      setTempItemData({ ...tempItemData, images: images });
    }
  };
  //update category and description
  const handleUpdateCatAndDescription = async (data) => {
    let tempMainArray = [];
    if (id !== undefined) {
      tempMainArray = [...report?.report_categories?.data];
      tempMainArray[id] = data;
      handleSetReport({
        report_categories: { data: [...tempMainArray] },
      });
    } else {
      setTempItemData({ ...data });
    }
  };
  useEffect(() => {
    // if theres an id splice and add , if not just push
    if (categoryData?.categories_by_pk?.schema) {
      if (id !== undefined) {
        const tempSchema = [...schema];
        tempSchema?.splice(id, 1, categoryData?.categories_by_pk?.schema);
        setSchema(tempSchema);
      } else {
        if (setTempSchema) {
          setTempSchema(categoryData?.categories_by_pk?.schema);
        }
      }
    }
  }, [categoryData]);

  useEffect(() => {
    if (
      tempItemData?.category_id !== "Other" &&
      tempItemData?.category_id?.trim() !== "" &&
      tempItemData?.category_id
    ) {
      reexecuteCategoryQuery();
    }
    if (
      tempItemData?.category_id !== "Other" ||
      tempItemData?.category_id !== null
    ) {
      if (id !== undefined) {
        const tempMainSchema = [...schema];
        tempMainSchema?.splice(id, 1, []);
        setSchema(tempMainSchema);
      } else {
        setTempSchema([]);
      }
    }
  }, [tempItemData?.category_id]);
  useEffect(() => {
    validate(id);
  }, [tempItemData]);
  return (
    <>
      <Disclosure>
        {({ open }) => (
          <div
            className={`bg-slate-50 px-2 ${
              open && !id
                ? "border-4 border-solid rounded-md border-[#d7eeff]"
                : ""
            }`}
          >
            <Disclosure.Button className="flex w-full border-b-2  align-middle items-center justify-between  bg-slate-50  py-2 text-left text-sm font-medium text-gray-900 hover:bg-gray-50 ">
              <div className="w-full">
                <div className="font-bold cursor-text bg-slate-50">
                  {" "}
                  {id !== undefined ? ` Item ${id + 1}` : "Add New Item"}
                </div>
                <div
                  className={`appearance-none  font-semibold cursor-text  rounded-lg px-2 py-2  text-slate-700 text-sm h-12 w-full `}
                >
                  {tempItemData?.category_id === "Other" ||
                  tempItemData?.category_id === null
                    ? "Other"
                    : tempItemData?.category_id === undefined
                    ? "Select Item Category"
                    : categoryData?.categories_by_pk?.name}
                </div>
              </div>

              <div className="flex gap-x-2 mx-2 align-middle items-center">
                {deleteButton ? (
                  <div className="hover:bg-gray-200 rounded-full  p-2">
                    <button
                      disabled={uploading}
                      className="rounded-md  disabled:opacity-30 disabled:cursor-not-allowed  text-base flex justify-center hover:bg-slate-50 hover:shadow-md"
                      onClick={(e) => {
                        e.preventDefault();
                        if (!uploading) {
                          if (id) {
                            removeFromMainArray(id);
                          } else removeFromMainArray();
                        }
                      }}
                    >
                      <RiDeleteBin6Fill />
                    </button>
                  </div>
                ) : (
                  <></>
                )}{" "}
                <ChevronUpIcon
                  className={`${
                    open ? "rotate-180 transform" : ""
                  } h-5 w-5 text-gray-900 mx-2`}
                />
              </div>
            </Disclosure.Button>
            <Disclosure.Panel className=" pb-2 pt-4 text-sm text-gray-500">
              <PanelContents
                id={id}
                categoryData={categoryData}
                categoryError={categoryError}
                categoryFetching={categoryFetching}
                errors={errors}
                handleUpdateCatAndDescription={handleUpdateCatAndDescription}
                handleUpdateData={handleUpdateData}
                handleUpdateImage={handleUpdateImage}
                images={images}
                progress={progress}
                report={report}
                setImages={setImages}
                setProgress={setProgress}
                tempItemData={tempItemData}
                setTempSchema={setTempSchema}
                setTempError={setTempError}
              />
            </Disclosure.Panel>
          </div>
        )}
      </Disclosure>
    </>
  );
};
