import React, { useEffect } from "react";
import { useQuery } from "urql";
import { useSearchParams } from "react-router-dom";
import { GetReportDataQuery } from "../queries";
import { MdVerified } from "react-icons/md";
import { getBenefitsData } from "./utility";
import Typography from "./typography";

const Benefits = () => {
  const [param] = useSearchParams();
  const benefitsData = getBenefitsData();
  const [configResults, reexecuteConfigQuery] = useQuery({
    query: GetReportDataQuery,
    pause: true,
  });

  const {
    data: configsData,
    fetching: configsFetching,
    error: configsError,
  } = configResults;

  useEffect(() => {
    reexecuteConfigQuery({ requestPolicy: "network-only" });
  }, []);
  return (
    <div className="w-full appearance-none font-medium cursor-text bg-slate-50 rounded-lg p-4 my-1 border-2 border-slate-200 text-slate-700 text-sm">
      <div className="benefitsHeading flex justify-between pb-4">
        <div>
          <Typography title={benefitsData.title} />
          {/* <p className="-mt-1">{benefitsData.time}</p> */}
        </div>
        {/* TODO - Make this dynamic */}
        <p className="text-xl font-bold">
          ${configsData?.config_by_pk?.price || "35"}
        </p>
      </div>
      <div className="benefitsBody pb-2">
        <ul>
          {benefitsData.benefitsItems.map((item, index) => (
            <li className="flex items-center space-x-2 py-1" key={index}>
              <div className="w-4">
                <MdVerified className="text-blue-700 mr-1 mt-0" size={18} />
              </div>
              <div className="font-semibold">{item.name}</div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
export default Benefits;
