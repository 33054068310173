import React, { useEffect, useState } from 'react';

const NumberUpdater = () => {
  const [currentNumber, setCurrentNumber] = useState(280);

  useEffect(() => {
    // Retrieve current number and last update date from localStorage
    const savedNumber = parseInt(localStorage.getItem('currentNumber'), 10) || 280;
    const lastUpdateDate = localStorage.getItem('lastUpdateDate') || null;
    const today = new Date().toDateString();

    setCurrentNumber(savedNumber);

    // If the last update date is not today, reset the daily increment count
    if (lastUpdateDate !== today) {
      localStorage.setItem('dailyIncrement', '0');
      localStorage.setItem('lastUpdateDate', today);
    }

    // Function to update the number with a random value between 1 and 5 daily, but not exceeding 5 in total
    const updateNumber = () => {
      const dailyIncrement = parseInt(localStorage.getItem('dailyIncrement'), 10) || 0;
      if (dailyIncrement < 5) {
        const remainingIncrement = 5 - dailyIncrement;
        const randomIncrement = Math.floor(Math.random() * remainingIncrement) + 1;
        const newNumber = savedNumber + randomIncrement;

        setCurrentNumber(newNumber);
        localStorage.setItem('currentNumber', newNumber.toString());
        localStorage.setItem('dailyIncrement', (dailyIncrement + randomIncrement).toString());
      }
    };

    // Schedule random updates during the day
    const scheduleRandomUpdate = () => {
      const randomHours = Math.floor(Math.random() * 23) + 1; // Random hour between 1 and 23
      const randomMinutes = Math.floor(Math.random() * 60); // Random minute
      const now = new Date();
      const nextUpdate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), randomHours, randomMinutes, 0);
      const delay = nextUpdate - now;

      if (delay > 0) {
        setTimeout(() => {
          updateNumber();
          scheduleRandomUpdate(); // Schedule the next update
        }, delay);
      }
    };

    scheduleRandomUpdate();

    // Cleanup the timeouts on component unmount
    return () => {
      localStorage.removeItem('dailyIncrement');
    };
  }, []);

  return (
    <div>{currentNumber}</div>
  );
};

export default NumberUpdater;