import React, { useEffect, useState } from "react";
import { gql, useQuery } from "urql";
import { ListAirlinesQuery } from "../../queries";
import SelectBox from "../select";
import TextArea from "../textarea";
const SelectAirline = ({
  classnames,
  labelValue,
  onChange,
  values,
  handleInput,
  airlineDescription,
}) => {
  const [options, setOptions] = useState([]);

  const [airlineResults, reexecuteAirlineQuery] = useQuery({
    query: ListAirlinesQuery,
  });

  const {
    data: airlinesData,
    fetching: airlinesFetching,
    error: airlinesError,
  } = airlineResults;
  useEffect(() => {
    if (airlinesData?.airlines?.length > 0) {
      setOptions([...airlinesData?.airlines, { id: "Other", name: "Other" }]);
    }
  }, [airlineResults]);
  return (
    <>
      <SelectBox
        loading={airlinesFetching}
        noDataLabel={airlinesFetching ? "Loading" : "Select 'Other'"}
        values={
          values?.value
            ? values?.value?.name
              ? [{ ...values?.value }]
              : [{ name: "Other", id: "Other" }]
            : []
        }
        onChange={onChange}
        options={options}
        classnames={classnames}
        labelValue={labelValue}
      />
      {typeof values?.value === "string" ? (
        <TextArea
          cols={6}
          value={values?.value?.name ? "" : values?.value}
          onChange={(e) => {
            handleInput(e);
          }}
          className={"w-full p-2 md:col-span-2"}
          placeholder={"Airline Name"}
        />
      ) : null}
    </>
  );
};
export default SelectAirline;
