import { useEffect } from "react";
import InputBox from "../input";
import CheckBox from "../checkbox";
import ErrorMessage from "../error_message";
import SelectBox from "../select";
import Select from "react-dropdown-select";
const DisplaySchema = ({
  schema,
  i,
  data,
  handleUpdateData,
  lostItemDetails,
  errors,
}) => {
  useEffect(() => {
    if (schema?.type === "boolean") {
      if (!lostItemDetails?.find((l) => l?.title !== schema?.name)) {
        handleUpdateData({
          title: schema?.name,
          value: false,
        });
      }
    }
  }, []);

  return (
    <>
      {schema?.type === "text" ? (
        <div>
          <InputBox
            className={"w-full"}
            key={i}
            value={
              lostItemDetails?.find((l) => l?.title === schema?.name)?.value
            }
            placeholder={`${schema?.name}${schema?.required ? " *" : ""}`}
            onChange={(e) => {
              handleUpdateData({
                title: schema?.name,
                value: e.target.value,
              });
            }}
          />
          <ErrorMessage
            condition={errors && errors[schema?.name]}
            message={errors && errors[schema?.name]}
          />
        </div>
      ) : schema?.type === "enum" ? (
        <div>
          <SelectBox
            classnames={"w-full"}
            options={schema?.enum?.map((op) => {
              return { value: op, title: schema?.name };
            })}
            labelField={"value"}
            labelValue={`${schema?.name}${schema?.required ? " *" : ""}`}
            onChange={(e) => {
              if (e[0]?.value !== schema?.name) {
                handleUpdateData({
                  title: schema?.name,
                  value: e[0]?.value,
                });
              }
            }}
            values={
              lostItemDetails?.find((l) => l?.title === schema?.name)?.value
                ? [lostItemDetails?.find((l) => l?.title === schema?.name)]
                : []
            }
            valueField={"value"}
          />
          {/* <select
            // placeholder={schema?.name}
            onChange={(e) => {
              if (e.target.value !== schema?.name) {
                handleUpdateData({
                  title: schema?.name,
                  value: e.target.value,
                });
              }
            }}
            className={`h-10 outline-none w-full text-lg px-2 my-1 border-none text-gray-700 bg-white`}
          >
            <option className="text-gray-300"> {schema?.name} </option>
            {schema?.enum?.map((op) => (
              <option
                selected={lostItemDetails?.find((l) => l?.value === op)?.value}
                key={op}
                id={op}
                value={op}
              >
                {op}
              </option>
            ))}
          </select> */}
          <ErrorMessage
            condition={errors && errors[schema?.name]}
            message={errors && errors[schema?.name]}
          />
        </div>
      ) : (
        schema?.type === "boolean" && (
          <div>
            <CheckBox
              checked={
                lostItemDetails?.find((l) => l?.title === schema?.name)?.value
              }
              onChange={(e) => {
                handleUpdateData({
                  title: schema?.name,
                  value: e?.target?.checked,
                });
              }}
              className=""
              labelValue={`${schema?.name}${schema?.required ? " *" : ""}`}
              key={i}
            />
            <ErrorMessage
              condition={errors && errors[schema?.name]}
              message={errors && errors[schema?.name]}
            />
          </div>
        )
      )}
    </>
  );
};
export default DisplaySchema;
