import React, { useEffect, useState } from "react";
import { gql, useQuery } from "urql";
import { ListThemeParkQuery } from "../../queries";
import SelectBox from "../select";
import TextArea from "../textarea";
const SelectThemePark = ({
  classnames,
  labelValue,
  onChange,
  values,
  handleInput,
}) => {
  const [options, setOptions] = useState([]);

  const [themeParkResults, reexecuteThemeParkQuery] = useQuery({
    query: ListThemeParkQuery,
    pause: true,
  });
  const {
    data: themeParksData,
    fetching: themeParksFetching,
    error: themeParksError,
  } = themeParkResults;
  useEffect(() => {
    if (themeParksData?.theme_park?.length > 0) {
      setOptions([
        ...themeParksData?.theme_park,
        { id: "Other", name: "Other" },
      ]);
    }
  }, [themeParksData]);

  useEffect(() => {
    reexecuteThemeParkQuery({ requestPolicy: "network-only" });
  }, []);
  return (
    <>
      {" "}
      <SelectBox
        loading={themeParksFetching}
        noDataLabel={themeParksFetching ? "Loading" : "Select 'Other'"}
        valueField={"name"}
        values={
          values?.value
            ? values?.value?.name
              ? [{ ...values?.value }]
              : [{ name: "Other", id: "Other" }]
            : []
        }
        onChange={onChange}
        options={options}
        classnames={classnames}
        labelValue={labelValue}
      />
      {typeof values?.value === "string" ? (
        <TextArea
          cols={6}
          value={values?.value?.name ? "" : values?.value}
          onChange={(e) => {
            handleInput(e);
          }}
          className={"w-full p-2 md:col-span-2"}
          placeholder={"Theme Park Name*"}
        />
      ) : null}
    </>
  );
};
export default SelectThemePark;
