import React, { useEffect, useState } from "react";
import SelectBox from "../select";
import InputBox from "../input";
import SelectAirport from "../select_inputs/select_airport";
import SelectCarRental from "../select_inputs/select_car_rental";
import ErrorMessage from "../error_message";
const LocationRentalCar = ({ locationDetails, handleSetReport, errors }) => {
  const [location, setLocation] = useState([...locationDetails]);

  const handleLocationDetails = async (key, data) => {
    const dataCopy = [...location];
    const findIndex = dataCopy.findIndex((d) => d?.title === key);
    try {
      if (findIndex >= 0) {
        dataCopy[findIndex] = { title: key, value: data };
      } else {
        dataCopy.push({ title: key, value: data });
      }
      console.log(dataCopy, "d copy");
      setLocation(dataCopy);
    } catch (e) {}
  };
  const handleRemoveItem = async (key) => {
    try {
      const updatedArray = location.filter((item) => item.title !== key);
      setLocation(updatedArray);
    } catch (e) {}
  };
  useEffect(() => {
    handleSetReport({ location_details: location });
  }, [location]);
  /*
  name of company (required),
  Drop off location (req)
  Rental agreement no (opt) 
  Vehicle number (opt)
  Vehicle Tag/Plate (optional)
  Make / model / color (req)
*/
  return (
    <div className="">
      <div className="mt-4">
        {/* at rental car */}
        <div className="grid gap-x-4 grid-cols-1 md:grid-cols-2">
          <div>
            <SelectAirport
              classnames={""}
              labelValue={"Departure Airport"}
              values={
                locationDetails?.find(
                  (l) => l?.title === "departure airport"
                ) || {}
              }
              onChange={(e) => {
                if (!e[0]?.name || e?.length < 1) {
                  handleRemoveItem("departure airport");
                } else if (e[0]?.name !== "Other") {
                  handleLocationDetails("departure airport", {
                    name: e[0]?.name,
                    id: e[0]?.id,
                    inventory: "airport",
                  });
                } else {
                  handleLocationDetails("departure airport", "");
                }
              }}
              handleInput={(e) => {
                handleLocationDetails("departure airport", e.target.value);
              }}
            />
            <ErrorMessage
              message={errors && errors["car rental agency"]}
              condition={errors && errors["car rental agency"]}
            />
          </div>
          {/* car rental agency */}
          <div>
            <SelectCarRental
              values={
                locationDetails?.find(
                  (l) => l?.title === "car rental agency"
                ) || {}
              }
              onChange={(e) => {
                if (!e[0]?.name || e?.length < 1) {
                  handleRemoveItem("car rental agency");
                } else if (e[0]?.name !== "Other") {
                  handleLocationDetails("car rental agency", {
                    name: e[0]?.name,
                    id: e[0]?.id,
                    inventory: "car_rental",
                  });
                } else {
                  handleLocationDetails("car rental agency", "");
                }
              }}
              handleInput={(e) => {
                handleLocationDetails("car rental agency", e?.target?.value);
              }}
              classnames={""}
              labelValue={"Car rental agency *"}
            />{" "}
            <ErrorMessage
              message={errors["car rental agency"]}
              condition={errors["car rental agency"]}
            />
          </div>
          {/*name of the company */}
          <div>
            <InputBox
              className={"w-full"}
              placeholder={"Company Name *"}
              value={
                locationDetails?.find((l) => l?.title === "Company Name")
                  ?.value || ""
              }
              onChange={(e) => {
                handleLocationDetails("Company Name", e.target.value);
              }}
            />
            <ErrorMessage
              condition={errors && errors["Company Name"]}
              message={errors && errors["Company Name"]}
            />
          </div>
          {/* Drop off location */}
          <div>
            <InputBox
              className={"w-full"}
              placeholder={"Drop Off Location *"}
              value={
                locationDetails?.find((l) => l?.title === "Drop Off Location")
                  ?.value || ""
              }
              onChange={(e) => {
                handleLocationDetails("Drop Off Location", e.target.value);
              }}
            />
            <ErrorMessage
              condition={errors && errors["Drop Off Location"]}
              message={errors && errors["Drop Off Location"]}
            />
          </div>
          {/* Rental agreement no */}
          <div>
            <InputBox
              className={"w-full"}
              placeholder={"Rental agreement no"}
              value={
                locationDetails?.find((l) => l?.title === "Rental agreement no")
                  ?.value || ""
              }
              onChange={(e) => {
                handleLocationDetails("Rental agreement no", e.target.value);
              }}
            />
            <ErrorMessage
              condition={errors && errors["Rental agreement no"]}
              message={errors && errors["Rental agreement no"]}
            />
          </div>
          {/* Vehicle number */}
          <div>
            <InputBox
              className={"w-full"}
              placeholder={"Vehicle number"}
              value={
                locationDetails?.find((l) => l?.title === "Vehicle number")
                  ?.value || ""
              }
              onChange={(e) => {
                handleLocationDetails("Vehicle number", e.target.value);
              }}
            />
            <ErrorMessage
              condition={errors && errors["Vehicle number"]}
              message={errors && errors["Vehicle number"]}
            />
          </div>
          {/* Vehicle tag/plate */}
          <div>
            <InputBox
              className={"w-full"}
              placeholder={"Vehicle Tag/Plate"}
              value={
                locationDetails?.find((l) => l?.title === "Vehicle Tag/Plate")
                  ?.value || ""
              }
              onChange={(e) => {
                handleLocationDetails("Vehicle Tag/Plate", e.target.value);
              }}
            />
            <ErrorMessage
              condition={errors && errors["Vehicle Tag/Plate"]}
              message={errors && errors["Vehicle Tag/Plate"]}
            />
          </div>
          {/* Make / model / color */}
          <div>
            <InputBox
              className={"w-full"}
              placeholder={"Make / model / color *"}
              value={
                locationDetails?.find(
                  (l) => l?.title === "Make / model / color"
                )?.value || ""
              }
              onChange={(e) => {
                handleLocationDetails("Make / model / color", e.target.value);
              }}
            />
            <ErrorMessage
              condition={errors && errors["Make / model / color"]}
              message={errors && errors["Make / model / color"]}
            />
          </div>
          {/* where lost */}
          <div>
            <InputBox
              className={"w-full"}
              placeholder={"Where Lost *"}
              value={
                locationDetails?.find((l) => l?.title === "where you lost it")
                  ?.value || ""
              }
              onChange={(e) => {
                handleLocationDetails("where you lost it", e.target.value);
              }}
            />
            <ErrorMessage
              condition={errors && errors["where you lost it"]}
              message={errors && errors["where you lost it"]}
            />
          </div>

          {/* comments */}
          <div className="grid ">
            <InputBox
              className={"w-full"}
              value={
                locationDetails?.find((l) => l?.title === "comments")?.value ||
                ""
              }
              placeholder={"Additional Info"}
              onChange={(e) => {
                handleLocationDetails("comments", e.target.value);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default LocationRentalCar;
