import React, { useEffect, useState } from "react";
import SelectBox from "../select";
import { Country, State, City } from "country-state-city";

const SelectState = ({
  classnames,
  labelValue,
  onChange,
  values,
  countryCode,
  ...rest
}) => {
  const [allStates, setAllStates] = useState([{ name: "", id: "" }]);
  useEffect(() => {
    const states = State.getStatesOfCountry(countryCode);
    setAllStates([...states]);
  }, [countryCode]);

  return (
    <>
      <SelectBox
        loading={allStates?.length < 2}
        noDataLabel={allStates?.length < 2 ? "Loading" : "No Data"}
        values={values?.value ? [{ name: values?.value }] : []}
        valueField={"name"}
        onChange={onChange}
        options={allStates}
        classnames={classnames}
        labelValue={labelValue}
        labelField={"name"}
        {...rest}
      />
    </>
  );
};
export default SelectState;
