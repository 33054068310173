import { isPossiblePhoneNumber } from "react-phone-number-input";

const requiredFields = {
  ["Rental Car"]: [
    "car rental agency",
    "where you lost it",
    "Company Name",
    "Drop Off Location",
    "Make / model / color",
  ],
  ["Hotel / AirBnB"]: [
    "Hotel / Resort Name",
    "country",
    "city",
    "where you lost it",
  ],
  ["Las Vegas"]: ["name", "where you lost it", "Room Number"],
  ["Theme Park"]: ["name"],
  ["Cruise Ship"]: [
    "cruise line",
    "ship name",
    "port of embarkation",
    "date of embarkation",
  ],
};
export const reqAirport = {
  ["at airport"]: [
    "departure airport",
    "arrival airport",
    "lost airport",
    "airline",
    "flight number",
  ],
  ["on flight"]: [
    "departure airport",
    "arrival airport",
    "lost airport",
    "airline",
    "flight number",
  ],
  ["tsa"]: [
    "departure airport",
    "arrival airport",
    "lost airport",
    "airline",
    "flight number",
  ],
  ["rental car"]: [
    "car rental agency",
    "where you lost it",
    "Company Name",
    "Drop Off Location",
    "Make / model / color",
  ],
};
export const ValidateLocation = async (
  location,
  locationDetails,
  lostTime,
  lostDate
) => {
  try {
    const errors = {};
    if (locationDetails?.length < 1) {
      // if (!isOnChange) {
      errors["locationDetails"] =
        "Please select the place where you lost your item.";
      // } else {
      //   throw "Please select the place where you lost your item.";
      // }
    }
    if (location === "Airport / Airline") {
      const lostPlaceArray = locationDetails?.find(
        (l) => l?.title === "lost place"
      )?.value;
      if (lostPlaceArray?.length < 1) {
        errors["lostPlace"] = "Please Select atleast one place ";
        // throw "Please Select atleast one place where you think your item is lost";
      }

      if (
        locationDetails?.find((l) => l?.title === "departure airport")?.value
          ?.name &&
        locationDetails?.find((l) => l?.title === "arrival airport")?.value
          ?.name &&
        locationDetails?.find((l) => l?.title === "departure airport")?.value
          ?.name ===
          locationDetails?.find((l) => l?.title === "arrival airport")?.value
            ?.name
      ) {
        errors["similar_airport"] =
          "Arrival Airport can't be similar to Departure Airport";
      }
      lostPlaceArray?.forEach((req) => {
        reqAirport[req]?.forEach((reqair) => {
          if (
            !locationDetails?.find((l) => l?.title === reqair) ||
            (typeof locationDetails?.find((l) => l?.title === reqair)?.value ===
              "string" &&
              locationDetails
                ?.find((l) => l?.title === reqair)
                ?.value?.trim() === "")
          ) {
            errors[reqair] = `Please enter ${reqair}`;
            // throw `${reqair} cannot be empty`;
          }
          // else if (reqair === "flight number") {
          //   if (
          //     locationDetails?.find((l) => l?.title === reqair).value?.length <
          //     1
          //   ) {
          //     errors[reqair] = `Please add atleast one ${reqair}`;
          //   }
          // }
        });
      });
    } else {
      if (location === "Hotel / AirBnB") {
        if (
          (locationDetails?.find((l) => l?.title === "country")?.value ===
            "United States" ||
            locationDetails?.find((l) => l?.title === "country")?.value ===
              "Canada") &&
          locationDetails?.find((l) => l?.title === "country")?.value.trim() !==
            ""
        ) {
          if (
            locationDetails?.find((l) => l?.title === "state")?.value.trim() ===
              "" ||
            !locationDetails?.find((l) => l?.title === "state")?.value
          ) {
            errors["state"] = "Please select a state";
            // throw "state cannot be empty";
          }
        }
      }
      requiredFields[location]?.forEach((req) => {
        if (
          (typeof locationDetails?.find((l) => l?.title === req)?.value ===
            "string" &&
            locationDetails?.find((l) => l?.title === req)?.value?.trim() ===
              "") ||
          !locationDetails?.find((l) => l?.title === req)
        ) {
          errors[req] = `Please enter ${req}`;
          // throw `${req} cannot be empty`;
        }
      });
    }

    if (!lostDate) {
      errors["lostDate"] = "Please select the item lost date ";
      // throw "lost date and lost time of the daty cannot be empty";
    }
    if (!lostTime) {
      errors["lostTime"] = "Please select lost time";
    }
    // console.log(Object.keys(errors).length, "length");
    if (Object.keys(errors).length > 0) {
      throw errors;
    } else {
      return true;
    }
    // check if theres anything in error object
    //if so return e
    // return true;
  } catch (e) {
    console.log(e);
    return { error: e };
  }
};

export const validateItemDetails = async (schema, itemDetails) => {
  try {
    const errors = {};
    // data ={category_id:"", details:"",description:""}
    // map details and validate item category and description
    // and then validate using schema and id
    // throw errors with index
    itemDetails?.forEach((details, index) => {
      if (!errors[index]) {
        errors[index] = {};
      }
      if (details?.category_id === undefined) {
        errors[index]["itemCategory"] = "Please select an item category";
      }
      if (details?.category_id === "Other" || details?.category_id === null) {
        if (!details?.description || details?.description?.trim() === "") {
          errors[index]["itemDescription"] =
            "Please enter a description for the lost item ";
          // throw "item description cannot be empty ";
        }
      } else {
        schema[index]?.forEach((s) => {
          if (s?.required) {
            if (
              !details?.details?.find((l) => l?.title === s?.name) ||
              (typeof details?.details?.find((l) => l?.title === s?.name)
                ?.value === "string" &&
                details?.details
                  ?.find((l) => l?.title === s?.name)
                  ?.value?.trim() === "")
            ) {
              errors[index][s?.name] = `Please enter ${s?.name}`;
              // throw `${s?.name} cannot be empty`;
            }
          }
        });
      }
      if (Object.keys(errors[index]).length < 1) {
        delete errors[index];
      }
    });

    if (Object.keys(errors).length > 0) {
      throw errors;
    } else {
      return true;
    }
  } catch (e) {
    return { error: e };
  }
};
//validate email
const validateEmail = (email) => {
  // Regular expression for email validation
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

  if (email.match(emailRegex)) {
    return true;
  } else {
    return { error: "malformed email" };
  }
};
//validate phone number
export const Validate = async (report, schema) => {
  const reqFields = [
    "first_name",
    "last_name",
    "email",
    "city",
    "country",
    "phone_number",
    "zip",
    "address",
  ];
  try {
    // required validation
    // type text
    let errors = {};
    // validate location
    const validateLoc = await ValidateLocation(
      report?.location,
      report?.location_details,
      report?.lost_time_of_the_day,
      report?.lost_date
    );
    if (validateLoc?.error) {
      errors = { ...validateLoc?.error };
    }
    //validate item details
    const validateItem = await validateItemDetails(
      schema,
      report?.item_category,
      report?.lost_item_details,
      report?.item_description
    );
    if (validateItem?.error) {
      errors = { ...errors, ...validateItem?.error };
    }
    reqFields.forEach((req) => {
      if (report[req]?.trim() === "" || !report[req]) {
        errors[req] = `Please enter ${req?.replace("_", " ")}`;
        // throw `${req?.replace("_", " ")} cannot be empty`;
      }
    });
    if (
      report?.country?.trim() !== "" &&
      (report?.country === "United States" || report?.country === "Canada")
    ) {
      if (report?.state?.trim() === "" || !report?.state) {
        errors["state"] = "Please select a state";
        // throw "state cannot be empty";
      }
    }
    if (report["email"]?.trim() !== "") {
      const validate = await validateEmail(report["email"]);

      if (validate?.error) {
        errors["email_validation"] = "Please enter a valid email";
        // throw validate?.error;
      }
    }
    if (
      report["phone_number"] &&
      !isPossiblePhoneNumber(report["phone_number"])
    ) {
      errors["phone_validation"] = "Please enter a valid phone number";
      // throw "malformed phone number";
    }
    if (Object.keys(errors).length > 0) {
      throw errors;
    } else {
      return true;
    }
    // if (!report?.send_sms) {
    //   throw "please allow to send sms";
    // }
    // return {
    //   status: "success",
    // };
  } catch (e) {
    console.log(e);
    return { status: "error", error: e };
  }
};
