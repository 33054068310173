import React, { useState, useEffect } from "react";
import SelectBox from "../select";
import InputBox from "../input";
import SelectCountry from "../select_inputs/select_country";
import SelectState from "../select_inputs/select_state";
import ErrorMessage from "../error_message";

const LocationHotel = ({ handleSetReport, locationDetails, errors }) => {
  const [location, setLocation] = useState([...locationDetails]);
  const [countryCode, setCountryCode] = useState();
  const handleLocationDetails = async (key, data) => {
    const dataCopy = [...location];
    const findIndex = dataCopy.findIndex((d) => d?.title === key);
    try {
      if (findIndex >= 0) {
        dataCopy[findIndex] = { title: key, value: data };
      } else {
        dataCopy.push({ title: key, value: data });
      }
      setLocation(dataCopy);
    } catch (e) {}
  };
  const handleRemoveItem = async (key) => {
    try {
      const updatedArray = location.filter((item) => item.title !== key);
      setLocation(updatedArray);
    } catch (e) {}
  };
  useEffect(() => {
    if (countryCode !== "CA" || countryCode !== "US") {
      const filtered = location?.filter((l) => l?.title !== "state");
      setLocation(filtered);
    }
  }, [countryCode]);
  useEffect(() => {
    handleSetReport({ location_details: location });
  }, [location]);

  return (
    <div className="">
      <div className="mt-4">
        {/* at airport */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4">
          <div className="w-full">
            <InputBox
              className={"w-full"}
              value={
                locationDetails?.find((l) => l?.title === "Hotel / Resort Name")
                  ?.value || ""
              }
              placeholder={"Hotel / Resort Name *"}
              onChange={(e) => {
                handleLocationDetails("Hotel / Resort Name", e.target.value);
              }}
            />
            <ErrorMessage
              condition={errors && errors["Hotel / Resort Name"]}
              message={errors && errors["Hotel / Resort Name"]}
            />
          </div>
          <div>
            <SelectCountry
              classnames={``}
              labelValue={"Country *"}
              onChange={(e) => {
                if (!e[0]?.name || e?.length < 1) {
                  handleRemoveItem("country");
                } else {
                  setCountryCode(e[0]?.isoCode);
                  handleLocationDetails("country", e[0]?.name);
                }
              }}
              values={
                locationDetails?.find((l) => l?.title === "country") || {}
              }
            />
            <ErrorMessage
              condition={errors && errors["country"]}
              message={errors && errors["country"]}
            />
          </div>
          <div>
            <InputBox
              className={"w-full"}
              value={
                locationDetails?.find((l) => l?.title === "city")?.value || ""
              }
              onChange={(e) => {
                handleLocationDetails("city", e.target.value);
              }}
              placeholder={"City *"}
            />
            <ErrorMessage
              condition={errors && errors["city"]}
              message={errors && errors["city"]}
            />
          </div>
          {(countryCode === "US" || countryCode === "CA") && (
            <div>
              {" "}
              <SelectState
                countryCode={countryCode}
                classnames={``}
                labelValue={"State *"}
                onChange={(e) => {
                  if (!e[0]?.name || e?.length < 1) {
                    handleRemoveItem("state");
                  } else {
                    handleLocationDetails("state", e[0].name);
                  }
                }}
                values={
                  locationDetails?.find((l) => l?.title === "state") || {}
                }
              />
              <ErrorMessage
                condition={errors && errors["state"]}
                message={errors && errors["state"]}
              />
            </div>
          )}{" "}
          <div>
            {" "}
            <InputBox
              className={"w-full"}
              placeholder={"Where in hotel *"}
              value={
                locationDetails?.find((l) => l?.title === "where you lost it")
                  ?.value || ""
              }
              onChange={(e) => {
                handleLocationDetails("where you lost it", e.target.value);
              }}
            />
            <ErrorMessage
              condition={errors && errors["where you lost it"]}
              message={errors && errors["where you lost it"]}
            />
          </div>
          <InputBox
            className={"w-full"}
            value={
              locationDetails?.find((l) => l?.title === "comments")?.value || ""
            }
            onChange={(e) => {
              handleLocationDetails("comments", e.target.value);
            }}
            placeholder={"Room Number & Comments"}
          />
        </div>
      </div>
    </div>
  );
};
export default LocationHotel;
