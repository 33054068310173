import React, { useEffect, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { useMutation, useQuery } from "urql";
import { AddReviewMutation, CheckReport } from "../queries";
import { useToasts } from "react-toast-notifications";
import HeaderPayment from "../components/header_payment";
import FooterPayment from "../components/footer_payment";
import { useSearchParams, Link, useParams } from "react-router-dom";
import { BsCheck2 } from "react-icons/bs";
const moods = [
  { name: "Not Satisfied", image: "bad", value: "bad" },
  { name: "Satisfied", image: "nut", value: "nut" },
  { name: "Awesome", image: "happy", value: "happy" },
];
function ClaimSuccess() {
  const { addToast } = useToasts();
  const { id } = useParams();
  const [review, setReview] = useState();
  const [loading, setLoading] = useState(false);
  const [query, executeQuery] = useQuery({
    query: CheckReport,
    requestPolicy: "network-only",
    variables: { id },
    pause: true,
  });

  const [reportFile, reportFileMutation] = useMutation(AddReviewMutation);

  const fileReport = async (value) => {
    try {
      setLoading(true);
      const file = await reportFileMutation({ id: id, review: value });
      if (file?.data) {
        console.log(file?.data, "update");
        setLoading(false);
        addToast("Thank you for your review!", { appearance: "success" });
      } else {
        throw reportFile?.error;
      }
    } catch (e) {
      console.log(e, "error");
      addToast(`${e ? e : ""} Review Couldn't be added`, {
        appearance: "error",
      });
      setLoading(false);
    }
  };

  const [searchParams] = useSearchParams();
  useEffect(() => {
    executeQuery({ requestPolicy: "network-only" });
  }, []);
  return (
    <>
      {" "}
      <HeaderPayment />
      <form className="">
        <div className="pb-0 md:py-1 bg-white max-w-5xl mx-2 md:mx-auto rounded-md md:min-h-fit min-h-screen">
          {/* tabs with icon */}
          {query?.fetching ? (
            <div>
              {" "}
              <div className="text-start  flex items-center h-[600px] my-1 max-h-screen justify-center">
                <p className="mb-4 text-sm font-semibold text-slate-600">
                  {/* loading... */}
                </p>
              </div>
            </div>
          ) : query?.data?.reports_by_pk &&
            searchParams.get("success") === "true" ? (
            <div className="">
              <div className="relative p-4 w-full max-w-lg h-full md:h-auto m-auto">
                <div className="relative p-4 text-center rounded-md shadow sm:p-5 border-2 border-green-100">
                  <div className="p-4 sm:p-5">
                    <div className="w-12 h-12 rounded-full bg-green-200 flex items-center justify-center mx-auto mb-3.5">
                      <BsCheck2 color="green" size={30} />
                    </div>
                    <p className="mb-4 font-bold text-slate-900 text-xl">
                      Successfully created a report
                    </p>
                    <p className="font-semibold text-sm text-slate-700 mb-4">
                      Thank you for submitting your lost item report. We will
                      send you an email with further updates, your report and
                      receipt.
                    </p>
                  </div>

                  <div className="">
                    <div>
                      <p className="font-bold text-sm text-slate-900 mb-8">
                        How satisfied were you
                        <br /> with the process of filing a claim?
                      </p>
                    </div>
                    <div className="grid grid-cols-3 gap-4">
                      {moods?.map((m) => (
                        <div
                          onClick={(e) => {
                            e.preventDefault();
                            fileReport(m?.value);
                            setReview(m?.value);
                          }}
                          className={`cursor-pointer w-full mx-auto hover:bg-blue-700 rounded-lg text-slate-700 py-4 ${
                            review === m?.value
                              ? "bg-blue-700 opacity-100 text-white rounded-lg"
                              : "opacity-50 hover:opacity-100 hover:text-white rounded-lg"
                          }`}
                        >
                          <img
                            src={`/${m?.image}.svg`}
                            alt={m?.name}
                            className="mx-auto w-6 md:w-10"
                          />
                          <div className="capitalize font-semibold md:text-sm text-xs mt-2">
                            {m?.name}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="pt-6 p-4 sm:p-5">
                    <p className="font-semibold text-sm text-slate-700 mb-4">
                      You may Track your lost item from here.
                    </p>
                    <Link to="/check-status" className="my-2">
                      <button
                        type="button"
                        className="disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-blue-700 rounded-md py-3 px-8 bg-blue-700 text-white font-bold text-lg flex justify-center hover:bg-blue-800 hover:shadow-md w-full"
                      >
                        Track your Lost Item{" "}
                      </button>
                    </Link>
                    <p className="mt-4 text-sm font-semibold text-slate-600">
                      Sincerely,
                    </p>
                    <p className="mb-4 text-sm font-semibold text-slate-600">
                      Customer Care Team
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ) : searchParams.get("success") === "false" ? (
            <div className="my-16">
              <div className="relative p-4 w-full max-w-lg h-full md:h-auto m-auto">
                <div className="relative p-4 text-center rounded-md shadow sm:p-5 border-2 border-red-100">
                  <div className="w-12 h-12 rounded-full bg-red-200 flex items-center justify-center mx-auto mb-3.5">
                    <RxCross2 color="red" size={30} />
                  </div>
                  <p className="mb-4 font-bold text-slate-900 text-xl">
                    Something went wrong
                  </p>
                  <p className="font-semibold text-sm text-slate-700 mb-4">
                    No worries! Your report is all set. You'll receive a payment
                    request email within the next hour. <br />
                    If you want things to move faster, go ahead and contact our
                    support team.
                  </p>
                  <Link
                    to="mailto:support@lostandfoundconcierge.com"
                    className="my-2"
                  >
                    <button
                      type="button"
                      className="disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-blue-700 rounded-md py-3 px-8 bg-blue-700 text-white font-bold text-lg flex justify-center hover:bg-blue-800 hover:shadow-md w-full"
                    >
                      Contact via Email
                    </button>
                  </Link>
                  <div className="my-2"></div>
                  <Link to={`/payment?claim_id=${id}`} className="my-2">
                    <button
                      type="button"
                      className="disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-blue-700 rounded-md py-3 px-8 bg-blue-700 text-white font-bold text-lg flex justify-center hover:bg-blue-800 hover:shadow-md w-full"
                    >
                      Retry Payment{" "}
                    </button>
                  </Link>
                  <p className="mt-4 text-sm font-semibold text-slate-600">
                    Sincerely,
                  </p>
                  <p className="mb-4 text-sm  font-semibold text-slate-600">
                    Customer Care Team
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div>
              {" "}
              <div className="text-start  flex items-center h-[600px] my-1 max-h-screen justify-center">
                <p className="mb-4 text-sm font-semibold text-slate-600">
                  Malformed URL.
                  {/* Report ID is missing in the URL */}
                </p>
              </div>
            </div>
          )}
        </div>
      </form>
      <div className="md:h-16 h-0"></div>
      <FooterPayment />
    </>
  );
}

export default ClaimSuccess;
