// // import React from "react";
// // import { useParams } from "react-router-dom";
// // import Header from "../components/header";
// // import Footer from "../components/footer";
// // import HeroSection from "../components/landing_pages/HeroSection";
// // import Ribbon from "../components/landing_pages/ribbon";
// // import LeaveBehind from "../components/landing_pages/leavebehind";
// // import ServiceAreas from "../components/landing_pages/serviceareas";
// // import HowItWorks from "../components/landing_pages/howitworks";
// // import TimeNot from "../components/landing_pages/timenot";
// // import Marquee from "../components/landing_pages/marquee";
// // import ReviewsCarousel from "../components/landing_pages/reviews";
// // import About from "../components/landing_pages/about";
// // import FaqSection from "../components/landing_pages/faq";
// // import PricingTables from "../components/pricing_tables";

// // function Landing() {
// //   const { airportCode } = useParams(); // Get the airport code from the URL

// //   return (
// //     <div className="bg-white">
// //       <Header />
// //       <HeroSection airportCode={airportCode} /> {/* Pass airportCode to HeroSection */}
// //       <Ribbon />
// //       <LeaveBehind />
// //       <ServiceAreas />
// //       <PricingTables />
// //       <HowItWorks />
// //       <TimeNot />
// //       <Marquee />
// //       <ReviewsCarousel />
// //       <About />
// //       <FaqSection />
// //       <Footer />
// //     </div>
// //   );
// // }

// // export default Landing;

// import React from "react";
// import Header from "../components/header";
// import Footer from "../components/footer";
// import HeroSection from "../components/landing_pages/HeroSection";
// import Ribbon from "../components/landing_pages/ribbon";
// import LeaveBehind from "../components/landing_pages/leavebehind";
// import ServiceAreas from "../components/landing_pages/serviceareas";
// import HowItWorks from "../components/landing_pages/howitworks";
// import TimeNot from "../components/landing_pages/timenot";
// import Marquee from "../components/landing_pages/marquee";
// import ReviewsCarousel from "../components/landing_pages/reviews";
// import About from "../components/landing_pages/about";
// import FaqSection from "../components/landing_pages/faq";
// import PricingTables from "../components/pricing_tables";

// function Landing() {
//   return (
//     <div className="bg-white">
//       <Header/>
//       <HeroSection/> {/* HeroSection now dynamically changes based on the route */}
//       <Ribbon />
//       <LeaveBehind />
//       <ServiceAreas />
//       <PricingTables/>
//       <HowItWorks />
//       <TimeNot />
//       <Marquee />
//       <ReviewsCarousel />
//       <About />
//       <FaqSection />
//       <Footer />
//     </div>
//   );
// }

// export default Landing;

import React from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import HeroSection from "../components/landing_pages/HeroSection";
import Ribbon from "../components/landing_pages/ribbon";
import LeaveBehind from "../components/landing_pages/leavebehind";
import ServiceAreas from "../components/landing_pages/serviceareas";
import HowItWorks from "../components/landing_pages/howitworks";
import TimeNot from "../components/landing_pages/timenot";
import Marquee from "../components/landing_pages/marquee";
import ReviewsCarousel from "../components/landing_pages/reviews";
import About from "../components/landing_pages/about";
import FaqSection from "../components/landing_pages/faq";
import PricingTables from "../components/pricing_tables";
import { useParams } from "react-router-dom";

function Landing() {
  const { category, subCategory } = useParams(); // Get category and subCategory from URL

  return (
    <div className="bg-white">
      <Header />
      <HeroSection category={category} subCategory={subCategory} />
      <Ribbon />
      <LeaveBehind />
      <ServiceAreas />
      <PricingTables />
      <HowItWorks />
      <TimeNot />
      <Marquee />
      <ReviewsCarousel />
      <About />
      <FaqSection />
      <Footer />
    </div>
  );
}

export default Landing;