import React from 'react';
import { Link } from 'react-router-dom';

const TrulyLegit = () => {

  return (
    <table border="0" cellPadding="10" cellSpacing="0" align="center">
      <tbody>
        <tr>
          <td align="center"></td>
        </tr>
        <tr>
          <td align="center">
            <Link target='_blank' to="https://portal.trulylegit.com/public/company/03d66d3b-103e-4f3e-92ca-d2836ca23445/" title="Truly Certified">
              <img src="../TrulyCertified.svg" border="0" alt="Truly Certified" width={240} />
    </Link>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default TrulyLegit;
