import React, { useEffect, useState } from "react";
import { gql, useQuery } from "urql";
import { ListCarRentalQuery } from "../../queries";
import SelectBox from "../select";
import TextArea from "../textarea";
const SelectCarRental = ({
  classnames,
  labelValue,
  onChange,
  values,
  handleInput,
}) => {
  const [options, setOptions] = useState([]);

  const [carRentalResults, reexecuteCarRentalQuery] = useQuery({
    query: ListCarRentalQuery,
  });

  const {
    data: carRentalsData,
    fetching: carRentalsFetching,
    error: carRentalsError,
  } = carRentalResults;
  useEffect(() => {
    if (carRentalsData?.car_rental?.length > 0) {
      setOptions([
        ...carRentalsData?.car_rental,
        { id: "Other", name: "Other" },
      ]);
    }
  }, [carRentalsData]);
  return (
    <>
      <SelectBox
        loading={carRentalsFetching}
        noDataLabel={carRentalsFetching ? "Loading.." : "Select 'Other'"}
        valueField={"name"}
        values={
          values?.value
            ? values?.value?.name
              ? [{ ...values?.value }]
              : [{ name: "Other", id: "Other" }]
            : []
        }
        onChange={onChange}
        options={options}
        classnames={classnames}
        labelValue={labelValue}
      />{" "}
      {typeof values?.value === "string" ? (
        <TextArea
          cols={6}
          value={values?.value?.name ? "" : values?.value}
          onChange={(e) => {
            handleInput(e);
          }}
          className={"w-full p-2 md:col-span-2"}
          placeholder={"Car Rental Agency Name*"}
        />
      ) : null}
    </>
  );
};
export default SelectCarRental;
