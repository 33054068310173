import React, { useEffect, useState } from "react";
import SelectBox from "../select";
import { Country, State, City } from "country-state-city";
const countryNames = [
  "Afghanistan",
  "Azerbaijan",
  "Belarus",
  "Brunei",
  "Burundi",
  "Burkina Faso",
  "Central African Republic",
  "Chad",
  "DR Congo",
  "Central African Republic",
  "China",
  "Cote D'Ivoire",
  "Cuba",
  "Comoros",
  "Djibouti",
  "Equatorial Guinea",
  "Eritrea",
  "Ghana",
  "Guinea-Bissau",
  "Iran",
  "Iraq",
  "Kazakhstan",
  "Kyrgyzstan",
  "Laos",
  "Lebanon",
  "Libya",
  "Liberia",
  "Mali",
  "Myanmar",
  "Nigeria",
  "Niger",
  "North Korea",
  "Russia",
  "Sierra Leone",
  "Somalia",
  "Sudan",
  "South Sudan",
  "Syria",
  "Tajikistan",
  "Turkmenistan",
  "Palestinian Territories",
  "Ukraine",
  "Venezuela",
  "Western Sahara",
  "Yemen",
];
const desiredOrder = ["GB", "CA", "US"];
const SelectCountry = ({
  classnames,
  labelValue,
  onChange,
  values,
  ...rest
}) => {
  const [allCountries, setAllCountries] = useState([{ name: "", id: "" }]);
  useEffect(() => {
    const countries = Country?.getAllCountries();
    if (countries?.length > 0) {
      // remove the countries
      const removeCountries = countries?.filter(
        (c) => !countryNames?.includes(c?.name)
      );
      // sort in alphabatic order
      removeCountries.sort((a, b) => a?.name?.localeCompare(b?.name));
      //make US , britain and canada at first
      const filter = removeCountries?.filter(
        (c) => c.isoCode === "GB" || c.isoCode === "US" || c.isoCode === "CA"
      );
      const sortedCountries = filter.sort((a, b) => {
        const aIndex = desiredOrder.indexOf(a.isoCode);
        const bIndex = desiredOrder.indexOf(b.isoCode);
        return aIndex - bIndex;
      });
      const merged = [...sortedCountries.reverse(), ...removeCountries];
      setAllCountries(Array.from(new Set(merged.map((item) => item))));
    }
  }, []);
  return (
    <>
      <SelectBox
        loading={allCountries?.length < 2}
        noDataLabel={allCountries?.length < 2 ? "Loading" : "No Data"}
        values={values?.value ? [{ name: values?.value }] : []}
        valueField={"name"}
        onChange={onChange}
        options={allCountries}
        classnames={classnames}
        labelValue={labelValue}
        labelField={"name"}
        {...rest}
      />
    </>
  );
};
export default SelectCountry;
