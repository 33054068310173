import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import React, { useState, createRef, forwardRef } from "react";

const InputDate = ({
  value,
  placeholder,
  onChange,
  className,
  type,
  maxDate,
  ...rest
}) => {
  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <input
      placeholder={placeholder}
      onClick={onClick}
      ref={ref}
      className={`${className}  appearance-none font-semibold cursor-text bg-slate-50 rounded-lg px-2 my-1 border-2 border-slate-200 text-slate-700 text-sm h-12`}
      defaultValue={value}
      {...rest}
    />
  ));
  return (
    <div className="w-full">
      {placeholder && (
        <label
          className="text-xs text-slate-500 font-semibold"
          htmlFor={placeholder}
        >
          {placeholder}
        </label>
      )}
      <DatePicker
        // customInput={<ExampleCustomInput />}
        className={`${className}  appearance-none font-semibold cursor-text bg-slate-50 rounded-lg px-2 my-1 border-2 border-slate-200 text-slate-700 text-sm h-12`}
        dateFormat="MM/dd/yyyy"
        wrapperClassName={`w-full datePicker`}
        selected={new Date(value)}
        value={new Date(value)}
        onChange={onChange}
        onSelect={onChange}
        maxDate={maxDate}
        placeholderText={placeholder}
        {...rest}
      />
    </div>
  );
};
export default InputDate;
