import React from 'react';

const FiveYears = ({ imagePath, altText }) => {
  return (
    <div style={{ textAlign: 'center' }}>
      <img src="../5years.png" alt="5 years in business" width={100} />
    </div>
  );
};

export default FiveYears;
