import React, { useState } from "react";

const TextArea = ({
  value,
  placeholder,
  onChange,
  className,
  type,
  ...rest
}) => {
  const [val, setVal] = useState();
  return (
    <div className="w-full">
      <label
        htmlFor={placeholder}
        className="text-xs text-slate-500 font-semibold"
      >
        {placeholder}
      </label>
      <textarea
        about=""
        type={type || "text"}
        className={` ${className} disabled:cursor-default disabled:opacity-30  appearance-none font-semibold cursor-text bg-slate-50 rounded-lg px-2 my-1 border-2 border-slate-200 text-slate-700 text-sm w-full`}
        value={val || value}
        onChange={(e) => {
          setVal(e.target.value);
          onChange(e);
        }}
        placeholder={placeholder}
        {...rest}
      />
    </div>
  );
};
export default TextArea;
