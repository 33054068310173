// import React, { useState, useEffect } from "react";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import StarsRow from "./stars";
// import { getReviewData } from "../utility";
// import { GetReviewsQuery } from "../../queries";
// import { useQuery } from "urql";
// const ReviewsCarousel = () => {
//   const [reviewContent, setReviewContent] = useState({
//     review: [],
//   });
//   const [getreviews, executeGetReviews] = useQuery({
//     query: GetReviewsQuery,
//     requestPolicy: "network-only",
//     pause: true,
//   });
//   const { data: reviewsData, fetching: reviewsFetching } = getreviews;
//   useEffect(() => {
//     if (reviewsData?.reviews) {
//       setReviewContent({
//         title: "Customer Reviews",
//         review: [...reviewsData?.reviews],
//       });
//     }
//   }, [reviewsData]);
//   useEffect(() => {
//     executeGetReviews({ requestPolicy: "network-only" });
//   }, []);
//   // useEffect(() => {
//   //   const data = getReviewData();
//   //   setReviewContent(data);
//   // }, []);

//   const settings = {
//     dots: true,
//     useRef: null,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 3,
//     slidesToScroll: 1,
//     draggable: true,
//     nextArrow: null,
//     prevArrow: null,
//     appendDots: (dots) => (
//       <div>
//         <ul style={{ marginBottom: "-40px" }}> {dots} </ul>
//       </div>
//     ),
//     responsive: [
//       {
//         breakpoint: 600,
//         settings: {
//           slidesToShow: 2,
//           slidesToScroll: 1,
//           initialSlide: 1,
//         },
//       },
//       {
//         breakpoint: 480,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//         },
//       },
//     ],
//   };

//   const sliderCardStyle = {
//     innerHeight: "900px",
//   };

//   // Limit the number of reviews to 20
//   const limitedReviews = reviewContent?.review.slice(0, 20);
  
//   return (
//     <div className="bg-blue-50 py-16">
//       <h2 className="text-3xl font-bold text-blue-700 mb-8 uppercase">
//         {reviewContent?.title}
//       </h2>
//       <div className="max-w-7xl mx-auto pb-4 w-11/12">
//         <Slider {...settings}>
//           {reviewContent?.review &&
//             reviewContent?.review?.map((re, index) => (
//               <div key={index} style={sliderCardStyle} className="px-2">
//                 <div className="bg-white shadow-lg p-6 rounded-lg text-left">
//                   <h3 className="text-xl font-slate-700 font-bold pb-2">
//                     {re?.title}
//                   </h3>
//                   <span className="flex items-center">
//                     <StarsRow rating={re?.rate} />
//                     <small className="ml-2 flex items-center font-semibold font-slate-500">
//                       {re?.date}
//                     </small>
//                   </span>
//                   <p className="text-sm pb-2  font-semibold font-slate-500">
//                     {re?.review}
//                   </p>
//                   <p className="text-sm  font-semibold font-slate-500">
//                     — {re?.user_name}
//                   </p>
//                 </div>
//               </div>
//             ))}
//         </Slider>
//       </div>
//     </div>
//   );
// };

// export default ReviewsCarousel;


import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import StarsRow from "./stars";
import { GetReviewsQuery } from "../../queries";
import { useQuery } from "urql";

const ReviewsCarousel = () => {
  const [reviewContent, setReviewContent] = useState({
    review: [],
  });
  const [getreviews, executeGetReviews] = useQuery({
    query: GetReviewsQuery,
    requestPolicy: "network-only",
    pause: true,
  });
  const { data: reviewsData } = getreviews;

  useEffect(() => {
    if (reviewsData?.reviews) {
      setReviewContent({
        title: "Customer Reviews",
        review: [...reviewsData?.reviews],
      });
    }
  }, [reviewsData]);

  useEffect(() => {
    executeGetReviews({ requestPolicy: "network-only" });
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    draggable: true,
    nextArrow: null,
    prevArrow: null,
    appendDots: (dots) => (
      <div>
        <ul style={{ marginBottom: "-40px" }}> {dots} </ul>
      </div>
    ),
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const sliderCardStyle = {
    innerHeight: "900px",
  };

  // Limit the number of reviews to 20
  const limitedReviews = reviewContent?.review.slice(0, 30); // Changed to limit total number of slides and dots to 20

  return (
    <div className="bg-blue-50 py-16">
      <h2 className="text-3xl font-bold text-blue-700 mb-8 uppercase">
        {reviewContent?.title}
      </h2>
      <div className="max-w-7xl mx-auto pb-4 w-11/12">
        <Slider {...settings}>
          {limitedReviews.map((re, index) => (
            <div key={index} style={sliderCardStyle} className="px-2">
              <div className="bg-white shadow-lg p-6 rounded-lg text-left">
                <h3 className="text-xl font-slate-700 font-bold pb-2">
                  {re?.title}
                </h3>
                <span className="flex items-center">
                  <StarsRow rating={re?.rate} />
                  <small className="ml-2 flex items-center font-semibold font-slate-500">
                    {re?.date}
                  </small>
                </span>
                <p className="text-sm pb-2 font-semibold font-slate-500">
                  {re?.review}
                </p>
                <p className="text-sm font-semibold font-slate-500">
                  — {re?.user_name}
                </p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default ReviewsCarousel;