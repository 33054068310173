import React, { useState, useEffect } from "react";
import SelectBox from "../select";
import TextArea from "../textarea";
import SelectThemePark from "../select_inputs/select_theme_park";
import { ListThemeParkStateQuery } from "../../queries";
import { useQuery } from "urql";
import InputBox from "../input";
import ErrorMessage from "../error_message";
const LocationPark = ({ handleSetReport, locationDetails, errors }) => {
  const [location, setLocation] = useState([...locationDetails]);

  const handleLocationDetails = async (key, data) => {
    const dataCopy = [...location];
    const findIndex = dataCopy.findIndex((d) => d?.title === key);
    try {
      if (findIndex >= 0) {
        dataCopy[findIndex] = { title: key, value: data };
      } else {
        dataCopy.push({ title: key, value: data });
      }
      setLocation(dataCopy);
    } catch (e) {}
  };
  useEffect(() => {
    handleSetReport({ location_details: location });
  }, [location]);
  const handleRemoveItem = async (key) => {
    try {
      const updatedArray = location.filter((item) => item.title !== key);
      setLocation(updatedArray);
    } catch (e) {}
  };
  return (
    <div className="">
      <div className="mt-4">
        {/* at park */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4">
          {/* <SelectBox
            labelField={"state"}
            loading={themeParksFetching}
            noDataLabel={themeParksFetching ? "Loading" : "No Data"}
            valueField={"state"}
            values={
              locationDetails?.find((l) => l?.title === "state")?.value
                ? [
                    {
                      state: locationDetails?.find((l) => l?.title === "state")
                        ?.value,
                    },
                  ]
                : []
            }
            onChange={(e) => {
              handleLocationDetails("state", e[0]?.state);
            }}
            options={options}
            labelValue={"State *"}
          /> */}
          <div>
            <SelectThemePark
              classnames={""}
              labelValue={"Theme Park Name *"}
              values={locationDetails?.find((l) => l?.title === "name") || {}}
              onChange={(e) => {
                if (!e[0]?.name || e?.length < 1) {
                  handleRemoveItem("name");
                } else if (e[0]?.name !== "Other") {
                  handleLocationDetails("name", {
                    name: e[0]?.name,
                    id: e[0]?.id,
                    inventory: "theme_park",
                  });
                } else {
                  handleLocationDetails("name", "");
                }
              }}
              handleInput={(e) => {
                handleLocationDetails("name", e.target.value);
              }}
            />
            <ErrorMessage condition={errors && errors["name"]} />
          </div>
          <InputBox
            value={
              locationDetails?.find((l) => l?.title === "place")?.value || ""
            }
            onChange={(e) => {
              handleLocationDetails("place", e.target.value);
            }}
            placeholder={"Where in Theme Park "}
            className={"w-full"}
          />
          <TextArea
            value={
              locationDetails?.find((l) => l?.title === "address")?.value || ""
            }
            onChange={(e) => {
              handleLocationDetails("address", e.target.value);
            }}
            placeholder={"Address "}
            className={"grid md:col-span-2 h-24 p-2"}
          />
        </div>
      </div>
    </div>
  );
};
export default LocationPark;
