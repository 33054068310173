import React, { useState, useEffect } from "react";
import SelectBox from "../select";
import InputBox from "../input";
import TextArea from "../textarea";
import ErrorMessage from "../error_message";
const LocationLasVegas = ({ handleSetReport, locationDetails, errors }) => {
  const [location, setLocation] = useState([...locationDetails]);

  const handleLocationDetails = async (key, data) => {
    const dataCopy = [...location];
    const findIndex = dataCopy.findIndex((d) => d?.title === key);
    try {
      if (findIndex >= 0) {
        dataCopy[findIndex] = { title: key, value: data };
      } else {
        dataCopy.push({ title: key, value: data });
      }
      setLocation(dataCopy);
    } catch (e) {}
  };

  useEffect(() => {
    handleSetReport({ location_details: location });
  }, [location]);

  return (
    <div className="">
      <div className="mt-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4">
          <div>
            <InputBox
              className={"w-full"}
              placeholder={"Casino Name *"}
              value={
                locationDetails?.find((l) => l?.title === "name")?.value || ""
              }
              onChange={(e) => {
                handleLocationDetails("name", e.target.value);
              }}
            />
            <ErrorMessage
              condition={errors && errors["name"]}
              message={errors && errors["name"]}
            />
          </div>
          <div>
            <InputBox
              className={"w-full"}
              value={
                locationDetails?.find((l) => l?.title === "where you lost it")
                  ?.value || ""
              }
              onChange={(e) => {
                handleLocationDetails("where you lost it", e.target.value);
              }}
              placeholder={"Where in Casino *"}
            />
            <ErrorMessage
              condition={errors && errors["where you lost it"]}
              message={errors && errors["where you lost it"]}
            />
          </div>

          <div>
            <InputBox
              value={
                locationDetails?.find((l) => l?.title === "Room Number")
                  ?.value || ""
              }
              onChange={(e) => {
                handleLocationDetails("Room Number", e.target.value);
              }}
              className={"w-full"}
              placeholder={"Room Number *"}
            />
            <ErrorMessage
              condition={errors && errors["Room Number"]}
              message={errors && errors["Room Number"]}
            />
          </div>
          <InputBox
            value={
              locationDetails?.find((l) => l?.title === "comments")?.value || ""
            }
            onChange={(e) => {
              handleLocationDetails("comments", e.target.value);
            }}
            className={"w-full"}
            placeholder={"Comments"}
          />
        </div>
      </div>
    </div>
  );
};
export default LocationLasVegas;
